import React, { Component } from "react";
import { Form, Layout, Input, Button, Row, Col, Select, Upload, Switch, Avatar, message,Divider, Modal, List } from 'antd';
import { FileImageOutlined, UserOutlined } from '@ant-design/icons';
import { Redirect, Link } from "react-router-dom";
import axios from 'axios'

const { Content, Header } = Layout;
const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;

class CrearProducto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nombreVista: 'Crear variante',
            return: false,
            prod_activo: undefined,
            sizes: [],
            variante: [],
           
        }
    }

    /**
    * @memberof CrearProducto
    *
    * @method componentDidMount
    * @description  Al montar el componente trae los datos de las categorias y tamanos y los asigna al state.categorias y state.tamanos
    *
    **/
    componentDidMount() {
        this.getTamanos();
    }
    /**
    * @memberof CrearProducto
    *
    * @method getTamanos
    * @description metodo que contiene la peticion de obtener todos los tamaños para asignarlos al state.tamanos
    **/
     getTamanos = () => {
        axios.get('/sizes', {
            headers: {Authorization: sessionStorage.getItem("token")}
        })
            .then(res => {
                this.setState({
                    sizes: res.data.data
                })
            })
            .catch(res => {
                console.log("error", res);
            });
    }

    /**
   * @memberof CrearProducto
   *
   * @method onChangeActive
   * @description  Actualiza el valor del switch del input activo al state.prod_activo
   *
   **/
    onChangeActive = (checked) => {
        console.log("nuevo estatus producto",checked);
        this.setState({ prod_activo: checked });
    };

    /**
   * @memberof CrearProducto
   *
   * @method handleSubmit
   * @description  Envia los datos del formulario al Servidor
   *
   * @param values (array)
   * Contiene los campos del formulario para registrar al producto
   *
   * @returns response (array)
   **/
    handleSubmit = values => {
        console.log(this.state);
        console.log(values);
        axios.post('/variantes/add', {
            nombre:       values.nombre,
            categoria_id: values.categoria_id,
            size_id:    values.size,
            precio:       values.precio,
            sku_variante:    values.sku_variante,
            producto_id:this.props.match.params.producto_id,
            activo:       this.state.prod_activo
        },
            { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                console.log(res);
                message.success(res.data.message);
                this.setState({ return: true });

            })
            .catch((error) => {
                Modal.warning({
                    title: 'Error',
                    content:
                        <List
                            size="small"
                            bordered
                            dataSource="No se ha podido crear el producto"
                        />
                });
            });
    }

    /**
   * @memberof CrearProducto
   *
   * @method renderRedirect
   * @description  Activa el redireccionamiento si el formulario se envio con exito
   *
   **/
    renderRedirect = () => {
        if (this.state.return) {
            let link="/admin/variantes/"+this.props.match.params.producto_id
            return <Redirect to={link} />;
        }
    };

    render() {
        return (
            <Layout>
            {this.renderRedirect()}
            <Header className="admin-header" style={{height: 'auto'}}>
                <Row>
                    <Col span={10}>
                        <h3 id="nombreVista" className="nombreVista encabezado">{this.state.nombreVista}</h3>
                    </Col>
                </Row>
            </Header>
            <Content className="box-shadow">
                <div>
                    <div className="div-contenedor">
                        <Col span={16} offset={4}>
                            <Form onFinish={this.handleSubmit} layout={"vertical"} ref={this.formRef}>
                                <Row gutter={[16, 16]}>
                                    <Col span={12} xs={24} lg={12}>
                                        <Form.Item name="nombre" label="Nombre" rules={[{
                                            required: true,
                                            message: 'Por favor ingresa nombre de producto'
                                        }]}>
                                            <Input placeholder="Nombre del producto" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} xs={24} lg={{span: 11, push: 1}}>
                                    <Form.Item name="sku_variante" label="Sku">
                                            <Input placeholder="ABCDS0123" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={12} xs={24} md={8} lg={8}>
                                        <Form.Item name="size" label="Tamaño" rules={[{
                                            required: true,
                                            message: 'Por favor selecciona tamaño'
                                        }]}>
                                            <Select placeholder="Seleccionar opción" >
                                                {this.state.sizes?.map(function (tam, index) {
                                                    return <Option value={tam._id}>{tam.size}</Option>
                                                })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} xs={24} md={8} lg={8}>
                                        <Form.Item name="precio" label="Precio" rules={[{
                                            required: true,
                                            message: 'Por favor ingresa costo de producto'
                                        }]}>
                                            <Input placeholder="$0.00"  type="number"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} xs={24} lg={12}>
                                        <Form.Item name="activo" label="Producto activo" >
                                            <Switch   defaultChecked onChange={this.onChangeActive}  style={{float: 'left'}}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12} xs={24} lg={{span: 12}}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit"
                                                    >
                                                Guardar
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </div>
                </div>
            </Content>

        </Layout>
        )
    }
}

export default CrearProducto;
