import React, { Component } from "react";
import { Route, Switch, Link } from "react-router-dom";
import { Layout, PageHeader, Button, Typography, Dropdown, Menu, } from "antd";

//COMPONENTES
import Navbar from '../components/Header/Header';
import { User, SetUser } from '../Hooks/Logged';
import AdminSidebar from "../components/Sidebar/Sidebar";
import { IconArrowBack } from './../components/Widgets/Iconos'
import CustomAvatar from "./../components/Widgets/Avatar/Avatar";
//ROUTERS
import RouterDashboard from "./DashboardRouter";
import RouterCategorias from "./CategoriasRouter";

import RouterTamanos from "./TamañosRouter"
import RouterProductos from "./ProductosRouter"
import VariantesRouter from "./VariantesRouter"
import RouterVentas from "./VentasRouter";
import SuscriptoresRouter from "./SuscriptoresRouter";
import RouterUsuarios from "./RouterUsuarios";
import axios from 'axios';

//css
import '../Styles/Global/admin.scss';


const { Content } = Layout;
const { Text } = Typography;

class AdminRoutes extends Component {

    volver = () => {
        window.history.back();
    }

    static contextType = User

    constructor(props) {
        super(props)
        this.state = {
            back: false,
            responsiveSidebar: false,

            showSearch: false,
            filterSearch: "",
            searching: false,
            title: '...',
            volver: false,
        };
    }

    SetFilterSearch(e) {
        this.setState({ filterSearch: e })
    }

    updateFilterSearch = (ShowFilter) => {
        if (ShowFilter === undefined) { ShowFilter = false }
        this.setState({
            showSearch: ShowFilter,
        })
    };

     /**
     * @memberof AdminRoutes
     *
     *
     * @description Actualiza el titulo del modulo actual
     */
    setTitle = (title, volver = false) => {
        this.setState({title, volver})
    }

    /**
     * @memberof AdminRoutes
     *
     *
     * @description Cierra sesion
     */
    cerrarSession = () => {
        this.props.setUser(0)
        sessionStorage.clear();
        localStorage.clear()
        axios.defaults.headers.common['Authorization'] = null
    };


    render() {
        const { title, back } = this.state;
        const { updateFilterSearch } = this;

        const menu = (
            <Menu>
                <Menu.Item key="pedidos"  >
                    <Link to={'/user/orders'}>My Account</Link>
                </Menu.Item>
                <Menu.Item onClick={this.cerrarSession} >
                    <Link to={'/'}>Logout</Link>
                </Menu.Item>
            </Menu>
        )

        const DropdownMenu = () => (
            <Dropdown key="more" overlay={menu}>
                
                <Button type="text" style={{color: 'white', margin: '0'}}> 
                    <CustomAvatar style={{marginRight: '10px'}} name={this.context?.nombre + ' ' + this.context?.apellido} image={this.context?.avatar} />
                    {this.context ? this.context.nombre.toUpperCase() : 'Usuario'} 

                </Button>
                
            </Dropdown>
        );

        return (

            <Layout className="layout-admin">
                <User.Consumer>
                    {value => { this.context = value }}
                </User.Consumer>
                <Layout>
                    <AdminSidebar
                        user={this.context}
                        back={back}
                        setUser={this.props.setUser}
                    />
                    <Content className="admin-content">
                        <PageHeader
                            className="site-page-header custom-page-header"
                            title={<div>
                                        {this.state.volver ? <Button type="text" className="mr-1" onClick={this.volver}>
                                            <IconArrowBack  />
                                        </Button> : null}
                                        <Text className="text-white">{this.state.title}</Text>
                                    </div>}

                            extra={[ <DropdownMenu/> ]}
                            
                        />
                        <Switch>

                            <Route path="/admin/dashboard"
                                render={(props) => <RouterDashboard  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} setTitle={this.setTitle} />}
                            />
                            <Route path="/admin/usuarios"
                                render={(props) => <RouterUsuarios  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} setTitle={this.setTitle} />}
                            />
                            <Route path="/admin/productos"
                                render={(props) => <RouterProductos  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} setTitle={this.setTitle} />} />
                            
                            <Route path="/admin/variantes/:producto_id"
                                render={(props) => <VariantesRouter  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} setTitle={this.setTitle} />} />
                            
                            <Route path="/admin/categorias"
                                render={(props) => <RouterCategorias  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} setTitle={this.setTitle} />} />
                            
                            <Route path="/admin/tamanos"
                                render={(props) => <RouterTamanos  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} setTitle={this.setTitle} />} />

                            <Route path="/admin/ventas"
                                render={(props) => <RouterVentas  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} setTitle={this.setTitle} />} />

                            <Route path="/admin/suscriptores"
                                render={(props) => <SuscriptoresRouter  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} setTitle={this.setTitle} />} />

                        </Switch>

                    </Content>
                </Layout>

            </Layout>
        )
    }
}


export default (props) => <AdminRoutes {...props} setUser={React.useContext(SetUser)} />