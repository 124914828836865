import React, { Component } from "react";
import { Table, Layout, Input, Row, Col,Switch, PageHeader, Button, message, Modal, Space} from 'antd';
import { Link } from 'react-router-dom';
import {FormOutlined,PlusOutlined, EyeOutlined, DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
//modal
import ModalProductos from './ModalProductos'
import axios from 'axios'

const { Header, Content } = Layout;
const { Search } = Input;

class Productos extends Component {
  constructor(props) {
	super(props);
	this.state = {
	  nombreVista: 'Productos',
	  user: 'Administrador',
	  productos: [],
	  itemCount: undefined,
	  perPage: 10,
	  pageCount: 1,
	  currentPage: 1,
	  slNo: 1,
	  hasPrevPage: false,
	  hasNextPage: false,
	  prev: null,
	  next: null,
	}
  }
  
  componentDidMount() {
  	this.props.setTitle('Productos')
	axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
	/*LLAMADA AL PAGINADO*/
	this.receivedData();
 
	
  }


	/**
	* @memberof Productos
	*
	* @method   receivedData
	* @description  Metodo que realiza el paginado de los productos
	*  @param values Number con la pagina a buscar
	*
	**/
	receivedData(page){
		axios.get('/productos', {
			params: {
				page:page,
				limit:10
			}
		}).then(res => {
			const data = res.data.data.itemsList
			console.log(data)
			this.setState({
				productos: data,
				currentPage:  res.data.data.paginator.currentPage,
				itemCount:    res.data.data.paginator.itemCount,
				perPage:      res.data.data.paginator.perPage,
			});
		}).catch(res => {
			console.log("error", res);
		});
	}

	/**
	* @memberof Productos
	*	
	* @method   searchData
	* @description  Metodo que realiza la busqueda de productos
	* @param values String   con la info a buscar, por defecto cadena vacia para busquedas
	*
	**/
	searchData=(values)=>{
		axios.get('/productos', {
			params: {
				search: values
			}
		})
		.then(res => {
			const data = res.data.data.itemsList
			console.log(data)
			this.setState({
				productos: data,
				currentPage:  res.data.data.paginator.currentPage,
				itemCount:    res.data.data.paginator.itemCount,
				perPage:      res.data.data.paginator.perPage,
				pageCount:    res.data.data.paginator.pageCount,		 
			});
		}).catch(res => {
			console.log("error", res);
		});
	}


	/**
	* @memberof Productos
	*
	* @method   handlePageClick
	* @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
	*
	**/
	handlePageClick = page => {
		this.receivedData(page);
	};

	/**
   	* @memberof Tamaños
   	*
   	* @method   showDeleteConfirm
   	* @description  describir funcion por favor
   	*
   	**/
   	showDeleteConfirm = (item) => {
  		const tm_nombre = item.nombre;
  		const tm_id = item._id;

			Modal.confirm({
				title: 'Eliminar Producto',
				icon: <ExclamationCircleOutlined />,
				content: '¿Estas seguro de eliminar el tamaño ' + tm_nombre + ' ?',
				okText: 'Continuar',
				okType: 'danger',
				cancelText: 'Cancelar',
				onOk: () => {
					axios({
						method: 'delete',
						url: '/productos/delete',
						data: { id: tm_id }
			  		})
			  		.then((response) => {
						message.success('Producto eliminado');
				 		this.receivedData()
					}).catch((error) => {
						message.error('Error al eliminar');
					})
				}
	  		})
	}

	render() {

		const columns = [
			{
				title: 'Nombre',
				dataIndex: 'nombre',
				key: 'nombre',
			},{
				title: 'Descripción',
				dataIndex: 'descripcion',
				key: 'descripcion',
			},{
				title: 'Categoria',
				key: 'categoria',
				render: (text, record) => (
					<span>{(record.categoria_id != undefined)?record.categoria_id.nombre:''}</span>
				),
			},{
				title: 'Activo',
				key: 'activo',
				render: (text, record) => (
					(record.activo) ? <Switch checked disabled={true}  /> : <Switch disabled={true}  />
				),
			},{
				title: 'Acciones',
				key: 'actions',
				render: (text, record) => (
					<Space>
						<Button 
							title="Editar" 
							className="mr-1"
							type="secondary" 
							icon={<FormOutlined />}
							onClick={()=>this.setState({modalProductos: true, producto_id: record._id})}
						>
						</Button>
						<Link to={`/admin/variantes/${record._id}`}>
							<Button
							className="mr-1"
								title="Variantes" 
								icon={<EyeOutlined />}
							/> 
						</Link>
						<Button 
							title="Eliminar"
							type="danger"
							onClick={()=>this.showDeleteConfirm(record)}
							icon={<DeleteOutlined /> }>
						</Button>
					</Space>
				),
			},
		];

		return (
			<Layout>
				<Content className="admin-content content-bg pd-1">
					<Row className="mb-1">
						<Col xs={24} md={12}>
							<Search
								className="search-bar"
								placeholder="Buscar..."
								enterButton="Buscar"
								onSearch={this.searchData}
							/>
						</Col>
						<Col xs={24} md={12} className="flex-right">
							<Button type="primary" icon={<PlusOutlined/>} onClick={()=>this.setState({modalProductos: true})}></Button>
						</Col>
					</Row>

					<Table 
						columns={columns} 
						dataSource={this.state.productos}  
						pagination={{
							onChange: this.handlePageClick,
                            total: this.state.itemCount,
                            pageSize:this.state.perPage,
                            current: this.state.currentPage
						}}
					/>
				</Content>
				<ModalProductos
					visible={this.state.modalProductos}
					producto_id={this.state.producto_id}
					onCancel={()=>{
						this.setState({modalProductos: false, producto_id: undefined})
						this.receivedData()
					}}
				/>
			</Layout>
		)
	}
}

export default Productos;