import React, { Component } from "react";
import { Table, Layout, Input, Row, Col,Switch, PageHeader, Button,Typography } from 'antd';
import { Link } from 'react-router-dom';
import {FormOutlined, PlusOutlined, CodeSandboxOutlined} from '@ant-design/icons';

import ModalVariantes from './ModalVariantes'
import ModalInventario from './ModalInventario'
import axios from 'axios'

const { Header, Content } = Layout;
const { Text } = Typography
const { Search } = Input;
 

class Variantes extends Component {
  constructor(props) {
	super(props);
	this.state = {
	  nombreVista: 'Variantes',
	  user: 'Administrador',
	  variantes: [],
	  itemCount: undefined,
	  perPage: 10,
	  pageCount: 1,
	  currentPage: 1,
	  slNo: 1,
	  hasPrevPage: false,
	  hasNextPage: false,
	  prev: null,
	  next: null,
	  modalVariantes: false
	}
  }

  /**
  * @memberof Variantes
  *
  * @method componentDidMount
  * @description  Al montar el componente trae los datos de los usuarios y los asigna al state.users
  *
  **/
  componentDidMount() {
  	this.props.setTitle('Variantes')
	axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
	console.log('MOUNTED!')
	/*LLAMADA AL PAGINADO*/
	this.receivedData();
 
	
  }


  /**
  * @memberof Variantes
  *
  * @method   receivedData
  * @description  Metodo que realiza el paginado de los variantes
  * @param values Number con la pagina a buscar
  *
  **/
  receivedData(page){
	console.log("paginando ando",page)
	axios.get('/variantes', {
	  headers: { Authorization: sessionStorage.getItem("token") },
	  params: {
		producto_id:this.props.match.params.producto_id,
		page:page,
		limit:50
	  }
	})
	  .then(res => {
	   
		const data = res.data.data.itemsList
	  
		this.setState({
		  variantes: data,
		  currentPage:  res.data.data.paginator.currentPage,
		  itemCount:    res.data.data.paginator.itemCount,
		  perPage:      res.data.data.paginator.perPage,
		  pageCount:    res.data.data.paginator.pageCount,
		  currentPage:  res.data.data.paginator.currentPage,
		  slNo:         res.data.data.paginator.slNo,
		  hasPrevPage:  res.data.data.paginator.hasNextPage,
		  hasNextPage:  res.data.data.paginator.hasNextPage,
		  prev:         res.data.data.paginator.prev,
		  next:         res.data.data.paginator.next,
		 
		});

		console.log("state paginator",this.state);
	  })
	  .catch(res => {
		console.log("error", res);
	  });
  }

   /**
  * @memberof Variantes
  *
  * @method   searchData
  * @description  Metodo que realiza la busqueda de variantes
  * @param values String   con la info a buscar, por defecto cadena vacia para busquedas
  *
  **/
  searchData=(values)=>{
	console.log("paginando ando",values)
	axios.get('/variantes', {
	  headers: { Authorization: sessionStorage.getItem("token") },
	  params: {
		search: values,
		producto_id: this.props.match.params.producto_id
	  }
	})
	  .then(res => {
		console.log("paginando ando",res);
		const data = res.data.data.itemsList
	  
		this.setState({
		  variantes: data,
		  currentPage:  res.data.data.paginator.currentPage,
		  itemCount:    res.data.data.paginator.itemCount,
		  perPage:      res.data.data.paginator.perPage,
		  pageCount:    res.data.data.paginator.pageCount,
		  currentPage:  res.data.data.paginator.currentPage,
		  slNo:         res.data.data.paginator.slNo,
		  hasPrevPage:  res.data.data.paginator.hasNextPage,
		  hasNextPage:  res.data.data.paginator.hasNextPage,
		  prev:         res.data.data.paginator.prev,
		  next:         res.data.data.paginator.next,
		 
		});

		console.log("state paginator",this.state);
	  })
	  .catch(res => {
		console.log("error", res);
	  });

  }
  /**
   * @memberof Variantes
   *
   * @method   handlePageClick
   * @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
   *
   **/
  handlePageClick = page => {
	console.log("pagina",page);//2
	  this.receivedData(page);
  };

	render() {


		const columns = [
			{
				title: 'Nombre',
				dataIndex: 'nombre',
				key: 'nombre',
			},{
				title: 'SKU',
				dataIndex: 'sku_variante',
				key: 'nombre',

			},{
				title: 'Tamaño',
				key: 'size_id',
				render: (text, record) => (
				  <span>{(record.size_id != undefined)?record.size_id.size:''}</span>
				),
			},{
				title: 'Precio',
				dataIndex: 'precio',
				key: 'precio',
				render: (text) => (<Text>$ {text.toMoney()}</Text>)
			},{
				title: 'Activo',
				key: 'activo',
				render: (text, record) => (
				  (record.activo) ? <Switch checked disabled={true} className="input-box" /> : <Switch disabled={true} className="input-box" />
				),
			},{
				title: 'Acciones',
				key: 'actions',
				render: (text, record) => (
				 	<>
						<Button 
							title="Editar" 
							icon={<FormOutlined />}
							onClick={()=>this.setState({modalVariantes:  true, variante_id: record._id})}
						/>
						<Button
							className="ml-1"
							title="Inventario" 
							icon={<CodeSandboxOutlined />}
							onClick={()=>this.setState({modalInventario:  true, variante_id: record._id})}
						/>
					</>
				),
			},
		];



		return (
			<Layout>
				<Content className="admin-content content-bg pd-1">
					<Row className="mb-1">
                        <Col xs={24} md={12}>
                            <Search
                            	className="search-bar"
                                placeholder="Buscar..."
                                enterButton="Buscar"
                                onSearch={this.searchData}
                            />
                        </Col>
                        <Col xs={24} md={12} className="flex-right">
                            <Button type="primary" icon={<PlusOutlined/>} onClick={()=>this.setState({modalVariantes: true})}></Button>
                        </Col>
                    </Row>
                    <Table 
						columns={columns} 
						dataSource={this.state.variantes}  
						pagination={{
							onChange: this.handlePageClick,
                            total: this.state.itemCount,
                            pageSize:this.state.perPage,
                            current: this.state.currentPage
			  			}}
			 		/>
				</Content>
				<ModalVariantes
					visible={this.state.modalVariantes}
					producto_id={this.props.match.params.producto_id}
					variante_id={this.state.variante_id}
					onCancel={()=>{
						this.setState({modalVariantes: false, variante_id: undefined})
						this.receivedData();
					}}
				/>	

				<ModalInventario
					visible={this.state.modalInventario}
					producto_id={this.props.match.params.producto_id}
					variante_id={this.state.variante_id}
					onCancel={()=>{
						this.setState({modalInventario: false, variante_id: undefined})
						this.receivedData();
					}}
				/>		  
	 		</Layout>
		)
 	}
}

export default Variantes;