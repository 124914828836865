import React, { Component, useEffect } from "react";

import { Layout, Row, Col, Typography, Card, Button, Carousel, } from 'antd';


import { CreditCardOutlined, HeartOutlined } from '@ant-design/icons'
import { IconBus, IconStar, IconTiming, IconConversation, IconHotDeals } from "../Widgets/Iconos";

import "../../Styles/Modules/Public/WeLoveOurClients.scss"

const { Title } = Typography

/**
 *
 *
 * @export
 * @class Landing
 * @extends {Component}
 * @description Pagina principal del sistema
 */
export default class Landing extends Component {
    render() {

        const contentStyle = {
            height: '400px',
            color: '#fff',
            lineHeight: '160px',
            textAlign: 'center',
            background: '#364d79',
        };

        return (

            <Row className="landing-section responsive" justify="center">
                <Col xs={23} sm={24}>
                    <Title level={2}> <HeartOutlined style={{ color: "#CCFF1B" }} /> We Love our Clients</Title>


                    <Row className="container-we-love-clientes">
                        <Card bordered={false} className="card-information">
                            <IconBus fill="#CCFF1B" size="1.4" className="custom-icon" style={{ top: '-13px', outline: '15px solid white' }} />
                            <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                <span style={{ fontWeight: 'bold' }}>Free Shipping</span><br />
                                <span style={{ fontSize: 11 }}>Black Friday Week</span>
                            </p>
                        </Card>
                        <Card bordered={false} className="card-information">
                            <IconStar fill="#CCFF1B" size="0.6" className="custom-icon" className="custom-icon" style={{ top: '-20px', outline: '5px solid white' }} />
                            <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                <span style={{ fontWeight: 'bold' }}>Best Quality</span><br />
                                <span style={{ fontSize: 11 }}>in our Products</span>
                            </p>
                        </Card>
                        <Card bordered={false} className="card-information">
                            <IconTiming fill="#CCFF1B" size="2" className="custom-icon" style={{ top: '-20px', outline: '5px solid white' }} />
                            <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                <span style={{ fontWeight: 'bold' }}>90 Days</span><br />
                                <span style={{ fontSize: 11 }}>Return Policy</span>
                            </p>
                        </Card>
                        <Card bordered={false} className="card-information">
                            <IconConversation fill="#CCFF1B" size="0.5" className="custom-icon" style={{ top: '-20px', outline: '5px solid white' }} />
                            <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                <span style={{ fontWeight: 'bold' }}>24/7 Support</span><br />
                                <span style={{ fontSize: 11 }}>Customer Service</span>
                            </p>
                        </Card>
                        <Card bordered={false} className="card-information">
                            <CreditCardOutlined className="custom-icon" style={{ top: '-15px', outline: '5px solid white', color: "#CCFF1B", fontSize: 30 }} />
                            {/* <IconCreditCard fill="#CCFF1B" size="0.5" className="custom-icon" /> */}
                            <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                <span style={{ fontWeight: 'bold' }}>Secure Payments</span><br />
                                <span style={{ fontSize: 11 }}>3D Security</span>
                            </p>
                        </Card>
                    </Row>

                    {/* <div className="container-we-love-clientes">
                        <Row justify="center" gutter={6}>
                            <Col xs={12} md={4} className="center mb-1">
                                <div className="card-information">
                                    <IconBus fill="#CCFF1B" size="1.4" className="custom-icon" style={{ top: '-13px', outline: '15px solid white' }} />
                                    <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                        <span style={{ fontWeight: 'bold' }}>Free Shipping</span><br />
                                        <span style={{ fontSize: 11 }}>Black Friday Week</span>
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} md={4} className="center mb-1">
                                <div className="card-information">
                                    <IconStar fill="#CCFF1B" size="0.6" className="custom-icon" className="custom-icon" style={{ top: '-20px', outline: '5px solid white' }} />
                                    <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                        <span style={{ fontWeight: 'bold' }}>Best Quality</span><br />
                                        <span style={{ fontSize: 11 }}>in our Products</span>
                                    </p>
                                </div>
                            </Col>
                            <Col xs={24} md={4} className="center mb-1">
                                <div className="card-information">
                                    <IconTiming fill="#CCFF1B" size="2" className="custom-icon" style={{ top: '-20px', outline: '5px solid white' }} />
                                    <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                        <span style={{ fontWeight: 'bold' }}>90 Days</span><br />
                                        <span style={{ fontSize: 11 }}>Return Policy</span>
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} md={4} className="center mb-1">
                                <div className="card-information">
                                    <IconConversation fill="#CCFF1B" size="0.5" className="custom-icon" style={{ top: '-20px', outline: '5px solid white' }} />
                                    <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                        <span style={{ fontWeight: 'bold' }}>24/7 Support</span><br />
                                        <span style={{ fontSize: 11 }}>Customer Service</span>
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} md={4} className="center mb-1">
                                <div className="card-information">
                                    <CreditCardOutlined className="custom-icon" style={{ top: '-15px', outline: '5px solid white', color: "#CCFF1B", fontSize: 30 }} />
                                    
                                    <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                        <span style={{ fontWeight: 'bold' }}>Secure Payments</span><br />
                                        <span style={{ fontSize: 11 }}>3D Security</span>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div> */}

                </Col>
            </Row>
        )
    }
}