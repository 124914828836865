import React, {useEffect, useState, Component} from 'react';

import {Form, Layout, Input, Button, Row, Col, Select, Upload, Switch, Modal, List, message, Divider, Spin} from 'antd';
// import {FileImageOutlined} from '@ant-design/icons';
import {Redirect, Link} from "react-router-dom";
import 'react-dropzone-uploader/dist/styles.css'
import Previews from '../../Widgets/Preview';
import axios from 'axios'

const { Header,Content} = Layout;



const {Option} = Select;
const {TextArea} = Input;

class EditarVariante extends Component {

    formRef = React.createRef();

    preview = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            nombreVista: 'Editar variante',
            return: false,
            prod_activo: undefined,
            sizes: [],
            variante: [],
           
        }
    }

    /**
     * @memberof EditarVariante
     *
     * @method componentDidMount
     * @description  Al montar el componente trae los datos del variante y los asigna al state.variante
     *
     **/
    async componentDidMount() {
        axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
        console.log("Edicion de variante montado");
        /*obtener las categorias y tamaños primero*/
        this.getTamanos();
        const pid = this.props.match.params.id;
        await axios.get(`/variantes/get`,
            {params:{id: pid}},
            {
                headers: {Authorization: sessionStorage.getItem("token")}
            })
            .then(async res => {

              

                this.setState({
                    prod_activo: res.data.data.variante.activo,
                    variante: res.data.data.variante,
                });

           

                let variante = res.data.data.variante;
                let inv = res.data.data.inventario;
                this.formRef.current.setFieldsValue({
                    nombre: variante.nombre,
                    sku_variante: variante.sku_variante,
                    size: (variante.size_id != undefined) ? variante.size_id._id : '',
                    precio: variante.precio,
                    activo: variante.activo,
                 });
            })
            .catch(res => {
                console.log("error", res);
            });

        console.log(this)
    }

    /**
     * @memberof EditarVariante
     *
     * @method getTamanos
     * @description metodo que contiene la peticion de obtener todos los tamaños para asignarlos al state.sizes
     **/
    getTamanos = () => {
        axios.get('/sizes', {
            headers: {Authorization: sessionStorage.getItem("token")}
        })
            .then(res => {
                this.setState({
                    sizes: res.data.data
                })
            })
            .catch(res => {
                console.log("error", res);
            });
    }


   


    /**
     * @memberof EditarVariante
     *
     * @method onChangeActive
     * @description  Actualiza el valor del switch del input activo al state.activo
     *
     **/
    onChangeActive = (checked) => {
        console.log("estatus del variante", checked);
        this.setState({prod_activo: checked});
    };


    

    /**
     * @memberof EditarVariante
     *
     * @method handleSubmit
     * @description  Envia los datos del formulario al Servidor
     *
     * @param values (array)
     * Contiene los campos del formulario para editar al variante
     *
     * @returns response (array)
     **/
    handleSubmit = values => {

        axios.put('/variantes/update', {
                id: this.state.variante._id,
                nombre: values.nombre,
                size_id: values.size,
                precio: values.precio,
                sku_variante: values.sku_variante,
                activo: this.state.prod_activo,
                //existencias: values.existencias
            },
            {headers: {Authorization: sessionStorage.getItem("token")}})
            .then((res) => {
                message.success(res.data.message);
                this.setState({return: true});

            })
            .catch((error) => {
                console.log(error)
                Modal.warning({
                    title: 'Error',
                    content: (Array.isArray(error.errors))? <List
                        size="small"
                        bordered
                        dataSource={error.errors}
                    />: 'Error al procesar el variante. Verifique la información.'
                });
            });

    };

    /**
     * @memberof EditarVariante
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
     **/
    renderRedirect = () => {
        if (this.state.return) {
            let link = "/admin/variantes/" + this.state.variante.producto_id._id;
            return <Redirect to={link} />;
        }
    };




    render() {
        return (
            <Layout>
                {this.renderRedirect()}
                <Header className="admin-header" style={{height: 'auto'}}>
                    <Row>
                        <Col span={10}>
                            <h3 id="nombreVista" className="nombreVista encabezado">{this.state.nombreVista}</h3>
                        </Col>
                    </Row>
                </Header>
                <Content className="box-shadow">
                    <div>
                        <div className="div-contenedor">
                            <Col span={16} offset={4}>
                                <Form onFinish={this.handleSubmit} layout={"vertical"} ref={this.formRef}>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12} xs={24} lg={12}>
                                            <Form.Item name="nombre" label="Nombre" rules={[{
                                                required: true,
                                                message: 'Por favor ingresa nombre de producto'
                                            }]}>
                                                <Input placeholder="Nombre del producto" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} xs={24} lg={{span: 11, push: 1}}>
                                        <Form.Item name="sku_variante" label="Sku">
                                                <Input placeholder="ABCDS0123" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12} xs={24} md={8} lg={8}>
                                            <Form.Item name="size" label="Tamaño" rules={[{
                                                required: true,
                                                message: 'Por favor selecciona tamaño'
                                            }]}>
                                                <Select placeholder="Seleccionar opción" >
                                                    {this.state.sizes?.map(function (tam, index) {
                                                        return <Option value={tam._id}>{tam.size}</Option>
                                                    })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} xs={24} md={8} lg={8}>
                                            <Form.Item name="precio" label="Precio" rules={[{
                                                required: true,
                                                message: 'Por favor ingresa costo de producto'
                                            }]}>
                                                <Input placeholder="$0.00"  type="number"/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} xs={24} lg={12}>
                                            <Form.Item name="activo" label="Producto activo" >
                                                <Switch   defaultChecked onChange={this.onChangeActive}  style={{float: 'left'}}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Divider className="divider-text">Inventario</Divider>
                                        <Col span={24} xs={24} lg={24}>
                                            <Form.Item name="existencias" label="Existencias">
                                                <Input placeholder="0"  type="number"/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12} xs={24} lg={{span: 12}}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit"
                                                        >
                                                    Guardar
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </div>
                    </div>
                </Content>

            </Layout>
        )
    }
}

export default EditarVariante;
