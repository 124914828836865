import React, { Component } from 'react';
import { Dropdown, Menu, Col, Row, Typography, Layout, Input, Space, Button, Form, message } from 'antd';
import { PhoneFilled, RightSquareOutlined, } from '@ant-design/icons';
import { FaEnvelope, FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from 'react-router-dom';

import { IconSNYoutube, IconSNLinkedIn, IconSNTwitter, IconSNFacebook, IconSNInstagram, IconContactoFt, IconConversation } from './../Widgets/Iconos'

import '../../Styles/Global/footer.scss';
import '../../Styles/Global/footer.css';
import { SetUser, User } from "./../../Hooks/Logged"
import Login from "./../Auth/Login"
import Register from "./../Auth/Register"
import axios from 'axios'

const { Footer } = Layout;
const { Title, Paragraph, Text } = Typography



/**
 * @const Boletin
 * @description Boletin informativo
 */

class Boletin extends Component {
    static contextType = User;
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            isAdmin: false,
            isLogged: false,
        }
    }

    componentDidMount() {
    }

    /**
        * @memberof Boletin
        *
        * @method onFinish
        * @description  Registra un usuario para el NewsLetter
        *
        **/
    onFinish = (values) => {
        axios.post('/suscriptores/add', {
            email: values.email
        }).then((res) => {
            message.success('Successfully subscribed');
        }).catch((error) => {
            message.error('Subscribing error')
        })
    }



    render() {
        return (
            <section className="footer-public">



            </section>

        )
    }
}


/**
 * @const Footer
 * @description Footer 
 */

class _Footer extends Component {
    static contextType = User;
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            isAdmin: false,
            isLogged: false,
            modalLoginVisible: false,
            modalRegisterVisible: false,
        }
    }

    componentDidMount() {
    }

    cerrarModal = () => {
        this.setState({
            modalLoginVisible: false,
            modalRegisterVisible: false
        })
    }

    render() {
        return (
            <section className="w-100 center mb-2">
                <Row className='w-80 mt-2'>
                    <Col xs={24} sm={24} md={12} lg={16} xl={16} xxl={16}>
                        <Row className='boletin-footer'>
                            <Col span={24}>
                                <Title level={6}>Subscribe</Title>
                            </Col>
                            <Col span={24} className="mt-1">
                                <Text>Subscribe us and you won't miss the new arrivals, as well as discounts and sales.</Text>
                            </Col>
                            <Col span={24} className="mt-1">
                                <Form name="horizontal_login" layout="inline" >
                                    <Form.Item
                                        name="email"

                                    >
                                        <Input placeholder="E-mail" />
                                    </Form.Item>
                                    <Form.Item>

                                        <Button

                                            htmlType="submit"
                                            size='large'
                                        >
                                            Send
                                        </Button>

                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col span={24} className="mt-3">
                                <Title level={6}>STAY IN TOUCH</Title>
                            </Col>
                            <Col span={24}>
                                <Row>
                                    <FaFacebook size={20} className="mr-2" />
                                    <FaTwitter size={20} className="mr-2" />
                                    <FaInstagram size={20} className="mr-2" />
                                    <FaYoutube size={20} className="mr-2" />
                                </Row>
                            </Col>
                            <Col span={24} className="mt-1">
                                <Text>Questions? Please write us at: <a href='mailto:padel@gmail.com'>padel@gmail.com</a></Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={16} xl={8} xxl={8}>
                        <Row className='boletin-footer'>
                            <Col span={24} className="mb-1">
                                <Title level={6}>INFO</Title>
                            </Col>
                            <Col span={12}>
                                <ol className="footer-links">
                                    <li><Link to="#">FAQ</Link></li>
                                    <li><Link to="#">Shipping + Handling</Link></li>
                                    <li><Link to={`/store`}
                                        onClick={() => {
                                            if (this.props.setCategoria) {
                                                this.props.setCategoria('all')
                                                this.props.setHot(false)
                                            }
                                        }} >2022 Catalog   </Link></li>
                                    <li><Link to="#">Search   </Link></li>
                                </ol>
                            </Col>
                            <Col span={12}>
                                <ol className="footer-links">
                                    <li><Link to="#">Contacts</Link></li>
                                    <li><Link to="#">Exchanges</Link></li>
                                    <li><Link to="#">Returns</Link></li>
                                </ol>
                            </Col>
                        </Row>
                        <Row className='boletin-footer'>
                            <Col span={24} className="mb-1 mt-1">
                                <Title level={6}>SHOP</Title>
                            </Col>
                            <Col span={12}>
                                <ol className="footer-links">
                                    <li><Link to="#">Rackets</Link></li>
                                    <li><Link to="#">Accesories</Link></li>
                                </ol>
                            </Col>
                            <Col span={12}>
                                <ol className="footer-links">
                                    <li><Link to="#">Shoes</Link></li>

                                </ol>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                    <div className="pie-footer">
                        <Row className="row-pie-footer">
                            <Col xs={24} lg={10} xl={14}>
                                <p>Padel   -   © 2022 All Rights Reserved</p>
                            </Col>
                            <Col xs={24} lg={14} xl={10}>
                                <Row>
                                    <Col xs={24} md={4} lg={5} xl={6} className="center">
                                        <Text>Payment</Text>
                                    </Col>
                                    <Col xs={4} md={4} lg={4} className="center">
                                        <img src={"/images/stripe.png"} width="80" className="w100" alt="img-pym-1" />
                                    </Col>
                                    <Col xs={8} md={6} lg={4} className="center">
                                        <img src={"/images/visa.png"} width="80" className="w100" alt="img-pym-2" />
                                    </Col>
                                    <Col xs={6} md={5} lg={4} className="center">
                                        <img src={"/images/master.png"} width="50" className="w100" alt="img-pym-4" />
                                    </Col>
                                    <Col xs={6} md={5} lg={4} className="center">
                                        <img src={"/images/ae.png"} width="80" className="w100" alt="img-pym-4" />
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </div>
                </Col>
                </Row>
                


                <Login
                    onClickRegistrarmeButton={() => this.setState({
                        modalRegisterVisible: true,
                        modalLoginVisible: false
                    })}
                    visible={this.state.modalLoginVisible}
                    onCancel={this.cerrarModal}
                />
                <Register
                    onClickLoginButton={() => this.setState({
                        modalRegisterVisible: false,
                        modalLoginVisible: true
                    })}
                    visible={this.state.modalRegisterVisible}
                    onCancel={this.cerrarModal}
                />

            </section>
        )
    }
}


export { Boletin, _Footer };