import React from 'react';
import { Typography, Col, Row, Card, List, Space, Tag, Modal, Statistic, message, Empty, } from 'antd';

import { Link } from 'react-router-dom'

import ProductControl from '../Widgets/ProductControl/ProductControl';
import axios from 'axios';


const { Title, Paragraph } = Typography

export default function ShoppingCart(props) {

    const { listProducts, setListProducts } = props

    // value, quantity
    const onQuantityChange = (index, quantity) => {

        console.log(index, quantity)
        let tempListProducts = listProducts
        const value = parseFloat(quantity)

        if (!isNaN(value) && value <= tempListProducts[index].limit && value > 0)
            tempListProducts[index].quantity = quantity

        if (value > tempListProducts[index].limit)
            message.error("No more availability.")

        if (value <= 1)
            message.error("There must be at least one product.")
        setListProducts([...tempListProducts])
    }

    const deleteProduct = (index) => Modal.confirm({
        title: "Are you sure you want to delete the product??",
        okText: "Yes",
        onOk: () => {
            let tempListProducts = listProducts
            tempListProducts.splice(index, 1);
            setListProducts([...tempListProducts])
        }
    })



    return <List
        className="list-productos-shopping-cart"
        itemLayout="vertical"
        size="large"
        dataSource={listProducts}
        locale={{
            emptyText: <Empty image="/images/shopping-cart.jpg"
                imageStyle={{
                    height: 300,
                }}
                description={
                    <span>
                        Your shopping cart is empty. <Link to="/store">¡Go to Store to add some products!</Link>
                    </span>
                } />
        }}
        renderItem={(item, index) => (<Card className="w-100 product-shopping-cart">
            {console.log('item', item)}
            <Row className="w-100">
                <Col span={1} className="producto-index-container">
                    <Title level={5} className="producto-index">{index + 1}</Title>
                </Col>
                <Col span={23}>
                    <List.Item
                        key={"list-element-" + index}
                    >
                        <List.Item.Meta
                            avatar={<div className="producto-img-container">
                                <img
                                    className="producto-image"
                                    width={200}
                                    alt="logo"
                                    src={axios.defaults.baseURL + '/upload/' + item.producto?.imagenes[0]}
                                />
                            </div>}
                            title={item?.producto?.nombre}
                            description={<div className="producto-content">
                                <Paragraph className="product-description">{item?.producto?.descripcion}</Paragraph>
                                <Space className="product-tags">
                                    <Tag>{item.size.size}</Tag>
                                    <Tag>{item?.categoria?.nombre}</Tag>
                                </Space>
                                <Statistic prefix={"$"} className="product-precio" precision={2} value={item.precio} />
                            </div>}
                        >
                        </List.Item.Meta>
                        <ProductControl
                            className="cart"
                            onQuantityChange={onQuantityChange}
                            deleteProduct={deleteProduct}
                            index={index}
                            quantity={item.quantity}
                            limit={item.limit}
                            deleteOption={true}
                        />
                    </List.Item>
                </Col>
            </Row>
        </Card>)}
    />

}
