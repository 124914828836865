import React, { Component } from "react";
import { Form, Layout, Input, Button, Row, Col,Modal,List,message } from 'antd';
import { Redirect } from "react-router-dom";
import axios from 'axios'

const { Content,Header } = Layout;
const { TextArea } = Input;


class CrearCategoria extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombreVista: ''
        }
    }

    componentDidMount() {
        this.setState({ nombreVista: 'Crear categoria' });
    }
    
   /**
   * @memberof CrearCategoria
   *
   * @method handleSubmit
   * @description  Envia los datos del formulario al Servidor
   *
   * @param values (array)
   * Contiene los campos del formulario para registrar 
   *
   * @returns response (array)
   **/

  handleSubmit = values => {
    axios.post('/categorias/add', 
        {
            nombre: values.nombre,
            descripcion:values.descripcion, 
        },
        {
            headers: {Authorization : sessionStorage.getItem('token')}
        }
    )
        .then((response) => {
            if (response.status == 200) {
                message.success("Categoría creada !");
                this.setState({ redirect: true });
            }
        })
        .catch((error) => {
            Modal.warning({
                title: 'Error',
                content:
                    <List
                        size="small"
                        bordered
                        dataSource="la categoria no se ha podido crear"
                    />
            });
        })
    }
    
      /**
     * @memberof Register
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
     **/
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to="/admin/categorias" />;
        }
    };
  
    render() {

        return (
            <Layout>
                {this.renderRedirect()}
                <Header className="admin-header" style={{ height: 'auto' }}  >
                    <Row>
                        <Col span={10}>
                            <h3  className="encabezado" >{this.state.nombreVista}</h3>
                        </Col>
                    </Row>
                </Header>
                <Content className="box-shadow">
                        <div className="div-contenedor" style={{ alignContent: 'center', alignItems: 'center' }} >
                            <Col span={12} xs={24} lg={{ span: 12, offset: 6 }} >
                                <Form initialValues={{ remember: true }} onFinish={this.handleSubmit} layout={"vertical"} className="form-CrearCategoria center" name="CrearCategoria" >
                                    <Form.Item name="nombre" label="Nombre"
                                        rules={[{ required: true, message: 'Por favor ingresa nombre' }]}>
                                        <Input placeholder="Nombre de la categoría"  />
                                    </Form.Item>

                                    <Form.Item type='textarea' label="Descripción" name="descripcion"
                                        rules={[{ required: true, message: 'Por favor ingresa descripción' }]}>

                                        <TextArea
                                            placeholder="Descripción de la categoría"
                                            
                                            style={{ minHeight: '100px' }}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" >
                                            Guardar
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </div>
                </Content>

            </Layout>
        )
    }
}

export default CrearCategoria;