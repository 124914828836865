import React, { useState } from 'react';
import { Typography, Col, Row, Button, Result, Modal } from 'antd';
import axios from 'axios';


import { useHistory } from 'react-router-dom';

import HotSale from '../Public/HotSale';
import '../../Styles/Modules/Public/Carrito.scss';

import ListaProductos from './ListaProductos';
import Direcciones from './Direcciones'

import ModalRegister from '../Auth/Register';
import ModalLogin from '../Auth/Login';


import { Carrito, SetCarrito } from '../../Hooks/Carrito';
import { User } from '../../Hooks/Logged';
import PaySummary from './ResumenPago'
import CardDetail from './CardDetails'



const { Title, Paragraph } = Typography


/**
 *
 * 1) Revisar si está loggeado. Si está loggeado, solo aparece "Proceder al pago"
 * 
 * 2) Si no está loggeado
 *      Le aparecen los botones de "Pagar con mi cuenta" y "Proceder al Pago"
 *          1) Si se selecciona "PAGAR CON MI CUENTA" se abre el modal de inicio de sesión / registro dependiendo del caso
 *          2) Si se selecciona "PAGAR COMO INVITADO" se pasa al siguiente step.
 *
 * @export
 * @param {*} props
 * @return {*} 
 */
export default function ShoppingCart(props) {

    /** @type {*} */
    let user = React.useContext(User)

    let history = useHistory()

    let setCarrito = React.useContext(SetCarrito)

    let [listProducts, setListProducts] = useState(React.useContext(Carrito))
    let [current, setCurrent] = useState(0)

    let [direccion, setDireccion] = useState({})



    let [status, setStatus] = useState(0)

    /** @type {*} */
    let [modalLoginVisible, setModalLoginVisible] = useState(false)
    let [modalRegisterVisible, setModalRegisterVisible] = useState(false)

    let [buyInformation, setBuyInformation] = useState()
    let [loading, setLoading] = useState(false)
    let direccionRef = React.useRef(null)
    let paysummaryRef = React.useRef(null)


    let total = 0;
    let subtotal = 0


    for (const product of listProducts)
        subtotal += product.quantity * product.precio

    let tax = subtotal * 0.07;
    let shipping = subtotal == 0 ? 0 : subtotal <= 50 ? 19.90 : 0

    total = subtotal + tax + shipping

    /** 
     * Invitado = 0
     * Con Cuenta = 1 
    /** @type {*} */
    let [tipoPago, setTipoPago] = useState(0)
    /**
     *
     *
     * @param {*} proceder_a Step a cambiar
     * @param {number} [tipo_pago=0] 
     */
    const proceeder = async (tipo_pago = tipoPago) => {
        let redirect = true
        switch (current) {
            case 0:
                console.log(0)
                setTipoPago(tipo_pago)
                if (tipo_pago == 1 && (user == 0 || user == null || user == undefined)) {
                    redirect = false
                    setModalLoginVisible(true)
                }
                break;

            case 1:
                redirect = false;

                console.log('current 1', tipoPago)
                if (tipoPago === 1) {
                    let validateFields = await direccionRef.current.formRef.current.validateFields()
                    console.log('validateFields con cuenta', validateFields)
                    if (validateFields !== -1) {

                        let values = direccionRef.current.formRef.current.getFieldsValue
                        setDireccion(values)
                        redirect = true
                    }
                } else {
                    let validateFields = await direccionRef.current.formRefNewDireccion.current.validateFields()
                    console.log('validateFields invitado', validateFields)
                    if (validateFields !== -1) {
                        let values = direccionRef.current.formRefNewDireccion.current.getFieldsValue()
                        setDireccion(values)
                        redirect = true
                    }
                }
                break;
            case 2:
                paysummaryRef.current.stripeRefence.current.click()
                console.log('paysummaryRef')
                redirect = false
                break;
        }
        if (redirect) {
            return setCurrent(current + 1)
            // if (current == 0 && tipo_pago == 1 && !(user == 0 || user == null || user == undefined))
            //     return setCurrent(current + 2)
            // else

        }
    }

    const paymentHandledSubmit = (values) => {
        setLoading(true)




        axios.post('/ventas/create', {
            token: values.token,
            direccion: (direccion?.direccion) ? direccion?.direccion : direccion,
            total,
            iva: tax,
            envio: shipping,
            subtotal,
            status: 1,
            detalles: listProducts.map(e => ({
                variante_id: e._id,
                precio: e.precio,
                cantidad: e.quantity,
                total: parseFloat(e.precio) * parseFloat(e.quantity)

            }))
        }, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        } )
            .then(({ data }) => {
                setCurrent(current + 1)
                setCarrito([])
                setBuyInformation(data.venta.order_id)
                setStatus(1)
            })
            .catch(e => {
                if (e.response.data.payment == true) {
                    setCurrent(current + 1)
                    setCarrito([])
                    setStatus(0)
                    return;
                }

                Modal.error({
                    'title': 'It\'s not possible to process the payment.',
                    'content': "There was an error processing the payment. Please try a different card."
                });


            })
            .finally(() => setLoading(false))
    }

    const onModalClose = () => {
        setModalLoginVisible(false)
        setModalRegisterVisible(false)
    }

    const onSuccess = () => {
        setCurrent(1)
        setModalLoginVisible(false)
        setModalRegisterVisible(false)
    }

    // console.log('setLoading', setLoading)

    let steps = [

        {
            title: 'My Cart',
            content: <ListaProductos
                listProducts={listProducts}
                setListProducts={list => {
                    setListProducts(list)
                    setCarrito(list)
                }}
            />,
        },
        {
            title: 'Adresses',
            content: <Direcciones
                ref={direccionRef}
                user={user}
                tipoPago={tipoPago}
            />
        },
        {
            title: 'Payment Details',
            content:
                <PaySummary
                    setLoading={setLoading}
                    loading={loading}
                    ref={paysummaryRef}
                    direccion={direccion}
                    paymentHandledSubmit={paymentHandledSubmit}
                />
        },
        {
            content: <Result
                style={{ margin: "0 auto" }}
                icon={<img src={(status == 1) ? "/images/status/sucess.svg" : "/images/status/error.svg"} style={{ maxWidth: '500px', width: '100%' }} />}
                title={(status == 1) ? "Thank you for your purchase!" : "¡We could not process your payment!"}
                subTitle={<div>
                    {(status == 1) ? [
                        <Title level={5} style={{ marginTop: '0.7em', marginBottom: '1em' }}>Order Number #<Paragraph style={{ display: 'inline' }} copyable>{buyInformation}</Paragraph></Title>,
                        <Paragraph>We are in the process of shipping your order. <br />During the next 24 hours you will receive an email with your tracking number. <br></br> Contact us if you have any questions <a href="tel:+18572065608"> +1 857 206 5608</a> </Paragraph>
                    ] : <Paragraph>We could not process your payment. Please, contact customer support at <a href="tel:+18572065608"> +1 857 206 5608</a> </Paragraph>}
                </div>}
                extra={[
                    <Button type="primary" key="console" onClick={() => history.push('/')}>Go Home</Button>,
                    <Button key="buy" onClick={() => history.push('/store')}>Go to the Store</Button>,
                ]}

            />
        }
    ]


    window.axios = axios

    return <div className="w-100">
        <Row className="landing-section pd-1 responsive" style={{ margin: '0px auto' }}>
            <Col xs={24} lg={(current == 3) ? 24 : 15}>
                <Row className={"w-100 steps-title " + (current == 3) ? "" : ""}>
                    <Title level={3}>{steps[current].title}</Title>
                </Row>
                <Row className="w-100 pr-1 ">
                    {steps[current].content}
                </Row>
            </Col>

            {(current != 3) ? <Col span={6}>
                <CardDetail
                    loading={loading}
                    user={user}
                    current={current}
                    proceeder={proceeder}
                    productos={listProducts}
                    subTotal={subtotal}
                    total={total}
                    shipping={19.90} />
            </Col> : null}

        </Row>
        <HotSale />

        <ModalLogin
            onClickRegistrarmeButton={() => {
                setModalLoginVisible(false)
                setModalRegisterVisible(true)
            }}
            visible={modalLoginVisible}
            onCancel={onModalClose}
            onSuccess={onSuccess}
        />
        <ModalRegister
            onClickLoginButton={() => {
                setModalLoginVisible(true)
                setModalRegisterVisible(false)
            }}
            visible={modalRegisterVisible}
            onCancel={onModalClose}
            onSuccess={onSuccess}
        />
    </div>
}
