

import React, { Component } from "react";
import { Button, Form, Input, message, Modal, Typography, } from 'antd';
import axios from 'axios'

const { Title } = Typography;


/**
 *
 *
 * @class ModalTracking
 * @extends {React.Component}
 * @description Formulario de categorias
 */
class ModalTracking extends Component {


    formModalCategoria = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            sending: false,
        }
    }


    /**
     * @methodOf ModalTracking
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        console.log('props', this.props);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }



    /**
    * @memberof ModalTracking
    *
    * @method getData
    * @description  Envia la peticion al server para enviar el tracking number
    *
    **/
    sendTrackNumber = (values) => {

        this.setState({ sending: true })
        axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
        axios.post('/ventas/tracking-number', {
            id: this.props.venta_id,
            email: this.props.email,
            ...values
        })
            .then(res => {
                message.success('El Tracking Number se ha enviado');
                this.props.close();
            })
            .catch(error => {
                message.error('El Tracking Number no se pudo enviar')
            })
            .finally(() => {
                this.setState({ sending: false })
            })

    }




    render() {


        return (
            <>
                <Title level={3} className="text-center">Envio de Tracking Number</Title>
                <Form layout="vertical" requiredMark={false} onFinish={this.sendTrackNumber} labelCol={{ span: 12, offset: 6 }} wrapperCol={{ span: 12, offset: 6 }}>
                    <Form.Item
                        name="tracking"
                        rules={[{ required: true, message: "Por favor, ingrese el Tracking Number" }]}
                    >
                        <Input placeholder="NKDLASKQRD"></Input>
                    </Form.Item>

                    <Form.Item className="text-center pd-1">
                        <Button htmlType="submit" type="primary" loading={this.state.sending}>
                            {(this.state.sending == true) ? 'Enviando' : 'Enviar'}
                        </Button>
                    </Form.Item>
                </Form>
            </>

        )
    }
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {

    let { visible, close } = props

    return (
        <Modal
            visible={visible}
            title={null}
            footer={null}
            maskClosable={true}
            destroyOnClose={true}
            closable={true}
            onCancel={close}
            zIndex={1000}
            onOk={close}
        >
            <ModalTracking {...props} />
        </Modal>
    )
}