
import React from 'react'
import { Spin, Layout, Col, Row, PageHeader, Avatar, Typography, Image, Card, Statistic } from "antd";
import { VentasSemana } from '../Widgets/graficas'
import Analytics from '../Widgets/analytics'
import { User } from '../../Hooks/Logged';
import { UserOutlined, StockOutlined } from "@ant-design/icons";
import { IconSalesGreen, IconGroupPersons, IconOrdenes } from "../Widgets/Iconos"
import '../../Styles/Modules/Dashboard/dashboard.css'
import { FaShoppingCart, FaBoxes } from "react-icons/fa";

import axios from "axios"


const { Content } = Layout;
const { Title } = Typography;


export default class Dashboard extends React.Component {
    static contextType = User;
    constructor(props) {
        super(props);

        this.state = {

            loading: false,
            ventas_totales: 0,
            ventas_semana: 0,
            total_productos: 0,
            total_usuarios: 0,
            dataValue: {
                "lunes": 0,
                "martes": 0,
                "miercoles": 0,
                "jueves": 0,
                "viernes": 0,
                "sábado": 0,
                "domingo": 0,
            }
        }
    }




    /**
     *
     *
     * @memberof Dashboard
     * @description Carga los datos a la vista
     */

    componentDidMount() {
        this.props.setTitle('Dashboard')
        axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
        this.getDashboard()

    }


    getDashboard() {
        axios.get('/dashboard').then(response => {
            console.log('Dashboard response', response.data);
            let resp = response.data.data;
            this.setState({
                dataValue: resp.ultimas_ventas,
                ventas_totales: resp.ventas_totales,
                ventas_semana: resp.longitud_ultimas_Ventas,
                total_productos: resp.total_productos,
                total_usuarios: resp.total_usuarios
            })

        });
    }



    render() {

        return (
            <Spin spinning={this.state.loading} >
                <Content >
                    <Row className="dashboard width-100  pd-1" gutter={[8, 8]}  >
                        <Col xs={24} xl={18}>
                            <Card className="card-bienvenido " style={{ background: "url('/images/bgBienvenido.png')", backgroundSize: 'cover' }}>
                                <Row gutter={[8, 8]}>
                                    <Col xs={24} lg={24} xl={24} className="flex-left-column-between">
                                        <Title level={2} className="main-dashboard-title">Bienvenido {this.context?.nombre}</Title>
                                    </Col>
                                </Row>
                            </Card>
                            <Row gutter={[16, 16]} className="width-100 mt-1">
                                <Col span={24} xs={23} lg={12} xl={8}>
                                    <Card className="card-container">
                                        <Card.Meta
                                            avatar={<Avatar className="card-icon" icon={<UserOutlined />} />}
                                            title="Usuarios"
                                        />
                                        <Statistic className="flex-right card-count" value={this.state.total_usuarios} />
                                    </Card>
                                </Col>
                                <Col span={24} xs={23} lg={12} xl={8} >
                                    <Card className="card-container">
                                        <Card.Meta
                                            avatar={<Avatar className="card-icon" icon={<FaBoxes />} />}
                                            title="Productos"
                                        />
                                        <Statistic className="flex-right card-count" value={this.state.total_productos} />
                                    </Card>
                                </Col>
                                <Col span={24} xs={23} lg={12} xl={8} >
                                    <Card className="card-container">
                                        <Card.Meta
                                            avatar={<Avatar className="card-icon" icon={<FaShoppingCart />} />}
                                            title="Ventas esta semana"
                                        />
                                        <Statistic className="flex-right card-count" value={this.state.ventas_semana} />
                                    </Card>

                                </Col>
                                <Col span={24}  >
                                    <Card className="card-container "  >
                                        <Card.Meta
                                            title="Ventas Totales"
                                            description={<Statistic value={this.state.ventas_totales} />}
                                        />

                                        <VentasSemana datos={this.state.dataValue} />
                                    </Card>
                                </Col>


                            </Row>
                        </Col>
                        <Col xs={24} xl={6}>
                            <Analytics />
                        </Col>
                    </Row >
                </Content >
            </Spin >
        )
    }
}