import React, { Component } from "react";
import { Form, Layout, Input, Button, Row, Col, message} from 'antd';
import { Redirect } from "react-router-dom";
import axios from 'axios'


const { Header, Content } = Layout;
const { TextArea } = Input;

class CrearTamaño extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombreVista: '',
        }
    }

   /**
   * @memberof CrearTamaño
   *
   * @method handleSubmit
   * @description  Envia los datos del formulario al Servidor
   *
   * @param values (array)
   * Contiene los campos del formulario para registrar al usuario 
   *
   * @returns response (array)
   **/

    handleSubmit = values => {
    axios.post('/sizes/add', 
        {
            size: values.size,
        },
        {
            headers: {Authorization : sessionStorage.getItem('token')}
        }
    )
        .then((response) => {
            if (response.status == 200) {
                message.success("Tamaño creado!");
                this.setState({ redirect: true });
            }
        })
        .catch((error) => {
            console.log(error.response);
        })
    }
    
      /**
     * @memberof Register
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
     **/
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to="/admin/tamanos" />;
        }
    };

    componentDidMount() {
        this.setState({
            nombreVista: 'Crear tamaño'
        });
    }
 
    render() {
        return (
            <Layout>
                {this.renderRedirect()}
                <Header className="admin-header" style={{ height: 'auto' }}  >
                    <Row>
                        <Col span={10}>
                            <h3 id="nombreVista" className="nombreVista encabezado" >{this.state.nombreVista}</h3>
                        </Col>
                    </Row>
                </Header>

                <Content className="box-shadow">
                    <div>
                        <div className="div-contenedor" style={{ alignContent: 'center', alignItems: 'center' }} >
                            <Col span={12} xs={24} lg={{ span: 12, offset: 6 }} >
                            <Form initialValues={{ remember: true }} onFinish={this.handleSubmit} layout={"vertical"} className="form-CrearTamaño center" >
                                    <Form.Item
                                        name="size"
                                        label="Tamaño"
                                        rules={[{ required: true, message: 'Por favor ingresa tamaño' }]}>
                                        <Input
                                            placeholder="Tamaño"
                                            
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            
                                        >
                                            Guardar
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </div>
                    </div>
                </Content>

            </Layout>
        )
    }
}

export default CrearTamaño;