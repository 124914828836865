

import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, List } from 'antd';

import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import PropTypes from "prop-types";
import Logged from '../../../Hooks/Logged';
import CustomAvatar from "../../Widgets/Avatar/Avatar";

import axios from 'axios'

const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;


/**
 *
 *
 * @class FormCategorias
 * @extends {React.Component}
 * @description Formulario de categorias
 */
class FormCategorias extends Component {


    formModalCategoria = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingImage: false,
            form: {},

            id: null,
            image: null,
            negocios: []

        }
    }


    /**
     * @methodOf FormCategorias
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if(this.props.categoria_id){
            this.getData()
        }
    }

    /**
   * @memberof FormCategorias
   *
   * @method onFinish
   * @description  Se ejecuta al dar finalizar al formulario
   *
   **/
    onFinish = values => {

        if(this.props.categoria_id !== undefined){
            this.updateCategoria(values)
        }else{
            this.addCategoria(values);
        }
        
    }


    /**
    * @memberof FormCategorias
    *
    * @method getData
    * @description  trae la informacion de una categoria
    *
    **/
    getData = (values) => {
        this.setState({loading: true})
        axios.post('/categorias/get', { 
            id: this.props.categoria_id,
            ...values
        }).then(response => {
            this.formModalCategoria.current?.setFieldsValue({
                nombre: response.data.data.nombre,
                descripcion: response.data.data.descripcion,
            })
        
        }).catch(error => {
            message.error('Error al obtener la informacion')
            console.log(error)
        }).finally(()=>this.setState({loading: false}))
    }


    /**
    * @memberof FormCategorias
    *
    * @method updateCategoria
    * @description  Actualiza la informacion de una categoria
    *
    **/
    updateCategoria = (values) => {
        console.log('update')
        axios.put('/categorias/update', {
            id: this.props.categoria_id,
            nombre: values.nombre,
            descripcion:values.descripcion,
        })
        .then((response) => {
            message.success("Categoria Actualizada");
            this.props.onCancel()
        })
        .catch((error) => {
            console.log(error)
            Modal.warning({
                title: 'Error',
                content:
                    <List
                        size="small"
                        bordered
                        dataSource="la categoria no se ha podido editar"
                    />
            });
        })
    }

    /**
    * @memberof FormCategorias
    *
    * @method addCategoria
    * @description  Añade una categoria
    **/
    addCategoria = (values) => {
        console.log('add')
        axios.post('/categorias/add', {
            nombre: values.nombre,
            descripcion:values.descripcion, 
        })
        .then((response) => {
            message.success("Categoría creada");
            this.props.onCancel();
        })
        .catch((error) => {
            Modal.warning({
                title: 'Error',
                content:
                    <List
                        size="small"
                        bordered
                        dataSource="la categoria no se ha podido crear"
                    />
            });
        })
    }






    render() {

        const { loading } = this.state

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">Categoria</Title>
                <Form 
                    layout="vertical" 
                    ref={this.formModalCategoria} 
                    onFinish={this.onFinish} 
                >

                    <Row>
                        <Col xs={24}>
                            <Form.Item 
                                label="Nombre" 
                                name="nombre" 
                                rules={[{ required: true, message: "Por favor, ingrese el nombre" }]} 
                            >
                                <Input placeholder="Nombre"></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.Item 
                                label="Descripción" 
                                name="descripcion" 
                                rules={[{ required: true, message: "Por favor, ingrese la descripcion" }]} 
                            >
                                <TextArea placeholder="Descripción"></TextArea>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="center" align="middle" >
                        <Col span={24} className="flex-column">
                            <Form.Item>
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {

    let { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormCategorias {...props} />
        </Modal>
    )
}