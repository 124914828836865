import { Card, Row, Col, Button, Statistic, Typography, Divider } from "antd";
import "../../Styles/Modules/Public/Carrito.scss";

const { Text } = Typography;

/**
 * @const CardDetail
 * @description Boton flotante inferior derecho
 * @param function  onClick Accion a ejecutar al presionar el boton
 * @param String button_texto, titulo del boton
 * @param Array Lista de productos del carrito
 */
const CardDetail = (props) => {

    let {
        current = 0,
        productos = [],
        subTotal = 0,
        proceeder = () => { },
        user,
        loading,
        total = 0,
    } = props


    let tax = subTotal * 0.07;
    let shipping = subTotal === 0 ? 0 : subTotal <= 50 ? 19.90 : 0

    total = subTotal + tax + shipping


    const renderButtonTitle = () => {

        switch (current) {
            case 0:
                return "PAY WITH MY ACCOUNT"
            case 1:
                return "CONTINUE"
            case 2:
                return "PLACE ORDER"
            case 3:
                return "PLACE ORDER"

        }
    }

    return (
        <Card title="Order" className="card-detail-shop">
            {productos.map(item => {
                let total_producto = parseFloat(item.precio) * parseFloat(item.quantity) 
                return <Row justify="space-between" align="top" className="mt-1">
                    <Col span={2}>
                        <Text className="card-item-cantidad"> {item.quantity}</Text>
                    </Col>
                    <Col span={16}>
                        <Text ellipsis className="card-item-title">{item?.producto?.nombre} {item?.size?.size} {item?.categoria?.nombre}</Text>
                    </Col>
                    <Col span={6} className="text-right">
                        <Text className="card-item-costo">$ {(!isNaN(total_producto) ? total_producto : 0).toMoney()}</Text>
                    </Col>
                </Row>
            })}
            <Divider />
            <Row justify="space-between" align="middle" >
                <Col flex={2}>
                    <Text ellipsis className="card-item-title">Subtotal</Text>
                </Col>
                <Col flex={3} className="text-right">
                    <Text className="card-item-costo">$ {subTotal.toMoney()}</Text>
                </Col>
            </Row>
            <Row justify="space-between" align="middle" >
                <Col flex={2}>
                    <Text ellipsis className="card-item-title">Tax 7%</Text>
                </Col>
                <Col flex={3} className="text-right">
                    <Text className="card-item-costo">$ {(tax).toMoney()}</Text>
                </Col>
            </Row>
            <Row justify="space-between" align="middle" >
                <Col flex={2}>
                    <Text ellipsis className="card-item-title">Shipping</Text>
                </Col>
                <Col flex={3} className="text-right">
                    <Text className="card-item-costo"> { subTotal === 0 ? '$ 0.00' : (shipping === 0) ? "Free": "$ " +shipping.toMoney()} </Text>
                </Col>
            </Row>
            <Divider />
            <Row justify="space-between" align="middle" >
                <Col flex={2}>
                    <Text ellipsis className="card-item-costo">Order Total</Text>
                </Col>
                <Col flex={3} className="text-right">
                    <Text className="card-item-costo-total">$ {(total).toMoney()}</Text>
                </Col>
            </Row>
            <Divider />
            <Row justify="space-between" align="middle" >
                <Col span={24} className="mb-1">
                    <Text className="card-footer">
                        <Button loading={loading} className="button-pay" disabled={productos?.length === 0} className="w-100" type="primary" size="large" onClick={() => proceeder(1)}>
                            {renderButtonTitle()}
                        </Button>
                    </Text>
                </Col>
                {(current === 0 && (user == 0 || user == null || user == undefined)) ?
                    <Col span={24}>
                        <Text className="card-footer">
                            <Button loading={loading} disabled={productos?.length === 0} className="w-100" type="ghost" size="large" onClick={() => proceeder(0)}>
                                PAY AS A GUEST
                            </Button>
                        </Text>
                    </Col>
                    : null}
                <Col span={24}>
                    <Text className="card-footer">All the transactions of BedifyMe are powered by Stripe to ensure the safety of us and our clients. All of our shipping and logistics are handled by Fedex.</Text>
                </Col>
            </Row>
        </Card>
    )
}

export default CardDetail;