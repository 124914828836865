import React, { Component, useContext } from 'react';
import { Dropdown, Menu, Col, Row, Badge, Layout, Input, Space, Button, Modal, Typography } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';
import { FaPhone, FaEnvelope, FaCaretDown, FaHeart, FaUser, FaShoppingCart } from "react-icons/fa";

import { useHistory, Link, Redirect } from 'react-router-dom';

import Carrito from '../../Hooks/Carrito';

import { IconBus, IconBuyBag, IconUserCircle, IconHotDeals, IconCloseModal } from "../Widgets/Iconos";

import { SetUser, User } from "./../../Hooks/Logged"

import CustomAvatar from "../Widgets/Avatar/Avatar";

import Login from "./../Auth/Login"
import Register from "./../Auth/Register"

import '../../Styles/Global/header.scss';
import '../../Styles/Global/modales.css';
import axios from 'axios';

const { Header } = Layout;
const { Search } = Input;
const { SubMenu } = Menu;
const { Text } = Typography;
let myRef = React.createRef();



/**
 * @const Header
 * @description Header del sistema
 * @param {*} { setUser, user, showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal }
 */


class _Header extends Component {
    static contextType = User;
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            modalLoginVisible: false,
            modalRegisterVisible: false,
            drawerCartShopVisible: false,
            redirect: false,
            isLogged: false,
            categorias: []
        };
    }


    componentDidMount() {
        this.getCategorias();
    }

    getCategorias = () => {
        axios.get('/categorias', {
            params: {
                limit: 7
            }
        })
            .then(res => {
                const categorias = res.data.data.itemsList;
                this.setState({
                    categorias
                });
            })
    }


    renderRedirect = () => {

        if (this.state.to !== null && this.state.to !== undefined && this.state.to !== "")
            return <Redirect to={this.state.to} />
    };


    setRedirect = (to) => this.setState({ to })

    cerrarSession = () => {
        this.props.SetUser(0);
        sessionStorage.clear();
        localStorage.clear()
        axios.defaults.headers.common['Authorization'] = null
        this.setRedirect("/");

    };


    abrirModalLogin = () => {
        this.setState({
            modalLoginVisible: true,
            modalRegisterVisible: false

        })
    }

    cerrarModal = () => {
        this.setState({
            modalLoginVisible: false,
            modalRegisterVisible: false
        })
    }

    abrirmodalRegistro = () => {
        this.setState({
            modalLoginVisible: false,
            modalRegisterVisible: true
        })

        console.log("Registro")
    }

    /**
    * @memberof Header
    *
    * @method   getFistName
    * @description  Metodo que retorna el primer nombre del usuario
    * */
    getFirstName = (name) => {
        return name.split(" ")[0].toUpperCase()
    }



    render() {
        //Variables para almacenar las banderas
        var sesion = Boolean(sessionStorage.getItem('token') != null),
            admin = ((sessionStorage.getItem('isAdmin') != null) ? ((sessionStorage.getItem('isAdmin') == "true")) : false);


        return (

            <Header className="header-public">
                {this.renderRedirect()}
                <div className="information-bar">
                    <div className="header-section-container main">
                        <Space size={40}>
                            <div className="link-information-bar"><FaPhone style={{ position: "relative", top: 1, right: 3 }} /> +52 555 555 5555</div>
                            <div className="link-information-bar"><FaEnvelope style={{ position: "relative", top: 3, right: 3 }} /> padel@gmail.com</div>
                            {/*<div className="link-information-bar" style={{ fontWeight: "bold", fontSize: 16 }}> FREE SHIPPING IN ORDERS OVER $50</div>*/}
                        </Space>
                        <Space style={{ float: "right" }}>
                            {/* <div className="link-information-bar-options"><FaHeart style={{ position: "relative", top: 2 }} /></div>
                            <span className="link-information-bar divider" /> */}
                            <div className="link-information-bar-options">
                                <Dropdown overlay={
                                    (!this.context) ?
                                        <Menu>
                                            <Menu.Item onClick={this.abrirModalLogin} >Iniciar Sesion</Menu.Item>
                                            <Menu.Item onClick={this.abrirmodalRegistro}>Registrarse</Menu.Item>
                                        </Menu>
                                        :
                                        <Menu>
                                            <Menu.Item key="pedidos"  >
                                                <Link to={'/user/orders'}>My Account</Link>
                                            </Menu.Item>
                                            {(this.context?.tipo == 1) ?
                                                <Menu.Item key="dashboard"  >
                                                    <Link to={'/admin/dashboard'}>Admin Dashboard</Link>
                                                </Menu.Item>
                                                : null}
                                            <Menu.Item onClick={this.cerrarSession} >Logout</Menu.Item>
                                        </Menu>
                                }>

                                    {(!this.context) ?
                                        <Button><FaUser /></Button>
                                        :
                                        <Button>
                                            <FaUser />
                                        </Button>
                                    }
                                </Dropdown>
                            </div>
                            <span className="link-information-bar divider" />
                            <div className="link-information-bar-options">
                                <Button
                                    onClick={() => this.props.history.push('/cart')}
                                >
                                    <Badge onClick={() => this.props.history.push('/cart')} count={this.props.carrito?.reduce((accomulator, product) => parseFloat(accomulator) + parseFloat(product.quantity), 0)}></Badge>
                                    <FaShoppingCart style={{ position: "relative", top: 2 }} />

                                </Button>


                            </div>

                        </Space>
                    </div>
                </div>
                <div className="control-bar">
                    <div className="header-section-container" style={{ margin: "2em auto" }}>
                        <Row gutter={[25, 25]} align="middle" justify='space-between' style={{ margin: "0 10px" }}>
                            <Col xs={24} sm={6} md={5} lg={4}>
                                <Link to={"/"}>
                                    <img src="/images/logo.png" className="w-100" />
                                </Link>
                            </Col>

                            <Col span={20} className="flex-right menu-lg" align="right">
							<Menu className="header-menu width-100" mode="horizontal" theme="light" style={{justifyContent:"flex-end"}} defaultSelectedKeys={['1']}>

								<Menu.Item className="item" key="1">
									
										Inicio
									
								</Menu.Item>
								
								<Menu.Item className="item" key="2">
									
										Servicios
									
								</Menu.Item>
								
								
								<Menu.Item className="item" key="3">
                                    <Link to="/store">
                                        Tienda
                                    </Link>								
								</Menu.Item>
								
								
								<Menu.Item className="item" key="4">
									
										Galeria
									
								</Menu.Item>
								
								<Menu.Item className="item" key="5">
									
										Contactanos
									
								</Menu.Item>
							</Menu>
							
						</Col>


                            {/* <Col xs={24} sm={18} md={16} lg={14} className="gutter-row">
                                <Search
                                    className="w-100 input-search"
                                    placeholder="Search here..."
                                    onPressEnter={(value) => this.props.setSearch(value.target.value)}
                                />
                            </Col>
                            <Col xs={8} lg={6} className="gutter-row" >
                                <Space className="w-100" style={{ justifyContent: 'space-between' }}>
                                    <Link to="/store">
                                        <Button style={{ margin: '0px auto', display: 'block' }} type="text" icon={<AppstoreOutlined style={{}} />}></Button>
                                    </Link>
                                    <Badge onClick={() => this.props.history.push('/cart')} count={this.props.carrito?.reduce((accomulator, product) => parseFloat(accomulator) + parseFloat(product.quantity), 0)}>
                                        <Button
                                            onClick={() => this.props.history.push('/cart')}
                                            style={{ margin: '0px auto', display: 'block', }}

                                            type="text"
                                            icon={<IconBuyBag size={0.7}
                                            />}></Button>
                                    </Badge>



                                </Space>
                            </Col> */}

                        </Row>
                    </div>
                </div>
                <Login
                    onClickRegistrarmeButton={() => this.setState({
                        modalRegisterVisible: true,
                        modalLoginVisible: false
                    })}
                    visible={this.state.modalLoginVisible}
                    onCancel={this.cerrarModal}
                />
                <Register
                    onClickLoginButton={() => this.setState({
                        modalRegisterVisible: false,
                        modalLoginVisible: true
                    })}
                    visible={this.state.modalRegisterVisible}
                    onCancel={this.cerrarModal}
                />
            </Header>
        )
    }

}

export default function (props) {

    const history = useHistory()
    const carrito = useContext(Carrito)
    const user = useContext(User)
    const setUser = useContext(SetUser)

    return <_Header history={history} carrito={carrito} user={user} SetUser={setUser} {...props} />
};
