

import React, { Component } from "react";
import { Col, Form, message, Modal, List, Tag, Row, Divider, Statistic, Spin, Typography, } from 'antd';
import '../../../Styles/Global/modales.css';

import axios from 'axios'

const { Title, Text, Paragraph } = Typography;
const moment = require('moment')


const colors = ["#00000", "#87d068", "#FA9100"];


/**
 *
 *
 * @class ModalVenta
 * @extends {React.Component}
 * @description Formulario Detalle de Venta
 */
class ModalVenta extends Component {


    formModalCategoria = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            venta: {}
        }
    }


    /**
     * @methodOf ModalVenta
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component,
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        console.log('props', this.props)
        this.getVenta(this.props.id)
    }



    /**
     *
     *
     * @memberof ModalVenta
     * @description Trae la venta 
     * @id ObjectId de la venta
     */
    getVenta = (id) => {
        axios.get('/ventas/get', {
            params: {
                id: id
            }
        })
            .then(res => {
                console.log('res', res)
                this.setState({ venta: res.data.data })
            }).catch(error => {
                message.error('Error al traer detalle de venta');
                console.log('Ha ocurrido un error al traer la venta', error)
            })
    }


    /**
        *
        *
        * @memberof Ventas
        * @description Renderiza el Tag correspondiente al status de la venta
        * @param status  1 = En proceso, 2= En transito , 3= Entregado
        */
    renderTag = (status) => {

        let text = "";
        switch (status) {
            case 1:
                text = "En Proceso";
                break;
            case 2:
                text = "En Transito";
                break;
            case 3:
                text = "Entregado";
                break;

            default:
                break;
        }
        return <Tag color={colors[status]}>{text}</Tag>
    }



    render() {
        const { venta } = this.state;
        return (

            <>
                <Spin spinning={false}>
                    <Title level={3} className="text-center">Detalle Venta</Title>
                    <Form layout="vertical">
                        <Row justify="center" gutter={[24, 24]}>
                            <Col xs={12}>
                                <Paragraph >Nombre </Paragraph>
                                <Text type="secondary" >{venta.direccion?.nombre} </Text>
                            </Col>

                            <Col xs={12}>
                                <Paragraph >Correo </Paragraph>
                                <Text type="secondary" >{venta.direccion?.email} </Text>
                            </Col>
                            <Col xs={24}>
                                <Paragraph >Dirección </Paragraph>
                                <Text type="secondary" >{venta.direccion?.localization?.place_name_en} </Text>
                            </Col>
                            <Divider orientation="left" className="modal-divider">Productos</Divider>
                            <Col xs={24}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={venta.detalles}
                                    className="pd-1"
                                    header={
                                        <Row className="width-100 modal-list-detalles">
                                            <Col span={10}>nombre</Col>
                                            <Col span={6}>size</Col>
                                            <Col span={4}>cantidad</Col>
                                            <Col span={4}>precio</Col>
                                        </Row>
                                    }
                                    renderItem={item => (
                                        <List.Item>
                                            <Row className="width-100">
                                                <Col span={10} ><Text ellipsis > {item.variante_id.nombre}</Text></Col>
                                                <Col span={6} ><Text ellipsis > {item.variante_id.size_id.size}</Text></Col>
                                                <Col span={4} ><Text ellipsis > {item.cantidad}</Text></Col>
                                                <Col span={4} ><Text ellipsis > $ {item.precio.toMoney()}</Text></Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                    footer={
                                        [
                                            <Row className="width-100">
                                                <Col span={20} ><Text ellipsis strong> Subtotal</Text></Col>
                                                <Col span={4} ><Text ellipsis > $ {(venta.subtotal ? venta.subtotal : 0).toMoney()}</Text></Col>
                                            </Row>,
                                            <Row className="width-100">
                                                <Col span={20} ><Text ellipsis strong> TAX</Text></Col>
                                                <Col span={4} ><Text ellipsis > $ {(venta.iva ? venta.iva : 0).toMoney()}</Text></Col>
                                            </Row>,
                                            <Row className="width-100">
                                                <Col span={20} ><Text ellipsis strong> Shipping</Text></Col>
                                                <Col span={4} ><Text ellipsis > $ {(venta.envio ? venta.envio : 0).toMoney()}</Text></Col>
                                            </Row>,
                                            <Row className="width-100">
                                                <Col span={20} ><Text ellipsis strong> Total</Text></Col>
                                                <Col span={4} ><Text ellipsis strong type="success" style={{ fontSize: 18 }}> $ {(venta.total ? venta.total : 0).toMoney()}</Text></Col>
                                            </Row>
                                        ]
                                    }
                                />
                            </Col>
                            <Divider orientation="left" className="modal-divider">Venta</Divider>
                            <Col xs={24} lg={{ span: 11, }}>
                                <Form.Item label="Total" >
                                    <Statistic
                                        value={venta.total}
                                        valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#FF0000' }}
                                        prefix={'$'}
                                        suffix={'Dlls'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={{ span: 11 }}>
                                <Form.Item label="Estatus" >
                                    {this.renderTag(venta.status)}
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>
                </Spin>
            </>

        )
    }
}


/**
 *@function ModalDetalle
 *@description 
 */
export default function (props) {

    let { visible, close } = props

    return (
        <Modal
            visible={visible}
            title={null}
            footer={null}
            maskClosable={true}
            destroyOnClose={true}
            closable={true}
            onCancel={close}
            zIndex={1000}
            onOk={close}
            width={600}
        >
            <ModalVenta {...props} />
        </Modal>
    )
}