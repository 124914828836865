

import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, Statistic, Switch } from 'antd';

import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import PropTypes from "prop-types";
import Logged from '../../../Hooks/Logged';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import axios from 'axios'

const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;


/**
 *
 *
 * @class FormInventario
 * @extends {React.Component}
 * @description Formulario de categorias
 */
class FormInventario extends Component {


	formModalInventario = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			loadingImage: false,
			form: {},

			id: null,
			image: null,
			negocios: []

		}
	}


	/**
	 * @methodOf FormInventario
	 *
	 * @function componentDidUpdate
	 * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
	 *
	 * */
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		if(this.props.variante_id){
			this.getData()
		}
	}


	/**
   * @memberof FormInventario
   *
   * @method onFinish
   * @description  Se ejecuta al dar finalizar al formulario
   *
   **/
	onFinish = values => {
		this.setState({loading: true})
		if(this.props.variante_id !== undefined){
			this.updateInventario(values)
		}
		
	}


	/**
	* @memberof FormInventario
	*
	* @method getData
	* @description  trae la informacion de una categoria
	*
	**/
	getData = (values) => {
		this.setState({loading: true})
		axios.get(`/inventario/get`,{ 
			params:{
				variante_id: this.props.variante_id
			}
		}).then(async res => {
			console.log(res.data.data)
			this.formModalInventario.current?.setFieldsValue({
				existencias: res.data.data.existencias
			})
        }).catch(res => {
            console.log("error", res);
            message.error('Error al traer la informacion')
        }).finally(()=>this.setState({loading: false}))
	}


	/**
	* @memberof FormInventario
	*
	* @method updateInventario
	* @description  Actualiza la informacion de una categoria. Switch : Checked = entrada (1), Unchecked = salida(2) 
	*
	**/
	updateInventario = (values) => {
		this.setState({loading: true})
		console.log('values.tipo', values.tipo)
		axios.put('/inventario/update', {
            variante_id: this.props.variante_id,
            existencias: values.existencias,
			tipo : (values.tipo)?1:2,
			cantidad:values.cantidad
        })
        .then((res) => {
        	message.success('Existencias Actualziadas')
        	this.props.onCancel()
       	}).catch((error) => {
        	message.error('Error al actualizar')
        	console.log(error)        
        }).finally(()=>this.setState({loading: false}))
	}





	render() {

		const { loading } = this.state

		return (
			<Spin spinning={loading}>
				<Title level={3} className="text-center">Existencias</Title>
				<Form 
					layout="vertical" 
					ref={this.formModalInventario} 
					onFinish={this.onFinish} 
					initialValues={
						{
							tipo:true

						}
					}
				>				
					<Row className="mb-3">
						<Col align={'center'} span={24} xs={24} lg={24}>
                            <Form.Item 
                            	name="existencias" 
                            >
                                 <Statistic title="Existencias actuales" value={0} />
                            </Form.Item>
                       	</Col>
					</Row>
					<Row className="mb-3">
					<Col span={12} xs={12} lg={12}>
                            <Form.Item 
                            	name="cantidad" 
                            	label="Cantidad"
                            >
                              <Input placeholder="0"  type="number"/>
                            </Form.Item>
                       	</Col>
						<Col  align={"center"} span={12} xs={12} lg={12}>
                            <Form.Item 
								style={{textAlign:"center" }}
                            	name="tipo" 
                            	label="Movimiento"
								valuePropName="checked"
                            >
                               <Switch checkedChildren="Entrada" unCheckedChildren="Salida" />
                            </Form.Item>
                       	</Col>
					</Row>
					<Row justify="center" align="middle" >
						<Col span={24} className="flex-column">
							<Form.Item>
								<Button htmlType="submit" type="primary">
									Agregar movimiento
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Spin>

		)
	}
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {

	let { visible, onCancel } = props

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			title={null}
			footer={null}
			maskClosable={true}
			destroyOnClose={true}
			zIndex={1000}
		>
			<FormInventario {...props} />
		</Modal>
	)
}
