import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";



import WOW from 'wowjs'


import { Layout, Row, Col, Typography, Card, Button, Select, Form, Input, Avatar, Rate } from 'antd';
import Carousel from "react-multi-carousel";
import AwesomeSlider from 'react-awesome-slider';

import { CreditCard, Outlined, HeartOutlined, LeftOutlined } from '@ant-design/icons'
import { IconBus, IconStar, IconTiming, IconConversation, IconHotDeals } from "../Widgets/Iconos";

// import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-multi-carousel/lib/styles.css';
import 'react-awesome-slider/dist/styles.css';

import '../../Styles/Modules/Public/landing.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import WeLoveOurClients from "./WeLoveOurClients";
import HotDeals from "./HotSale";
import Productos from "./Productos";

import UpdatePassword from "../Auth/UpdatePassword";


import 'wowjs/css/libs/animate.css'  
// const AutoplaySlider = withAutoplay(AwesomeSlider);

const { Title, Paragraph, Text, } = Typography


const responsive = {
    desktop_fhq: {
        breakpoint: { max: 4000, min: 1200 },
        items: 4,
        partialVisibilityGutter: -70
    },
    desktop_hq: {
        breakpoint: { max: 1500, min: 1200 },
        items: 3,
        partialVisibilityGutter: -70
    },
    desktop_xxl: {
        breakpoint: { max: 1200, min: 992 },
        items: 3,
        partialVisibilityGutter: -70
    },
    desktop_xl: {
        breakpoint: { max: 992, min: 768 },
        items: 3,
        partialVisibilityGutter: -70
    },
    desktop_lg: {
        breakpoint: { max: 768, min: 576 },
        items: 3,
        partialVisibilityGutter: -70
    },
    desktop_md: {
        breakpoint: { max: 576, min: 464 },
        items: 3,
        partialVisibilityGutter: -70
    },
    desktop_sm: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        partialVisibilityGutter: -70
    },

    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: -70
    }
};

/**
 *
 *
 * @export
 * @class Landing
 * @extends {Component}
 * @description Pagina principal del sistema
 */
export default class Landing extends Component {

    static defaultProps = {
        recovery: false
    }

    constructor(props) {
        super(props)
        this.state = {
            showRecovery: this.props.recovery
        }
    };



    componentDidMount() {
        this.props.setCategoria(null)
        this.props.setHot(false)
        new WOW.WOW({
            live: true
        }).init();
    }

    ProductosPrueba = [
        {
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc ut aliquam ultricies, nunc nisl aliquam mauris, eget aliquam nisl nisl eu nunc. Sed euismod, nunc ut aliquam ultricies, nunc nisl aliquam mauris, eget aliquam nisl nisl eu nunc.",
            bg: "/images/bg-slider.svg",
            img: "/images/landing/raqueta.png",
            modelo: "Modelo 1",
            precio: 100,

        },
        {
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc ut aliquam ultricies, nunc nisl aliquam mauris, eget aliquam nisl nisl eu nunc. Sed euismod, nunc ut aliquam ultricies, nunc nisl aliquam mauris, eget aliquam nisl nisl eu nunc.",
            bg: "/images/bg-slider-2.svg",
            img: "/images/landing/raqueta.png",
            modelo: "Modelo 1",
            precio: 100,
        },
        {
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc ut aliquam ultricies, nunc nisl aliquam mauris, eget aliquam nisl nisl eu nunc. Sed euismod, nunc ut aliquam ultricies, nunc nisl aliquam mauris, eget aliquam nisl nisl eu nunc.",
            bg: "/images/bg-slider-3.svg",
            img: "/images/landing/raqueta.png",
            modelo: "Modelo 1",
            precio: 100,
        },
        {
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc ut aliquam ultricies, nunc nisl aliquam mauris, eget aliquam nisl nisl eu nunc. Sed euismod, nunc ut aliquam ultricies, nunc nisl aliquam mauris, eget aliquam nisl nisl eu nunc.",
            bg: "/images/bg-slider.svg",
            img: "/images/landing/raqueta.png",
            modelo: "Modelo 1",
            precio: 100,
        }
    ]


    render() {


        return (
            <Layout className="landing">
                <video autoPlay muted loop class="video-mainhead">
                    <source src="/video/bg.mp4" type="video/mp4" />
                </video>
                <div style={{ zIndex: 1 }}>
                    <Row className="center logo-slider">
                        <img src="/images/logo.png" alt="img" />
                    </Row>

                    <Slider {...{
                        dots: true,
                        infinite: true,
                        speed: 500,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }} className="w-100">
                        {this.ProductosPrueba.map((item, index) => {

                            return (
                                <div>
                                    <Row className="center">
                                        <Text className="producto-desc mt-1">{item.desc}</Text>
                                    </Row>
                                    <Row className="center">
                                        <Button className="mt-1 producto-buy">Comprar ahora</Button>
                                    </Row>
                                    <Row align="end" className="producto-precio">
                                        <Col span={6}>
                                            <Card className="producto-info">

                                                <Row align="middle" className="precios">
                                                    <Col span={8} className="producto-info-img">
                                                        <img src={item.img} alt="img" />
                                                    </Col>
                                                    <Col span={15} push={1}>
                                                        <Row align="left">
                                                            <Text className="producto-precio-modelo">{item.modelo}</Text>
                                                        </Row>
                                                        <Row align="left">
                                                            <Text className="producto-precio-text">$ {item.precio.toMoney(true)}</Text>
                                                        </Row>
                                                    </Col>
                                                </Row>




                                                {/*  <div >
                                                    <img src={item.img} alt="img" />
                                                </div>
                                                <div className="producto-info-label">
                                                    <Row className="center">
                                                        <Text className="producto-precio-text">{item.modelo}</Text>
                                                    </Row>
                                                    <Row className="center">
                                                        <Text className="producto-precio-text">$ {item.precio.toMoney(true)} MXN</Text>
                                                    </Row>
                                                </div> */}




                                            </Card>
                                        </Col>


                                    </Row>
                                    <Row className="center producto-imagenes">
                                        <img className="producto-bg" src={item.bg} />
                                        <img className="producto-img" src={item.img} />
                                    </Row>
                                </div>
                            )
                        })}

                    </Slider>
                </div>

                {/* <AutoplaySlider
                    play={true}
                    // cancelOnInteraction={false} // should stop playing on user interaction
                    interval={4000}

                    bullets={false}
                    style={{ height: "calc(100vh - 150px)" }}>

                    <div
                        data-src="/images/bg-1.png"
                        onTransitionEnd={(e, x, g) => {
                        }}
                        className="menmen"
                    >
                        <div className="landing-slider-slide">
                            <Title level={2} className="landing-slider-slug"><b>The</b> Home Decor <b>Store</b></Title>
                            <Link to={'/store'} className="landing-slider-button-link">
                                Shop Now
                            </Link>
                        </div>
                    </div>
                    <div
                        data-slug="Bedding Fasion"
                        data-src="/images/bg-2.png"
                        onTransitionEnd={(e, x, g) => {
                        }}
                    >
                        <div className="landing-slider-slide">
                            <Title level={2} className="landing-slider-slug">We Proudly Carry</Title>
                            <img src="/images/vianney.png" alt="" className="landing-slider-brand" />
                            <Link to={'/store'} className="landing-slider-button-link">
                                Shop Now
                            </Link>
                        </div>
                    </div>
                </AutoplaySlider> */}

                <Row className="center">
                    <Card className="card-encuentra shadow-card">
                        <Row align="center">
                            <Title className="titulo" strong>Encuentra lo que necesitas</Title>
                        </Row>
                        <Form layout="vertical">
                            <Row align="bottom" justify="space-between">
                                <Col span={6}>
                                    <Form.Item label="Palas">
                                        <Select>
                                            <Select.Option value="1">Palas de control</Select.Option>
                                            <Select.Option value="2">Palas Equilibradas</Select.Option>
                                            <Select.Option value="3">Palas de poder</Select.Option>
                                            <Select.Option value="4">Palas para dama</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Vestimenta">
                                        <Select>
                                            <Select.Option value="1">Faldas</Select.Option>
                                            <Select.Option value="2">Tennis</Select.Option>
                                            <Select.Option value="3">Camisas</Select.Option>
                                            <Select.Option value="4">Tops</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Accesorios">
                                        <Select>
                                            <Select.Option value="1">Mochilas</Select.Option>
                                            <Select.Option value="2">Protectores</Select.Option>
                                            <Select.Option value="3">Pesas</Select.Option>
                                            <Select.Option value="4">Pelotas</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Button className="producto-buy">Buscar</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Row>




                <Row className="center mt-2">
                    <Carousel
                        ssr
                        partialVisbile
                        infinite
                        containerClass="carousel-container"
                        responsive={responsive}
                        className="carousel-cards"

                    >

                        <Card className="card-categ">
                            <img src='/images/categ-1.png' />
                            <Row className="categ-info" align="middle">
                                <Col span={24}>
                                    <Text className="categ-title">Zapatos</Text>
                                </Col>
                                <Col span={24}>
                                    <Button className="producto-buy">Ver Más</Button>
                                </Col>
                            </Row>
                        </Card>

                        <Card className="card-categ">
                            <img src='/images/categ-2.png' />
                            <Row className="categ-info" align="middle">
                                <Col span={13}>
                                    <Text className="categ-title">Accesorios y Ropa</Text>
                                </Col>
                                <Col span={24}>
                                    <Button className="producto-buy">Ver Más</Button>
                                </Col>
                            </Row>
                        </Card>

                        <Card className="card-categ">
                            <img src='/images/categ-3.png' />
                            <Row className="categ-info" align="middle">
                                <Col span={24}>
                                    <Text className="categ-title">Pelotas</Text>
                                </Col>
                                <Col span={24}>
                                    <Button className="producto-buy">Ver Más</Button>
                                </Col>
                            </Row>
                        </Card>

                        <Card className="card-categ">
                            <img src='/images/categ-1.png' />
                            <Row className="categ-info" align="middle">
                                <Col span={24}>
                                    <Text className="categ-title">Raquetas</Text>
                                </Col>
                                <Col span={24}>
                                    <Button className="producto-buy">Ver Más</Button>
                                </Col>
                            </Row>
                        </Card>



                    </Carousel>
                </Row>

                <Row className="ventajas" justify="center">
                    <div className="w-100 center">
                        <Title>Nuestras Ventajas</Title>
                    </div>
                    <div className="w-80 mt-2">
                        <Row gutter={[24, 24]} justify="center">
                            <Col span={6}>
                                <Row align="center">
                                    <img src="/images/advantages-1.svg" alt="" />
                                </Row>
                                <Row align="center mt-2">
                                    <Title className="ventaja-title" level={4}>Envíos a gratis todo el país a partir de $ 500 MXN</Title>
                                </Row>
                            </Col>
                            <Col span={6}>
                                <Row align="center">
                                    <img src="/images/advantages-2.svg" alt="" />
                                </Row>
                                <Row align="center mt-2">
                                    <Title className="ventaja-title" level={4}>Garantía de servicio por 3 meses</Title>
                                </Row>
                            </Col>
                            <Col span={6}>
                                <Row align="center">
                                    <img src="/images/advantages-3.svg" alt="" />
                                </Row>
                                <Row align="center mt-2">
                                    <Title className="ventaja-title" level={4}>Cambios y devoluciones hasta despues de 14 dias de la compra</Title>
                                </Row>
                            </Col>
                            <Col span={6}>
                                <Row align="center">
                                    <img src="/images/advantages-4.svg" alt="" />
                                </Row>
                                <Row align="center mt-2">
                                    <Title className="ventaja-title" level={4}>Descuento para nuestros clientes</Title>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Row>

                <Productos />

                <Row className="subscribe" justify="center" >
                    <div className="w-80">
                        <Row align="middle">
                            <Col span={12}>
                                <Title>Subscribe</Title>
                                <Paragraph className="subtitle">Recibe las mejores ofertas y promociones</Paragraph>
                                <Form name="horizontal_login" layout="inline" >
                                    <Form.Item
                                        name="email"

                                    >
                                        <Input placeholder="E-mail" />
                                    </Form.Item>
                                    <Form.Item>

                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Send
                                        </Button>

                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col span={12}>
                                <div className="space wow fadeInUp">
                                    <img style={{ width: "100%" }} src="/images/logo2.png" />
                                </div>

                            </Col>
                        </Row>

                    </div>
                </Row>

                <HotDeals />

                <Row className="ventajas opinion" justify="center" >
                    <div className="w-100">
                        <div className="w-100 center">
                            <Title>Opiniones</Title>
                        </div>

                        <Row className="center mt-2">

                            <Carousel
                                ssr
                                partialVisbile
                                infinite
                                containerClass="carousel-container"
                                responsive={responsive}
                                className="carousel-cards"
                            >

                                <Card className="card-categ">

                                    <Row className="" align="middle">
                                        <Col span={24}>
                                            <Paragraph className="opinion">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempoinc ididunt
                                                ut magna aliqua dolor sit amet, consectetur adipiscing elit magna</Paragraph>
                                        </Col>
                                        <Col span={24}>

                                            <Row>
                                                <Col span={7}>
                                                    <Avatar size={70} />
                                                </Col>
                                                <Col span={17}>
                                                    <Row className="mt-1">
                                                        <Text level={5}>Nombre Apellido</Text>
                                                    </Row>
                                                    <Row>
                                                        <Rate size="small" />
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>

                                <Card className="card-categ">

                                    <Row className="" align="middle">
                                        <Col span={24}>
                                            <Paragraph className="opinion">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempoinc ididunt
                                                ut magna aliqua dolor sit amet, consectetur adipiscing elit magna</Paragraph>
                                        </Col>
                                        <Col span={24}>

                                            <Row>
                                                <Col span={7}>
                                                    <Avatar size={70} />
                                                </Col>
                                                <Col span={17}>
                                                    <Row className="mt-1">
                                                        <Text level={5}>Nombre Apellido</Text>
                                                    </Row>
                                                    <Row>
                                                        <Rate size="small" />
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>

                                <Card className="card-categ">

                                    <Row className="" align="middle">
                                        <Col span={24}>
                                            <Paragraph className="opinion">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempoinc ididunt
                                                ut magna aliqua dolor sit amet, consectetur adipiscing elit magna</Paragraph>
                                        </Col>
                                        <Col span={24}>

                                            <Row>
                                                <Col span={7}>
                                                    <Avatar size={70} />
                                                </Col>
                                                <Col span={17}>
                                                    <Row className="mt-1">
                                                        <Text level={5}>Nombre Apellido</Text>
                                                    </Row>
                                                    <Row>
                                                        <Rate size="small" />
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>

                                <Card className="card-categ">

                                    <Row className="" align="middle">
                                        <Col span={24}>
                                            <Paragraph className="opinion">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempoinc ididunt
                                                ut magna aliqua dolor sit amet, consectetur adipiscing elit magna</Paragraph>
                                        </Col>
                                        <Col span={24}>

                                            <Row>
                                                <Col span={7}>
                                                    <Avatar size={70} />
                                                </Col>
                                                <Col span={17}>
                                                    <Row className="mt-1">
                                                        <Text level={5}>Nombre Apellido</Text>
                                                    </Row>
                                                    <Row>
                                                        <Rate size="small" />
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>



                            </Carousel>
                        </Row>

                    </div>
                </Row>

                <Row className="mt-3" justify="center">
                    <Title>Sponsors</Title>
                    <div className="w-100 mt-2">
                        <Row gutter={[24, 24]} justify="center">
                            <Col span={6}>
                                <Row align="center">
                                    <img src="/images/advantages-1.svg" alt="" />
                                </Row>

                            </Col>
                            <Col span={6}>
                                <Row align="center">
                                    <img src="/images/advantages-2.svg" alt="" />
                                </Row>

                            </Col>
                            <Col span={6}>
                                <Row align="center">
                                    <img src="/images/advantages-3.svg" alt="" />
                                </Row>

                            </Col>
                            <Col span={6}>
                                <Row align="center">
                                    <img src="/images/advantages-4.svg" alt="" />
                                </Row>

                            </Col>
                        </Row>
                    </div>
                </Row>

                <UpdatePassword
                    visible={this.state.showRecovery}
                    {...this.props}
                />
            </Layout>
        )
    }

}