import React, { Component } from 'react'
import { Row, Col, Card } from 'antd';
import { Productos_Categorias, Productos_Activos } from './../Widgets/graficas'
import '../../Styles/Modules/Analytics/analytics.css'
import axios from 'axios';

class Analytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            montado: false,
            productos: []
        }

    }

    componentDidMount() {
        console.log('analytics montado')
        axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');

        axios.get('/analytics').then(response => {
            let data = response.data.data;
            console.log('data', data)
            this.setState({ productos: data })
            this.setState({ montado: true })
        }).catch(error => {
            console.log('error analytics', error)
            this.setState({ montado: true })
        })
    }


    renderActivos() {
        if (this.state.montado) {
            return < Productos_Activos productos={this.state.productos} />
        }
    }
    render() {
        return (
            <Card className="card-container analytics  width-100 heigth-100"  title="Analytics" >
                <Row>
                    <Col xs={24} sm={24}>
                        <Card className="cardAnalitics" title="Productos Activos" bordered={false}>
                            {this.renderActivos()}
                        </Card>
                    </Col>

                </Row>


            </Card>
        );
    }

}

export default Analytics;