import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';


import { Steps } from 'antd'

import { User, SetUser } from './Hooks/Logged';
import { SetCarrito, Carrito } from './Hooks/Carrito';



import Routes from './Routes';

import './Styles/Theme/antd-manantial-theme.css';
import './App.css';
import './Styles/Global/global.css';

import axios from "axios"


// axios.defaults.baseURL = "http://localhost:4021" 
axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE



axios.defaults.headers.post["Content-Type"] = "application/json";

/**
 *
 *
 * @class App
 * @extends {Component}
 */
class App extends Component {


  /**
   *Creates an instance of App.
   * @param {*} props
   * @memberof App
   */
  constructor(props) {
    super(props);
    this.state = {
      user: 0,
      carrito: []
    }
  }

  componentDidMount() {
    axios.get('/user/logged', {
      headers: { Authorization: sessionStorage.getItem('token') }
    })
      .then(({ data }) => this.setUser(data.data))
      .catch((error) => { })
  }

  setUser = (user) => {
    this.setState({ user })
  };


  setCarrito = (carrito, callback = () => {}) => {
    console.log(carrito)
    this.setState({ carrito },callback)
  };


  render() {
    const { setUser, setCarrito } = this;
    const { user, carrito } = this.state;

    return (
      <BrowserRouter>
        <Carrito.Provider value={carrito}>
          <SetCarrito.Provider value={setCarrito}>
            <User.Provider value={user}>
              <SetUser.Provider value={setUser}>
                <Routes />
              </SetUser.Provider>
            </User.Provider>
          </SetCarrito.Provider>
        </Carrito.Provider>

      </BrowserRouter>
    );
  }


}

export default (App);