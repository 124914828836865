import React, { Component } from "react";
import { Form, Layout, Input, Button, Row, Col, Select, Upload, Switch, Avatar, message,Divider, Modal, List } from 'antd';
import { FileImageOutlined, UserOutlined } from '@ant-design/icons';
import { Redirect, Link } from "react-router-dom";
import axios from 'axios'

const { Content, Header } = Layout;
const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;


var img_uploaded = [];

/**
*
* @memberof CrearProducto
*
* @method addFile
* @description Sube un archivo al WebService.
*
*/
const addFile = {
    name: 'file',
    listType: "picture-card",
    action: 'http://localhost:4000/upload/add',
    multiple: true,

    onChange(img) {
        if (img.file.status === 'done') {
            console.log("repuesta de img", img.file.response.filename);
            img_uploaded.push(img.file.response.filename);
            message.success(`${img.file.name} Archivo subido con exito`);
        } else if (img.file.status === 'error') {
            message.error(`${img.file.name} El archivo no se ha podido subir.`);
        }
    },
};
class CrearProducto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nombreVista: 'Crear Producto',
            categorias: [],
            imagenes: [''],
            prod_activo: true,
            fileList: ['']
        }
    }

    /**
    * @memberof CrearProducto
    *
    * @method componentDidMount
    * @description  Al montar el componente trae los datos de las categorias y tamanos y los asigna al state.categorias y state.tamanos
    *
    **/
    componentDidMount() {
        this.getCategorias();
      
    }
    /**
    * @memberof CrearProducto
    *
    * @method getCategorias
    * @description  metodo que contiene la peticion de obtener todas las categorias para asignarlos al state.categorias
    *
    **/
    getCategorias = () => {
        axios.get('/categorias', {
            headers: { Authorization: sessionStorage.getItem("token") }
        })
            .then(res => {

                this.setState({
                    categorias: res.data.data.itemsList
                })

                console.log("categorias cargadas", res);
            })
            .catch(res => {
                console.log("error", res);
            });
    }

    /**
   * @memberof CrearProducto
   *
   * @method onChangeActive
   * @description  Actualiza el valor del switch del input activo al state.prod_activo
   *
   **/
    onChangeActive = (checked) => {
        console.log("nuevo estatus producto",checked);
        this.setState({ prod_activo: checked });
    };

    /**
    *
    * @memberof CrearProducto
    *
    * @event normFile
    * @description Se ejecuta al actualizar el Dragger, solamente es necesario los eventos cuando se sube y cuando se elimina.
    *
    * @param event (event)
    * Datos del evento.
    */

    normFile = async (event) => {
            console.log('normaFile',event)
            let update_fileList = false;
            let delete_file = false;
            /**
             * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
             */
            if (event.file.status == "done") {
                update_fileList = true;
            }
            if (event.file.status == "removed") {
                update_fileList = true;
                delete_file = true;
            }
            if (delete_file)
                this.removeFile((event.file.response.filename != undefined) ? event.file.response.filename : event.file.name);


            if (update_fileList){
                let arrayList = [];
                // console.log(event.fileList.map(file => (file.response)));
                // const array1 = ['a', 'b', 'c'];
                // await event.fileList.map(async element  => console.log(element));
                for (let x = 0; x < event.fileList.length; x++){
                    try{
                        console.log(event.fileList[x])
                        arrayList.push(event.fileList[x].response.filename)
                    }catch (e) {
                        console.log(e)
                    }
                }

                this.setState({
                    fileList: arrayList
                });
            }
            return event && event.fileList;
    };

    /**
     *
     * @memberof CrearProducto
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };


    /**
   * @memberof CrearProducto
   *
   * @method handleSubmit
   * @description  Envia los datos del formulario al Servidor
   *
   * @param values (array)
   * Contiene los campos del formulario para registrar al producto
   *
   * @returns response (array)
   **/
    handleSubmit = values => {
        console.log(this.state);
        console.log(values);
        axios.post('/productos/add', {
            nombre:       values.nombre,
            descripcion:  values.descripcion,
            categoria_id: values.categoria,
            tamano_id:    values.tamano,
            precio:       values.precio,
            sku:          values.sku,
            imagenes:     this.state.fileList,
            existencias:  values.existencias,
            activo:       this.state.prod_activo

        },
            { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                console.log(res);
                message.success(res.data.message);
                this.setState({ return: true });

            })
            .catch((error) => {
                Modal.warning({
                    title: 'Error',
                    content:
                        <List
                            size="small"
                            bordered
                            dataSource="No se ha podido crear el producto"
                        />
                });
            });
    }

    /**
   * @memberof CrearProducto
   *
   * @method renderRedirect
   * @description  Activa el redireccionamiento si el formulario se envio con exito
   *
   **/
    renderRedirect = () => {
        if (this.state.return) {
            return <Redirect to="/admin/productos" />;
        }
    };

    render() {
        return (
            <Layout>
            {this.renderRedirect()}
            <Header className="admin-header" style={{height: 'auto'}}>
                <Row>
                    <Col span={10}>
                        <h3 id="nombreVista" className="nombreVista encabezado">{this.state.nombreVista}</h3>
                    </Col>
                </Row>
            </Header>
            <Content className="box-shadow">
                <div>
                    <div className="div-contenedor">
                        <Col span={16} offset={4}>
                            <Form onFinish={this.handleSubmit} layout={"vertical"} ref={this.formRef}>
                                <Row gutter={[16, 16]}>
                                    <Col span={12} xs={24} lg={12}>
                                        <Form.Item name="nombre" label="Nombre" rules={[{
                                            required: true,
                                            message: 'Por favor ingresa nombre de producto'
                                        }]}>
                                            <Input placeholder="Nombre del producto" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} xs={24} lg={{span: 11, push: 1}}>
                                        <Form.Item name="categoria" label="Categoría" rules={[{
                                            required: true,
                                            message: 'Por favor selecciona categoría'
                                        }]}>
                                            <Select placeholder="Seleccionar opción" >
                                                    {this.state.categorias.map(function (categoria, index) {
                                                        return <Option value={categoria._id}>{categoria.nombre}</Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} md={24} lg={24}>
                                        <Form.Item name="descripcion" type='textarea' label="Descripción" rules={[{
                                            required: true,
                                            message: 'Por favor ingresa descripción'
                                        }]}>
                                            <TextArea
                                                placeholder="Descripción del producto"
                                                
                                                style={{minHeight: '100px', width: '100%'}}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={12} xs={24} md={8} lg={8}>
                                        <Form.Item name="sku" label="Sku" rules={[{
                                            required: true,
                                            message: 'Por favor ingresa el sku de producto'
                                        }]}>
                                            <Input placeholder="ABCDS0123" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} xs={24} md={8} lg={8}>
                                            <Form.Item name="activo" label="Producto activo">
                                                    <Switch defaultChecked  onChange={this.onChangeActive}  style={{float: 'left'}}/>
                                                </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item label="Imagenes">
                                        <Form.Item
                                            name="file"
                                            getValueFromEvent={this.normFile}
                                        >
                                            <Dragger

                                                listType="picture-card"
                                                action={axios.defaults.baseURL + 'upload/add'}
                                                multiple={true}
                                                // data={(e)=>{
                                                //     console.log('e',e)
                                                // }}
                                                // onRemove={this.removeFile}

                                                >
                                                <p className="ant-upload-drag-icon" style={{ marginBottom: '5px' }} >
                                                    <FileImageOutlined style={{ fontSize: '30px' }} />
                                                </p>
                                                <p className="ant-upload-text">Agrega imagenes del producto</p>
                                                <p className="ant-upload-hint"> Haga clic o arrastre el archivo a esta área para cargar</p>
                                            </Dragger>
                                        </Form.Item>
                                    </Form.Item>
                                <Row>
                                    <Col span={12} xs={24} lg={12}>
                                        
                                    </Col>
                                    <Col span={12} xs={24} lg={{span: 12}}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit"
                                                    >
                                                Guardar
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </div>
                </div>
            </Content>

        </Layout>
        )
    }
}

export default CrearProducto;
