import React from 'react'
import { Route, Switch } from "react-router-dom";
import Suscriptores from '../components/Admin/Suscriptores/Suscriptores';


/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function SuscriptoresRouter( props) {
    return (
        <Switch>
            <Route exact path="/admin/suscriptores" render={() => <Suscriptores {...props} />} />
        </Switch>
    )
}

export default SuscriptoresRouter;
