import React, { useEffect, useState, Component, useContext } from 'react';
import { Link } from "react-router-dom"
import {
    Layout, Row, Col, Typography, Card, Button, Spin, Menu, Dropdown, Form, Slider, Select, message, Image, Modal, Carousel, Space, Radio
} from 'antd';
import '../../Styles/Modules/Public/store.scss';
import { Carrito, SetCarrito } from '../../Hooks/Carrito';


import ProductControl from '../Widgets/ProductControl/ProductControl';

import axios from "axios"

const { Title, Paragraph, Text } = Typography
const { Option } = Select

// const axios = require('axios')


function InputControlProduct(props) {


    console.log('props', props)
    const { limit, onChange } = props
    let [value, setValue] = useState(props.value)


    // value, quantity
    const onQuantityChange = (index, quantity) => {
        console.log('index', index)
        console.log('quantity', quantity)

        // console.log(index, quantity)
        // let tempListProducts = listProducts
        const valueA = parseFloat(quantity)

        if (isNaN(valueA))
            return message.error("Is not a valid number")

        if (valueA > limit)
            return message.error("No more availability.")

        if (valueA <= 0)
            return message.error("Should be at least one product to add to the cart.")

        onChange(valueA)
        setValue(valueA)
    }







    return <ProductControl
        className="product"
        onQuantityChange={onQuantityChange}
        index={null}
        quantity={value}
        limit={limit}
        deleteOption={false}

    />
}


/**
 *
 *
 * @export
 * @class ProductoDetalle
 * @extends {Component}
 * @description Pagina tipo tienda del sistema
 */
class ProductoDetalle extends Component {

    formProducto = React.createRef();
    carouselMain = React.createRef();
    carouselMinis = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            producto_id: this.props.match.params.producto_id,
            producto: {},
            imagenIdx: 0,
            imagenes: [],
            variantes: [],
            variante: {},
            variante_info: {},
            modalImage: false,
            spinning: false,
        }
    };

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProducto()
        this.getVariantes()
        window.scrollTo(0, 0)
    }


    /**
   * @memberof ProductoDetalle
   *
   * @method   getProducto
   * @description  Metodo que realiza la busqueda de productos
   **/
    getProducto = () => {
        this.setState({ loading: true })
        axios.get(`/productos/get`, {
            params: {
                id: this.props.match.params.producto_id,
            }
        }).then(async res => {
            this.setState({
                producto: res.data.data.producto,
                imagen: res.data.data.producto.imagenes[0],
                imagenes: res.data.data.producto.imagenes,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error obtaining the product.')
        })
        .finally(() => this.setState({loading: false}))

    }

    /**
   * @memberof ProductoDetalle
   *
   * @method   getVariantes
   * @description  Metodo que realiza la busqueda de variantes del producto
   **/
    getVariantes = () => {
        this.setState({ loading: true })
        axios.get('/variantes', {
            params: {
                producto_id: this.props.match.params.producto_id,
                tienda: true,
                page: 1,
                limit: 50
            }
        })
            .then(res => {
                const data = res.data.data.itemsList
                console.log('date', data)
                this.setState({
                    variantes: data,
                    variante: data[0] ? data[0] : {}
                })
                this.getVariante(data[0]._id)
                this.formProducto.current.setFieldsValue({
                    variante_id: data[0] ? data[0]._id : null
                })
            }).catch(res => {
                console.log("error", res);
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
* @memberof FormVariantes
*
* @method getVariantes
* @description  trae la informacion de una categoria
*
**/
    getVariante = (id) => {
        this.setState({ loading: true })
        axios.get(`/variantes/get`, {
            params: {
                id: id
            }
        }).then(async res => {
            let variante = res.data.data.variante;
            this.setState({ variante_info: variante })
        }).catch(res => {
            console.log("error", res);
            message.error('Error when bringing the information ')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
   * @memberof ProductoDetalle
   *
   * @method   getProducto
   * @description  Metodo que se ejecuta al agregar al carrito
   **/
    onFinish = (values) => {
        this.setState({ loading: true })

        let element = this.state.variante_info
        element.producto = this.state.producto
        element.categoria = this.state.producto?.categoria_id
        element.producto.categoria_id = this.state.producto?.categoria_id?._id
        element.size = this.state.variante.size_id
        element.size_id = this.state.variante?.size_id?._id
        element.producto_id = this.state.producto?._id
        element.quantity = values.cantidad
        element.limit = this.state.variante_info?.inventarios?.existencias
        // console.log(element)
        let carrito_actual = this.props.carrito;

        console.log('carrito_actual', carrito_actual)

        if (carrito_actual.length > 0) {
            let index = carrito_actual.findIndex(carr => carr._id == element._id)
            if (index === -1) {
                carrito_actual.push(element)
                message.success('Added to Cart')
            } else {
                console.log('element', element)
                console.log('element', index)
                console.log('carrito_actual', carrito_actual[index])
                carrito_actual[index] = element
                message.success('Quantity updated!')
            }
        } else {
            carrito_actual.push(element)
            message.success('Added to Cart')
        }


        this.props.setCarrito(carrito_actual, () => this.setState({ loading: false }))




    }


    render() {

        let {producto, imagenes, imagen, variante } = this.state
        return (
            <>
                <Layout className="store">
                    <Spin spinning={this.state.loading}>
                        <Row className="store-section responsive store-producto" justify="center">
                            <Col xs={20} xl={20}>
                                <Row gutter={24}>
                                    <Col xs={24} md={14} className="store-producto-gallery">
                                        <Carousel dots={false} ref={this.carouselMain} className="store-producto-carousel" afterChange={() => {}}>
                                            {
                                                imagenes?.length > 0 ? imagenes?.map((image, idx) => (
                                                    <Image
                                                        key={image + "-" + idx}
                                                        width={"100%"}
                                                        src={`${axios.defaults.baseURL}/upload/${image}`}
                                                        placeholder={true}
                                                    />
                                                )) : (
                                                    <Image
                                                        width={"100%"}
                                                        placeholder={true}
                                                    />
                                                )
                                            }
                                        </Carousel>
                                        <Space className='store-producto-minis w-100' wrap size={0}>
                                            {
                                                imagenes?.length > 0 ? imagenes?.map((image, idx) => (
                                                    <Image                                                                                                      
                                                        key={image + '-' + idx + "-mini"}
                                                        preview={false}
                                                        className={`${this.state.imagenIdx === idx && "selected"}`}
                                                        src={`${axios.defaults.baseURL}/upload/${image}`}
                                                        onClick={() => {
                                                            this.setState({
                                                                imagenIdx: idx
                                                            }, () => this.carouselMain.current?.goTo(idx))
                                                        }}
                                                        placeholder={true}
                                                    />
                                                )) : (
                                                    <Image
                                                        width={"100%"}
                                                        placeholder={true}
                                                    />
                                                )
                                            }
                                        </Space>
                                    </Col>
                                    <Col xs={24} md={10}>
                                       <Card className="store-producto-info">
                                            <div>
                                                <Link to="/store" className="store-producto-categoria">{producto.categoria_id?.nombre}</Link>
                                                <Title className="store-producto-title" level={4}>{producto.nombre}</Title>
                                                <div className="store-producto-price"> $ {variante.precio ? variante.precio.toMoney() : '0.00'} </div>
                                                <div className="store-producto-descripcion-title">Description:</div>
                                                <Paragraph className="store-producto-descripcion">{producto.descripcion}</Paragraph>
                                            </div>
                                            <div>
                                                <Form
                                                    layout="vertical"
                                                    ref={this.formProducto}
                                                    onFinish={this.onFinish}
                                                    initialValues={{
                                                        cantidad: 1
                                                    }}
                                                    onChangeValues={(changedValues, allValues) =>{
                                                        if(changedValues.variante_id){
                                                            let index = this.state.variantes.findIndex(vari => changedValues.variante_id === vari._id)
                                                            this.getVariante(changedValues.variante_id)
                                                            this.setState({ variante: this.state.variantes[index] })
                                                        }
                                                    }}
                                                >

                                                    <Row>
                                                        <Col xs={24}>
                                                            <Form.Item
                                                                name="variante_id"
                                                                label="Size"
                                                                className="mb-1"
                                                            >
                                                                <Radio.Group>
                                                                    {
                                                                        this.state.variantes.map(variante => (
                                                                            <Radio.Button key={variante._id} value={variante._id}>{variante.nombre}</Radio.Button>
                                                                        ))
                                                                    }
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24}>
                                                            <Form.Item
                                                                name="cantidad"
                                                                label="Quantity"


                                                            >
                                                                <InputControlProduct
                                                                    quantity={1}
                                                                    limit={this.state.variante_info?.inventarios?.existencias}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>

                                                    <Row justify="center" align="middle" >
                                                        <Col span={24} className="flex-column">
                                                            <Form.Item className="w-100">
                                                                <Button htmlType="submit" type="secondary" className="w-100">
                                                                    Add to cart
                                                                </Button>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </div>
                                       </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Spin>
                </Layout>
                <Modal
                    className="modal-image"
                    visible={this.state.modalImage}
                    onCancel={() => this.setState({ modalImage: false })}
                    title={null}
                    footer={null}
                    maskClosable={true}
                    destroyOnClose={true}
                    zIndex={1000}
                >
                    <img src={`${axios.defaults.baseURL}/upload/${imagen}`} className="w-100" />
                </Modal>
            </>
        )
    }
}

export default function Producto(props) {


    let setCarrito = React.useContext(SetCarrito)
    let carrito = React.useContext(Carrito)
    
    return (
        <ProductoDetalle setCarrito={setCarrito} carrito={carrito}  {...props} />
    );

}