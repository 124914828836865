import React, { useEffect, useState, Component, useContext } from 'react';
import { Typography, Col, Row, Card, List, Input, Space, Button, Tag, Modal, Statistic, message, } from 'antd';
import { PlusOutlined, MinusOutlined, DeleteOutlined, ConsoleSqlOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';
import HotDeals from '../../Public/HotSale';

import axios from 'axios';
import './ProductControl.scss';


const { Title, Paragraph } = Typography

export default function ShoppingCart(props) {

    const {
        onQuantityChange = () => { },
        deleteProduct = () => { },
        index = 0,
        quantity = 0,
        className = "",
        limit = 0,
        deleteOption
    } = props

    return <Space className={"controls-producto " + className}>
        <Input
            size="large"
            value={quantity}
            max={limit}
            onChange={e => onQuantityChange(index, e.target.value)}
            className="control-input-producto"
            addonBefore={<Button
                onClick={e => onQuantityChange(index, parseInt(quantity) - 1)}
                className="control-button-minus-producto"
                type="text"
                icon={<MinusOutlined className="icon" />}
            />}
            addonAfter={<Button
                onClick={e => onQuantityChange(index, parseInt(quantity) + 1)}
                className="control-button-plus-producto"
                type="text"
                icon={<PlusOutlined className="icon" />}
            />}
        />
        {(deleteOption == true) ? <Button
            onClick={e => deleteProduct(index)}
            className="control-input-delete-producto"
            icon={<DeleteOutlined className="icon" />} /> : null}

    </Space>

}
