import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, PageHeader, Tag, Button, Space } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { IconBus, IconEye } from "../../Widgets/Iconos";
import ModalTracking from "./ModalTracking";
//modales
 import ModalVenta from './ModalDetalle'
 import axios from 'axios'

const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;

const moment = require('moment');



const colors = ["#00000", "#87d068", "#FA9100"];
/**
 *
 *
 * @class Ventas
 * @extends {Component}
 */
export default class Ventas extends Component {
    constructor(props) {
        super(props);
        this.state = {

            data: [
                // {
                //     "_id": "619957c7a973c236f0c2d753",
                //     "usuarios_id": {
                //         "_id": "fdsaljf",
                //         "nombre": "Adan ramirez",
                //         "telefono": "123456",
                //     },
                //     "direccion": {
                //         "email": "ar@iseeyoutech.com",
                //         "telefono": "123456",
                //         "nombre": "Juanito Ramirez",
                //         "direccion": "DIRECCIOn",
                //         "direccion_adicional": [
                //             "DIRECCION ADICIONAL"
                //         ],
                //         "pais": "USA",
                //         "estado": "CALIFORNIA",
                //         "ciudad": "SAN DIEGO",
                //         "cp": "222040",
                //         "_id": "619957c7a973c236f0c2d754"
                //     },
                //     "detalles": [
                //         {
                //             "variante_id": {
                //                 "_id": "619957c7a973c236f0c2d70d",
                //                 "sku_variante": "Variante 0",
                //                 "nombre": "KING SIZE MAX",
                //                 "descripcion": "Edredon King size",
                //                 "producto_id": "619957c7a973c236f0c2d6eb",
                //                 "precio": 27,
                //                 "activo": true,
                //                 "size_id": "619957c7a973c236f0c2d6a1",
                //                 "createdAt": "2021-11-20T20:17:11.408Z",
                //                 "updatedAt": "2021-11-20T20:17:11.408Z",
                //                 "__v": 0
                //             },
                //             "cantidad": 4,
                //             "total": 49,
                //             "_id": "619957c7a973c236f0c2d755"
                //         }
                //     ],
                //     "status": 1,
                //     "subtotal": {
                //         "$numberDecimal": "193"
                //     },
                //     "iva": 8,
                //     "total": 109,
                //     "order_id": null,
                //     "guia_envio": false,
                //     "createdAt": "2021-11-20T20:17:11.597Z",
                //     "updatedAt": "2021-11-20T20:17:11.597Z",
                //     "__v": 0,
                //     "id": "619957c7a973c236f0c2d753"
                // },
            ],
            sending: false,
            itemCount: 0,
            perPage: 0,
            pageCount: 1,
            currentPage: 1,
            search: '',
            modaltrack_visible: false,
            modaldetalle_visible:false,
            venta_id: undefined,
            venta_email: undefined,
        }
    }

    /**
    * @memberof Ventas
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    *
    *
    **/
    componentDidMount() {
        this.props.setTitle('Ventas')
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getVentas();
    }



    /**
    * @memberof Ventas
    *
    * @method   getVentas
    * @description  Metodo que realiza el paginado de las Ventas
    * @param page  Number con la pagina a buscar, 
    * @param search String para buscar datos de la tabla, por defecto cadena vacia para busquedas
    *
    **/
    getVentas = (page = this.state.currentPage, search = this.state.search) => {
        axios.get('/ventas',
            {
                headers: { Authorization: sessionStorage.getItem("token") },
                params: {
                    page: page,
                    limit: 25,
                    search
                }
            }).then(res => {

                let data = res.data.data.itemsList;
                console.log('data', res);
                this.setState({
                    data: data,
                    currentPage: res.data.data.paginator.currentPage,
                    itemCount: res.data.data.paginator.itemCount,
                    perPage: res.data.data.paginator.perPage,
                    pageCount: res.data.data.paginator.pageCount
                });

            })
    }
    /**
  * @memberof Ventas
  *
  * @method   searchData
  * @description  Metodo que realiza la busqueda de productos
  * @param search String   con la info a buscar, por defecto cadena vacia para busquedas
  *
  **/
    searchData = (search) => {
        this.setState({ search: search });
        this.getVentas(1, search);

    }


    /**
     *
     *
     * @memberof Ventas
     * @description Renderiza el Tag correspondiente al status de la venta
     * @param status  1 = En proceso, 2= En transito , 3= Entregado
     */
    renderTag = (status) => {

        let text = "";
        switch (status) {
            case 1:
                text = "En Proceso";
                break;
            case 2:
                text = "En Transito";
                break;
            case 3:
                text = "Entregado";
                break;

            default:
                break;
        }
        return <Tag color={colors[status]}>{text}</Tag>
    }



    /**
     *
     *
     * @memberof Ventas
     * @description Envia un email con el tracking Number de la venta
     */
    sendTrackNumber = (id, email) => {
        this.setState({ venta_email: email, venta_id: id, modaltrack_visible: true })

    }


    /**
     *
     *
     * @memberof Ventas
     * @description Al cerrar modal refresca la vista
     */
    closeModal = () => {
        this.setState({ modaltrack_visible: false,modaldetalle_visible:false});
        this.getVentas();
    }

    showDetalle = (id) => {
        this.setState({ venta_id: id, modaldetalle_visible: true, })
    }

    render() {

        const columns = [
            {
                title: '#',
                dataIndex: 'order_id',
            },
            {
                title: 'Usuario',
                dataIndex: ['direccion', 'nombre'],
            },

            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
            },
            {
                title: 'Fecha',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (text, record) => (
                    moment(record.createdAt).format('LL')
                )
            },
            {
                title: 'Estatus',
                key: 'status',
                dataIndex: 'status',
                render: (text, record) => (
                    this.renderTag(record.status)
                )
            },
            {
                title: 'Acciones',
                key: 'actions',
                render: (text, record) => (
                    <Space direction="horizontal">

                        <Button
                            type="primary"
                            title="Detalle Venta"
                            onClick={() => this.showDetalle(record._id)}
                            icon={<IconEye/>}>
                        </Button>
                        <Button
                            title="Enviar Guia"
                            style={{ background: colors[record.status], border: 'none' }}
                            onClick={() => this.sendTrackNumber(record._id, record.direccion?.email)}
                            icon={<IconBus width={20} />}>
                        </Button>
                    </Space>
                ),
            },
        ];

        return (
            <Layout>
                <Content className="admin-content content-bg pd-1">
                    <Row className="mb-1">
                        <Col xs={24} md={12}>
                            <Search className="search-bar" placeholder="Buscar código..." enterButton="Buscar" onSearch={this.searchData} />
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={this.state.data}
                        pagination={{
                            onChange: this.getVentas,
                            total: this.state.itemCount,
                            pageSize: this.state.perPage,
                            current: this.state.currentPage
                        }}
                    />
                    <ModalTracking
                        visible={this.state.modaltrack_visible}
                        venta_id={this.state.venta_id}
                        email={this.state.venta_email}
                        close={this.closeModal}
                    />
                    <ModalVenta
                        visible={this.state.modaldetalle_visible}
                        id={this.state.venta_id}
                        close={this.closeModal}
                    />
                </Content>

            </Layout>
        )
    }
}

