import React, { Component, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "antd";

//COMPONENTES
import Header from '../components/Header/Header';
import { Boletin, _Footer } from '../components/Footer/Footer';
import { User, SetUser } from '../Hooks/Logged';


//ROUTERS
import Landing from "../components/Public/LandingPage";
import Store from "../components/Public/store";
import ThemeEdit from '../components/ThemeEdit/index';
import Login from '../components/Auth/Login';
import UpdatePassword from '../components/Auth/UpdatePassword';
import Steps from '../components/ShoppingCart/Steps';
import PaySummary from "../components/ShoppingCart/ResumenPago";
import ProductoDetalle from "../components/Public/ProductoDetalle";

//css
import '../Styles/Global/public.scss';



const { Content } = Layout;


/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function PublicRouter(props) {

    const [ search, setSearch ] = useState(null) //texto de busqueda del header
    const [ categoria, setCategoria ] = useState([]) //categoria selccionada en el header
    const [ hot, setHot ] = useState(false) //opcion de hot Deals en el header

    return <Layout className="layout-public">
        <Header  search={search} setSearch={setSearch} setCategoria={setCategoria} setHot={setHot} categorias={true}/>
        <Layout>
            <Content className="admin-content">
                <Switch>

                    <Route exact path="/" render={props => <Landing {...props} key="landing-page" setCategoria={setCategoria} setHot={setHot}/>} />

                    <Route exact path="/theme" component={ThemeEdit} />

                    <Route exact path="/store" render={(props)=><Store {...props} hot={hot} setHot={setHot} search={search} categoria={categoria} setCategoria={setCategoria}/>} />

                    <Route exact path="/store/:producto_id" component={ProductoDetalle} />

                    <Route exact path="/login" component={Login} />

                    <Route exact path="/recovery/email-:email/token-:token" render={props => <Landing key="recover-landing-page" setCategoria={setCategoria} setHot={setHot} {...props} recovery={true} />} />

                    <Route exact path="/cart"  render={() => <Steps  {...props} step={0} />}  />
                    
                    <Route exact path="/cart/summary" render={() => <Steps  {...props} step={3} />} />

                </Switch>

            </Content>
        </Layout>
        <Boletin />
        <_Footer  setCategoria={setCategoria} setHot={setHot} />

    </Layout>
}

export default PublicRouter;
