import React, { Component } from "react";
import { Form, Layout, Input, Button, Col,Row ,Modal,List,message} from 'antd';
import { Redirect } from "react-router-dom";
import axios from 'axios'

const { Header, Content } = Layout;
const { TextArea } = Input;


class EditarCategoria extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombreVista: '',
            categoria_id:'',
            data_categoria: {
                id: '',
                nombre: "",
                descripcion: ""
            }
        }
    }
    
    formRef = React.createRef();

    componentDidMount() {
        this.setState({ 
            nombreVista: 'Editar categoria',
            categoria_id:'',
            data_categoria: {
                id: '',
                nombre: "",
                descripcion: ""
            }
        });

        this.getData();
    }


    getData(){
        const cat_id = this.props.match.params.id;
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        axios.post('/categorias/get', { 
            id: cat_id
         },
        )

      .then(response => {
        if(response.data != undefined )
                { 
                this.setState({ categoria_id: cat_id });
                this.setState({ data_categoria:response.data.data });
                this.onFill()   
                }
          });

    }

    /**
     * @memberof EditarCategoria
     *
     * @method handleSubmit
     * @description  Envia los datos del formulario al Servidor
     *
     * @param values (array)
     * Contiene los campos del formulario
     *
     * @returns response (array)
     **/

    handleSubmit = values => {
    console.log('editar');
   
    axios.put('/categorias/update', {
        id: this.state.categoria_id,
        nombre: values.nombre,
        descripcion:values.descripcion,
    }
   )
    .then((response) => {
        console.log(response);
        if (response.status == 200) {
            message.success("Categoria Actualizada");
            this.setState({ redirect: true });
        }
    })
    .catch((error) => {
        console.log(error);
        Modal.warning({
            title: 'Error',
            content:
                <List
                    size="small"
                    bordered
                    dataSource="la categoria no se ha podido editar"
                />
        });
    })
    }
 
     /**
     * @memberof Edit
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
     **/

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to="/admin/categorias" />;
        }
    };

    onFill = () => {
        this.formRef.current.setFieldsValue({
          nombre: this.state.data_categoria.nombre,
          descripcion: this.state.data_categoria.descripcion,
        });
      };
     
    render() {

        return (
            <Layout>
                {this.renderRedirect()}
                <Header className="admin-header" style={{ height: 'auto' }}  >
                    <Row>
                        <Col span={10}>
                            <h3 id="nombreVista" className="nombreVista encabezado" >{this.state.nombreVista}</h3>
                        </Col>
                    </Row>
                </Header>
                <Content className="box-shadow">
                    <div>
                        <div className="div-contenedor" style={{ alignContent: 'center', alignItems: 'center' }} >
                            <Col span={12} xs={24} lg={{ span: 12, offset: 6 }} >
                            <Form  ref={this.formRef} name="control-ref" initialValues={{ remember: true }} onFinish={this.handleSubmit} layout={"vertical"} className="form-EditarCategoria center" >
                                    <Form.Item name="nombre" label="Nombre"
                                        rules={[{ required: true, message: 'Por favor ingresa nombre' }]}>
                                        <Input
                                            id="nombre"
                                            placeholder="Nombre de la categoría"
                                            
                                            defaultValue ={this.state.data_categoria.nombre}
                                        />
                                    </Form.Item>

                                    <Form.Item type='textarea' label="Descripción" name="descripcion"
                                        rules={[{ required: true, message: 'Por favor ingresa descripción' }]}>

                                        <TextArea
                                            id="descripcion"
                                            placeholder="Descripción de la categoría"
                                            
                                            style={{ minHeight: '100px' }}
                                            defaultValue={this.state.data_categoria.descripcion}
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button 
                                        type="primary" 
                                        htmlType="submit" 
                                        >
                                            Guardar
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </div>
                    </div>
                </Content>
            </Layout>
        )
    }
}

export default EditarCategoria;
