import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout, Menu, Row } from "antd";
import { Link } from 'react-router-dom';

//COMPONENTES
import Navbar from '../components/Header/Header';
import { User, SetUser } from '../Hooks/Logged';
import { Boletin, _Footer } from '../components/Footer/Footer';
import WeLoveOurClients from "./../components/Public/WeLoveOurClients";

import Pedidos from "../components/User/Pedidos/Pedidos"

import Login from "../components/Auth/Login"
import Register from "../components/Auth/Register"
import Direcciones from "../components/User/Direcciones/Direcciones"
import Sider from "antd/lib/layout/Sider";



//css
import '../Styles/Modules/User/user.css';



const { Content, Header, Footer } = Layout;

class UserRouter extends Component {

    static contextType = User

    constructor(props) {
        super(props)
        this.state = {
            back: false,
            responsiveSidebar: false,

            showSearch: false,
            filterSearch: "",
            searching: false
        };
    }


    componentDidMount () {
        if (!this.props.user)
            this.setState({ modalLoginVisible: true })

    }

    componentDidUpdate (prevProps) {
        if(!prevProps.user && this.props.user){
            this.setState({ modalLoginVisible: false })
        }
    }

    onModalClose = () => this.setState({ modalLoginVisible: false, modalRegisterVisible: false })

    onSuccess = () => {
        this.ref.current.getUserPerdidos()
        this.onModalClose()
    }


    ref = React.createRef()

    render() {
        const ruta = window.location.pathname.split("/")[(window.location.pathname.split("/").length) - 1];
        if (!(ruta === "direcciones" || ruta === "orders")) {
            ruta = ""
        }

        return (
            <>

                <Layout>
                    <Navbar />
                    <Content>
                        <Row className="pd-1">
                            <Layout className="user-layout">
                                <Sider theme="light" className="user-sider" >
                                    <Menu mode="inline" theme="light" defaultSelectedKeys={(ruta !== "") ? [ruta] : false} >

                                        <Menu.Item key="pedidos"  >
                                            <Link to={'/user/orders'}>My Orders</Link>
                                        </Menu.Item>

                                        <Menu.Item key="direcciones"  >
                                            <Link to={'/user/direcciones'}>My Addresses</Link>
                                        </Menu.Item>

                                    </Menu>
                                </Sider>


                                <Content className="user-cnt">
                                    <Switch>
                                        <Route path="/user/direcciones" render={(props) => <Direcciones  {...props} />} />
                                        <Route path="/user/orders" render={(props) => <Pedidos ref={this.ref}  {...props} />} />

                                    </Switch>
                                </Content>

                            </Layout>
                        </Row>
                    </Content>

                    <Row className="pd-1">
                        <WeLoveOurClients />
                    </Row>
                    <_Footer />
                    <Login
                        onClickRegistrarmeButton={() => this.setState({ modalLoginVisible: false, modalRegisterVisible: true })}
                        visible={this.state.modalLoginVisible}
                        onCancel={this.onModalClose}
                        onSuccess={this.onSuccess}
                    />
                    <Register
                        onClickRegistrarmeButton={() => this.setState({ modalLoginVisible: true, modalRegisterVisible: false })}
                        visible={this.state.modalRegisterVisible}
                        onCancel={this.onModalClose}
                        onSuccess={this.onSuccess}
                    />
                </Layout>
            </>
        )
    }
}


export default (props) => {
    const setUser = React.useContext(SetUser)
    const user = React.useContext(User)

    return <UserRouter {...props} setUser={setUser} user={user} />
}
