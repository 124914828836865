import React, {  Component } from 'react';
import { Col, Row,  Input,Button, Steps, Form, Typography,  Card, Radio, InputNumber, Empty, message, Space, Tooltip, Popconfirm } from 'antd';


import Busqueda from '../../Widgets/Busqueda';
import { User } from '../../../Hooks/Logged'
import '../../../Styles/Modules/ShoppingCart/direcciones.scss';

import axios from 'axios';
import { DeleteFilled, EditFilled } from '@ant-design/icons';


const { Step } = Steps;
const { Title, Paragraph } = Typography;

/**
 *
 *
 * @param {*} props
 * @return {*} 
 * @name Direcciones
 * @description Step de direcciones para el carrito de compras
 */


export default class Direcciones extends Component {
    static contextType = User;

    state = {
        direcciones: [],
        direccion_id: undefined
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.obtenerDirecciones()
    }


    obtenerDirecciones = () => {
        axios.get('/usuarios/direcciones')
            .then(data => {
                this.setState({ direcciones: [...data.data.data] })
            })
            .catch(response => {
                console.log(response)
            })
    }

    onAddressSubmit = (values) => {
        if(this.state.direccion_id){
            values._id = this.state.direccion_id
            this.updateAddress(values)
        }else{
            this.saveAddress(values)
        }
    }

    saveAddress = (values) => {
        axios.post('/usuarios/direccion/add', values)
            .then(({ data }) => {
                message.success("Address saved!")
                this.formRefNewDireccion.current?.resetFields()
                this.obtenerDirecciones()
            })
            .catch(({ response }) => {
                message.error("Address can't be saved")
                console.log(response)
            })
    }

    updateAddress = (values) => {
        axios.put('/usuarios/direccion/update', values)
            .then(({ data }) => {
                message.success("Address updated!")
                this.formRefNewDireccion.current?.resetFields()
                this.obtenerDirecciones()
            })
            .catch(({ response }) => {
                message.error("Address can't be updated")
                console.log(response)
            })
    }
    
    deleteAddress = (id) => {
        axios.delete('/usuarios/direccion/delete', {params: {id}})
            .then(({ data }) => {
                message.success("Address deleted!")
                this.obtenerDirecciones()
            })
            .catch(({ response }) => {
                message.error("Address can't be deleted")
                console.log(response)
            })
    }


    formRef = React.createRef()
    formRefNewDireccion = React.createRef()


    render() {

        let user = this.context;

        const { direcciones } = this.state

        user = !(user == 0 || user == null || user == undefined)


        return (
            <Col span={24} className="direcciones">
                {user && <Form
                    ref={this.formRef}
                    layout="vertical"
                    name="direcciones">
                    <Title level={5} className="direcciones-title pd-1">Saved Addresses</Title>
                    <Row className="direcciones-section">
                        <Col lg={24}>
                            {direcciones.length > 0 ? (
                                <Form.Item name="direccion" rules={[{ required: true, message: "You need to select an address to continue." }]}>
                                    <Radio.Group className="direcciones-radio-group">
                                        {direcciones.map(direccion => {
                                            const { _id, nombre, codigo_postal, localization, notas } = direccion
                                            return <Radio
                                                className="direcciones-radio"
                                                value={_id}
                                            >
                                                <Card>
                                                    <Row
                                                        className="width-100"
                                                        align="middle"
                                                    >
                                                        <Col flex="auto">
                                                            <Title level={5}>{nombre} <small>{codigo_postal}</small></Title>
                                                            <Paragraph>{notas}<br />{localization?.text_es}</Paragraph>
                                                        </Col>
                                                        <Col flex="none">
                                                            <Space size={10} direction="vertical">
                                                                <Tooltip title="Edit" placement="left">
                                                                    <Button
                                                                        type="text"
                                                                        icon={<EditFilled/>}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                direccion_id: direccion._id
                                                                            })
                                                                            this.formRefNewDireccion.current?.setFieldsValue({
                                                                                ...direccion,
                                                                                localization: {
                                                                                    label: direccion.localization?.place_name_en,
                                                                                    value: direccion.localization
                                                                                }
                                                                            })
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                                <Popconfirm
                                                                    title="Are you sure to delete this address?"
                                                                    onConfirm={() => this.deleteAddress(direccion._id)}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Tooltip title="Delete" placement="left">
                                                                        <Button
                                                                            type="text"
                                                                            icon={<DeleteFilled/>}
                                                                        />
                                                                    </Tooltip>
                                                                </Popconfirm>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Radio>
                                        })}
                                    </Radio.Group>
                                </Form.Item>
                            ) : (
                                <Empty
                                    description="There are not addresses"
                                />
                            )}
                        </Col>
                    </Row>
                </Form>}


                {user ? <Title level={5} className="pd-1">New Address</Title> : null}


                <Form
                    ref={this.formRefNewDireccion}
                    layout="vertical"
                    name="direcciones"
                    className="pd-1"
                    onFinish={this.onAddressSubmit}>
                    <Row gutter={[24, 24]}>
                        <Col lg={24}>
                            <Form.Item
                                label="Full Name"
                                name="nombre"
                            >
                                <Input size="large" />
                            </Form.Item>
                        </Col>

                        {(!user) ? [
                            <Col lg={12}>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    className="width-100"

                                    rules={[
                                        { required: true, message: "Enter your email, please" },
                                        { required: true, type: "email", message: "The email sent isn't valid!" }
                                    ]}
                                >
                                    <Input size="large" />
                                </Form.Item>
                            </Col>,
                            <Col lg={12}>
                                <Form.Item
                                    label="Phone Number"
                                    name="telefono"
                                >
                                    <InputNumber size="large" className="width-100" />
                                </Form.Item>
                            </Col>
                        ] : null}
                        <Col lg={4}>
                            <Form.Item
                                label="Postal Code"
                                name="codigo_postal"
                                className="width-100"
                            >
                                <InputNumber size="large" className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col lg={20}>
                            <Form.Item
                                label="Address"
                                name="localization"

                            >
                                <Busqueda />
                            </Form.Item>
                        </Col>
                        <Col lg={24}>
                            <Form.Item
                                label="Notes"
                                name="notas"
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Col>

                        {user ? <Col lg={24}>
                           <Space size={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                {this.state.direccion_id && <Button type="secondary" 
                                    onClick={() => {
                                        this.setState({direccion_id: undefined})
                                        this.formRefNewDireccion.current?.resetFields()
                                    }}
                                >
                                    New Address
                                </Button>}
                                <Form.Item style={{marginBottom: 0}}>
                                    <Button type="primary" htmlType="submit">
                                        {
                                            this.state.direccion_id ? "Update Address" : "Add Address"
                                        }
                                    </Button>
                                </Form.Item>
                           </Space>
                        </Col> : null}

                    </Row>
                </Form>
            </Col>
        )
    }


}