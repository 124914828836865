import { Link } from "react-router-dom"
import ReactEcharts from "echarts-for-react";
import { EllipsisOutlined, ArrowUpOutlined, ArrowDownOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Card, Tag, List, Checkbox, Typography, Row, Col, Image, Progress, Avatar, Button, Space, Rate} from 'antd';
import CustomAvatar from './Avatar/Avatar';
import { LightenDarkenColor } from 'lighten-darken-color';
import { IconChip, IconVisa, IconMaster, IconCheckMedal, IconProfit, IconLoss, IconToolOperation, IconMenuDots } from './Iconos';
import '../../Styles/Global/cards.css';
import axios from "axios";

const moment = require('moment');
const { Text, Title,Paragraph } = Typography;

/**
 * @const CardTaskStep
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
const CardTaskStep = ({ step, color = "#bababa" }) => {
    return <Card
        className="card-task-name"
        cover={<span className="card-task-color"
            style={{ backgroundColor: color }} />}
    >
        <Card.Meta
            className="card-task-name-meta"
            title="Quotation"
            description="First Step in the Supply Chain Process." />
    </Card>
}

/**
 * @const Navbar
 * @description Header del sistema
 * @param ObjectId id
 * @param Date fecha de la lista de task
 * @param String color del step
 * @param String description del task
 * @param Array Lista tasks
 * @param String Nombre del cliente
 * 
 */
const CardTaskClient = ({ id, date = new Date(), description, task, cliente = "Luisa Gutamart", color = "#bababa" }) => {

    return <Card title="#000001" extra={<Tag className="card-task-tag" href="#" color={color}>2 days</Tag>} bordered={false} className="card-task">
        <p className="card-task-description">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,</p>
        <List
            itemLayout="horizontal"
            dataSource={task}
            bordered={false}
            className="list-border-none card-task-list"
            renderItem={item => (
                <List.Item >
                    <List.Item.Meta
                        className="card-task-list-meta"
                        avatar={<Checkbox className="custom-checkbox-circle" />}
                        title={item.title}

                    />
                </List.Item>
            )}
        />
        <p>{moment(date).format('LL')}</p>
        <p> <CustomAvatar image="random.jpg" /> <span> {cliente}</span></p>

    </Card>

}

/**
 * @const CardSaldo
 * @description Card que muetra el total de Saldo diponible entre todas las cuentas
 * @param num saldo suma de los saldos disponibles
 * @param num cuentas cantidad de cuentas registradas
 */
const CardSaldo = ({ saldo = 0, cuentas = 0 }) => {
    return <Card className="card-saldo">
        <Card.Meta
            className="card-saldo-main-meta"
            title="Saldo al Momento"
            description={<><Text style={{ marginRight: '0.5rem' }}>$ {saldo.toMoney()}</Text><small>MXN</small></>} />
        <Card.Meta
            className="card-saldo-sub-meta"
            title="Cuentas Incluidas"
            description={<>{cuentas}</>} />
    </Card>
}

/**
 * @const CardCuenta
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
const CardCuenta = ({ cuenta = { saldo: 0 } }) => {

    let logos = {
        1: <IconMaster />,
        2: <IconVisa />,
        3: <img src={'/images/AE.png'} alt="AE" height="30px"/>
    }

    return <Card bordered={false} className="card-cuenta" style={{ background: `linear-gradient(244.64deg, ${LightenDarkenColor(cuenta.color, -20)} 10%, ${LightenDarkenColor(cuenta.color, 20)} 49.92%, ${LightenDarkenColor(cuenta.color, -10)}  85.62%)` }}>
        <Row>
            <Col span={4} className="center">
                <CustomAvatar name={[cuenta.nombre, cuenta.apellido]} image={cuenta.logo} color={cuenta.color} />
            </Col>
            <Col span={16} className="flex-left ">
                <Text className="cuenta-nombre">{cuenta.nombre} <IconCheckMedal /></Text>
            </Col>
            <Col span={4} className="center">
                <Image preview={false} src={"/images/Chip.png"} />
            </Col>
        </Row>
        <Card.Meta
            className="card-saldo-sub-meta"
            title={<><Text style={{ marginRight: '0.5rem' }} className="text-white">$ {cuenta.saldo?.toMoney()}</Text><small>MXN</small></>}
            description={<><Text style={{ marginRight: '0.5rem' }} className="text-white">$ {cuenta.saldo?.toMoney()}</Text><small>MXN</small></>} />
        <Row>
            <Col span={8} className="flex-left">
                <small className="text-white">ACCOUNT NUMBER</small>
            </Col>
            <Col span={12} className="flex-left">
                <Text strong style={{ paddingLeft: '7px' }} className="text-white">{cuenta.cuenta}</Text>
            </Col>
            <Col span={4} className="center">
                {logos[cuenta.marca]}
            </Col>
        </Row>
    </Card>
}


/**
 * @const CardProfit
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
const CardGains = ({ title = "Profit", status = 'success' }) => {


    return <Card className="card-gains card-border-16">
        <Row>
            <Col span={3}>
                <div className={`${title === 'Profit' ? 'green-box' : 'red-box'}`}>
                    {title === 'Profit' ? <IconProfit /> : <IconLoss />}
                </div>
            </Col>
            <Col span={17} className="flex-left pl-1">
                <Text className="card-title">{title}</Text>
            </Col>
            <Col span={4} className="flex-right dots">
                <Button ghost icon={<IconMenuDots/>}></Button>
            </Col>
            <Col span={24} className="center">
                <Text>{moment().format('DD/MM/YYYY')}</Text>
            </Col>
            <Col span={12} className="flex-left">
                <Text className="text-paid">Monto Pagado</Text>
            </Col>
            <Col span={12} className="flex-right">
                <Text className="text-paid">Total</Text>
            </Col>
            <Col span={24} className="center mb-2">
                <Progress percent={90} status={status} showInfo={false} />
            </Col>
            <Col span={24} className="center">
                <Text>{moment().format('DD/MM/YYYY')}</Text>
            </Col>
            <Col span={12} className="flex-left">
                <Text className="text-paid">Monto Pagado</Text>
            </Col>
            <Col span={12} className="flex-right">
                <Text className="text-paid">Total</Text>
            </Col>
            <Col span={24} className="center">
                <Progress percent={100} status={status} showInfo={false} />
            </Col>

        </Row>

    </Card>
}


























/**
 * @const CardSaldosGraph
 * @description Card de saldos de las cuentas
 * @param Array data Lista de cuentas con saldos
 * @param String title Nombre de la grafica
 */
const CardSaldosGraph = ({ title = "Saldos", data = [], }) => {

    let dataSaldos = [
        { value: 1000, name: 'cuenta 1' },
        { value: 0, name: 'cuenta 2' },
        { value: 20, name: 'cuenta 3' },
        { value: 100, name: 'cuenta 4' }
    ];
    let series = []
    dataSaldos.map(item => {
        series.push({
            name: item.name,
            type: 'bar',
            stack: '1',
            label: {
                show: false,
            },
            barWidth: 15,
            data: [item.value],
        });
    });


    return <Card className=" card-saldos-dashboard" bordered={false} extra={<EllipsisOutlined style={{ fontSize: 25 }} />} title={title}>
        <ReactEcharts
            style={{ height: '260px' }}
            theme="light"
            className="pd-1 width-100"
            option={{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    }
                },
                title: {
                    subtext: (new Intl.NumberFormat().format(4000000)),
                    subtextStyle: {
                        align: 'center',
                        fontSize: 30,
                        color: '#000',
                        fontWeight: 'bold'
                    }
                },
                legend: {
                    data: dataSaldos,
                    left: 'left',
                    bottom: 0,
                    itemWidth: 10,
                    itemHeight: 10,
                    orient: 'vertical',
                    textStyle: {
                        align: 'right',
                        fontSize: 14,
                        fontWeight: "normal",
                        lineHeight: 21,
                        color: '#858997',

                    },
                    lineStyle: {
                        cap: 'round'
                    },
                    formatter: function (name) {
                        let info = ""
                        for (let index = 0; index < dataSaldos.length; index++) {
                            const element = dataSaldos[index]
                            if (element.name == name) {
                                const val = new Intl.NumberFormat().format(element.value);
                                info = name + ' $ ' + val
                                break
                            }
                        }
                        return info
                    }
                },
                grid: {
                    left: '2%',
                    right: '2%',
                    bottom: '0px',
                    top: '80px',
                    height: '20px',
                    innerWidth: '100px',
                    containLabel: false,

                },
                xAxis: {
                    type: 'value',
                    show: false,
                    splitLine: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'category',
                    show: false,
                    splitLine: {
                        show: false
                    },
                },
                series: series

            }}
        />

    </Card>

}

/**
 * @const CardOperations
 * @description Card de saldos de las cuentas
 * @param String title Nombre del card
 * @param operation Object con la informacion del card
 * @param onClick Funcion a ejecutar al hacer click
 * @param color Color del ProgressBar y el Icono,
 * @param String icon Si el icono es flecha arriba o flecha abajo
 */
const CardOperations = ({ title = "", operation = {}, onClick = () => { }, color = "#006BFF", icon = "down" }) => {
    return <Card bordered={false} className="card-operations-dashboard pl-1 pr-1" title={title}>
        <Card.Meta
            title={<> $32,134 <span className={icon == "up" ? "operations-up" : "operations-down"}>{icon == "up" ? <ArrowUpOutlined /> : <ArrowDownOutlined />} 2.5 %</span></>}
            description="Compared to $21,340 last month"
        />
        <Card extra={<EllipsisOutlined style={{ fontSize: 25, color: '#425466' }} />} size="small" className="card-operations-dashboard-inner pd-1">
            <Row className="width-100">
                <Col span={24}>
                    <div className="box-icon-rounded" style={{ backgroundColor: 'rgba(${color},0.3)' }}>
                        <IconToolOperation color={color} style={{ display: 'block', margin: 'auto' }} />
                    </div>
                </Col>
                <Col span={24}>
                    <Row className="width-100">
                        <Col span={24}><p className="card-operations-dashboard-inner-title  ">Operations Costs</p></Col>
                        <Col span={24}>
                            <Row align="middle" justify="space-between" className="width-100">
                                <Col span={20}><p className="card-operations-dashboard-progress-text">Amounts</p></Col>
                                <Col span={4}><p className="card-operations-dashboard-progress-text">Total</p></Col>
                            </Row>
                            <Progress percent={30} showInfo={false} strokeColor={color} />
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Card>
    </Card>
}

/**
 * @const CardOperationsList
 * @description Card de saldos de las cuentas
 * @param String title Nombre del card
 * @param operation Object con la informacion del card
 * @param onClick Funcion a ejecutar al hacer click
 * @param color Color del ProgressBar y el Icono,
 * @param String icon Si el icono es flecha arriba o flecha abajo
 */
const CardOperationsList = ({ title = "", operation = {}, onClick = () => { }, color = "#006BFF", icon = "down" }) => {
    let data = [
        { color: '#4807EA', nombre: 'Rubro', monto: 0 },
        { color: '#FF9C54', nombre: 'Rubro', monto: 0 },
        { color: '#00FF47', nombre: 'Rubro', monto: 0 },
        { color: '#00FF47', nombre: 'Rubro', monto: 0 },
        { color: '#00FF47', nombre: 'Rubro', monto: 0 }
    ];
    return <Card bordered={false} className="card-operations-dashboard pl-1 pr-1" title={title}>
        <Card.Meta
            title={<> $32,134 <span className={icon == "up" ? "operations-up" : "operations-down"}>{icon == "up" ? <ArrowUpOutlined /> : <ArrowDownOutlined />} 2.5 %</span></>}
            description="Compared to $21,340 last month"
        />
        <List
            size="small"
            className="list-border-none list-top-dashboard"
            header={<div className="top-list-header ">Top 5 Headings</div>}
            bordered={false}
            dataSource={data}
            renderItem={item => <List.Item>
                <List.Item.Meta
                    avatar={<Avatar shape="square" size="small" style={{ backgroundColor: item.color }} />}
                    title={item.nombre}
                />

                <div className="list-top-dashboard-monto">${item.monto}</div>
            </List.Item>}
        />
    </Card>
}


const CardProducto = ({ producto = {}, cuentas = 0 }) => {
    return <Link to={`/store/${producto._id}`}>      

            <Card
                className="card-store"
                hoverable
            >
                <Row wrap={false} gutter={[24, 24]}>
                    <Col flex="none">
                        <Image
                            width={240}
                            height={295}
                            {...(producto.imagenes?.length > 0 && {
                                src: `${axios.defaults.baseURL}/upload/${producto.imagenes[0]}`
                            })}
                            placeholder={true}
                        />
                    </Col>
                    <Col flex="auto">
                        <div class="card-store-content  h-100">
                            <div class="card-store-metadata">
                                <Text strong>{producto.categoria_id?.nombre}</Text>
                                <Space direction="horizontal" size={4} wrap>
                                <Title level={5}>{producto.nombre}</Title> <Text italic>({producto.variantes.length || 0} Variantes)</Text>
                                </Space>
                                {
                                    Array.isArray(producto.variantes) && producto.variantes?.length > 0 && (
                                        <Text>${producto.variantes[0]?.precio?.toMoney && producto.variantes[0]?.precio?.toMoney(true)}</Text>
                                    )
                                }
                                {/* <Rate allowHalf value={4.5} disabled/> */}
                                <Paragraph 
                                    ellipsis={{
                                        rows: 2
                                    }}
                                >
                                { producto.descripcion }     
                                </Paragraph>
                            </div>
                            <Space size={16}>
                                <Button  
                                    icon={<ShoppingCartOutlined />}
                                    size="large"
                                    className="card-store-button"
                                >
                                    Add to cart
                                </Button>
                            </Space>    
                        </div>
                    </Col>
                </Row>
            </Card>
    </Link>
}



export {
    CardTaskClient,
    CardTaskStep,
    CardSaldosGraph,
    CardOperations,
    CardOperationsList,
    CardSaldo,
    CardCuenta,
    CardGains,
    CardProducto
}



