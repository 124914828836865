import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, PageHeader, Button } from 'antd';
import { Link } from "react-router-dom"
import { ExclamationCircleOutlined, FormOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios'

//modales
import ModalCategorias from './ModalCategorias'

const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;



class Categorias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombreVista: '',
      user: 'Administrador',
      dataCategorias: [],
      itemCount: undefined,
      perPage: 10,
      pageCount: 1,
      currentPage: 1,
      slNo: 1,
      hasPrevPage: false,
      hasNextPage: false,
      prev: null,
      next: null,
      categoria_id: undefined
    }
  }

    componentDidMount() {
        this.props.setTitle('Categorias')
        this.receivedData();
    }

    /**
    * @memberof Categorias
    *
    * @method   receivedData
    * @description  Metodo que realiza el paginado de las categorias
    * @param values  Number con la pagina a buscar, por defecto cadena vacia para busquedas
    *
    **/
    receivedData = (values) => {
        axios.get('/categorias', {
            params: {
                page: values,
                limit: 10
            }
        }).then(res => {
            if (res.data != undefined) {
                var data = res.data.data.itemsList;
                var data_categorias = [];
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    data_categorias.push({
                        _id:          element._id,
                        nombre:       element.nombre,
                        descripcion:  element.descripcion,
                        createdAt:    element.createdAt,
                        updatedAt:    element.updatedAt,
                        index: (index + 1)
                    });
                }
                this.setState({
                    dataCategorias: data_categorias,
                    currentPage:    res.data.data.paginator.currentPage,
                    itemCount:      res.data.data.paginator.itemCount,
                    perPage:        res.data.data.paginator.perPage,
                    pageCount:      res.data.data.paginator.pageCount,
                    currentPage:    res.data.data.paginator.currentPage,
                    slNo:           res.data.data.paginator.slNo,
                    hasPrevPage:    res.data.data.paginator.hasNextPage,
                    hasNextPage:    res.data.data.paginator.hasNextPage,
                    prev:           res.data.data.paginator.prev,
                    next:           res.data.data.paginator.next
                });
            }
      })
  }
  /**
* @memberof Categorias
*
* @method   searchData
* @description  Metodo que realiza la busqueda de productos
* @param values String   con la info a buscar, por defecto cadena vacia para busquedas
*
**/
  searchData = (values) => {
    axios.get('/categorias', {
      headers: { Authorization: sessionStorage.getItem("token") },
      params: {
        search: values
      }
    })
      .then(res => {
        console.log("paginando ando", res);
        var data = res.data.data.itemsList;
        var data_categorias = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          data_categorias.push({
            _id:         element._id,
            nombre:      element.nombre,
            descripcion: element.descripcion,
            createdAt:   element.createdAt,
            updatedAt:   element.updatedAt,
            index: (index + 1)
          });
        }
        this.setState({
            dataCategorias: data_categorias,
            currentPage:    res.data.data.paginator.currentPage,
            itemCount:      res.data.data.paginator.itemCount,
            perPage:        res.data.data.paginator.perPage,
            pageCount:      res.data.data.paginator.pageCount,
            currentPage:    res.data.data.paginator.currentPage,

        });

        console.log("state paginator", this.state);
      })
      .catch(res => {
        console.log("error", res);
      });

  }

/**
 * @memberof Categorias
 *
 * @method   showDeleteConfirm
 * @description  comentar codigo por favor
 *
 **/
showDeleteConfirm = (item) => {
  const cat_nombre = item.nombre;
  const cat_id = item._id;

  confirm({
    title: 'Eliminar Categoria',
    icon: <ExclamationCircleOutlined />,
    content: '¿Estas seguro que deseas eliminar la categoria ' + cat_nombre + ' ?',
    okText: 'Continuar',
    okType: 'danger',
    cancelText: 'Cancelar',

    onOk: ()=> {

      axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');

      axios({
        method: 'delete',
        url: '/categorias/delete',
        headers: { Authorization: sessionStorage.getItem('token') },
        data: { id: cat_id }
      })

        .then((response) => {
          this.receivedData()
        })

        .catch((error) => {
            console.log(error)
            message.error('Error al eliminar')
        })
    },
  });
}

  /**
  * @memberof Usuarios
  *
  * @method   handlePageClick
  * @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
  *
  **/
  handlePageClick = page => {
    this.receivedData(page);
  };

    render() {

        const columns = [
            {
                title: '#',
                dataIndex: 'index',
                key: 'index',
            },
            {
                title: 'Nombre',
                dataIndex: 'nombre',
                key: 'nombre',
            },
            {
                title: 'Descripción',
                dataIndex: 'descripcion',
                key: 'descripcion',
            },
            {
                title: 'Acciones',
                key: 'actions',
                render: (text, record) => (
                    <>
                        <Button
                            className="mr-1"
                            title="Editar" 
                            type="secondary" 
                            onClick={()=>this.setState({modalCategrias: true, categoria_id: record._id})}
                            icon={<FormOutlined/>}> 
                        </Button>
                        <Button 
                            title="Eliminar" 
                            type="danger" 
                            onClick={()=>this.showDeleteConfirm(record)} 
                            icon={<DeleteOutlined/>}>
                        </Button>
                    </>
                ),
            },
        ];

        return (
            <Layout>
                <Content className="admin-content content-bg pd-1">
                    <Row className="mb-1">
                        <Col xs={24} md={12}>
                            <Search
                                className="search-bar"
                                placeholder="Buscar..."
                                enterButton="Buscar"
                                onSearch={this.searchData}
                            />
                        </Col>
                        <Col xs={24} md={12} className="flex-right">
                            <Button type="primary" icon={<PlusOutlined/>} onClick={()=>this.setState({modalCategrias: true})}></Button>
                        </Col>
                    </Row>

                    <Table
                        columns={columns}
                        dataSource={this.state.dataCategorias}
                        pagination={{
                            onChange: this.handlePageClick,
                            total: this.state.itemCount,
                            pageSize:this.state.perPage,
                            current: this.state.currentPage
                        }} 
                       
                    />
                </Content>
                <ModalCategorias
                    visible={this.state.modalCategrias}
                    onCancel={()=>{
                        this.setState({modalCategrias: false, categoria_id: undefined})
                        this.receivedData()
                    }}
                    categoria_id={this.state.categoria_id}
                />
            </Layout>
        )
    }
}

export default Categorias;