import React from 'react'
import { Route, Switch } from "react-router-dom";

import RouterPublic from "./Routers/RouterPublic";
import AdminRoutes from "./Routers/AdminRoutes";
import UserRoutes from "./Routers/UserRoutes";


/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function Routes() {
    return (
        <Switch>
            <Route path="/admin" component={AdminRoutes} />

            <Route path="/user" component={UserRoutes} />

            {/* <Route path="/shoppingcart" component={ShoppingCartRouter} /> */}

            <Route path="/" component={RouterPublic} />
            
        </Switch>
    )
}

export default Routes;
