

import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, List } from 'antd';

import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import PropTypes from "prop-types";
import Logged from '../../../Hooks/Logged';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import axios from 'axios'

const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;


/**
 *
 *
 * @class FormSize
 * @extends {React.Component}
 * @description Formulario de usuarios
 */
class FormSize extends Component {


    formModalTamaño = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingImage: false,
            form: {},

            id: null,
            image: null,
            negocios: []

        }
    }


    /**:4
     * @methodOf FormSize
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        console.log(this.props)
        if(this.props.size_id){
            this.getData()
        }
    }

   /**
   * @memberof FormSize
   *
   * @method onFinish
   * @description  Se ejecuta al dar finalizar al formulario
   *
   **/
    onFinish = values => {

        if(this.props.size_id !== undefined){
            this.updateSize(values)
        }else{
            this.addSize(values);
        }
        
    }


     /**
    * @memberof FormSize
    *
    * @method getData
    * @description  trae la informacion de un tamaño
    *
    **/
    getData = (values) => {
        this.setState({loading: true})
        axios.get('/sizes/get', {
            params: { id:  this.props.size_id} 
        }).then(response => {
            this.formModalTamaño.current?.setFieldsValue({
                size: response.data.data.size.size,
            })
        }).catch(error => {
            message.error('Error al obtener la informacion')
            console.log(error)
        }).finally(()=>this.setState({loading: false}))
    }


    /**
    * @memberof FormSize
    *
    * @method updateSize
    * @description  Actualiza la informacion de un tamaño
    *
    **/
    updateSize = (values) => {
        console.log('update')
        axios.put('/sizes/update', {
            id: this.props.size_id,
            size: values.size,
        })
        .then((response) => {
            message.success("Tamaño Actualizado");
            this.props.onCancel()
        })
        .catch((error) => {
            console.log(error)
            Modal.warning({
                title: 'Error',
                content:
                    <List
                        size="small"
                        bordered
                        dataSource="el Tamaño no se ha podido editar"
                    />
            });
        })
    }

    /**
    * @memberof FormSize
    *
    * @method addSize
    * @description  Añade un tamaño
    **/
    addSize = (values) => {
        axios.post('/sizes/add', {
            size: values.size,
        })
        .then((response) => {
            message.success("Tamaño creado");
            this.props.onCancel()
        })
        .catch((error) => {
            console.log(error);
            message.success("Error al crear el tamaño");
        })
    }






    render() {

        const { loading } = this.state

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">Tamaños</Title>
                <Form 
                    layout="vertical" 
                    ref={this.formModalTamaño} 
                    onFinish={this.onFinish} 
                >

                    <Row>
                        <Col xs={24}>
                            <Form.Item 
                                label="Tamaño" 
                                name="size" 
                                rules={[{ required: true, message: "Por favor, ingrese el tamaño" }]} 
                            >
                                <Input placeholder="Tamaño"></Input>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="center" align="middle" >
                        <Col span={24} className="flex-column">
                            <Form.Item>
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {

    let { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormSize {...props} />
        </Modal>
    )
}