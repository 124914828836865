import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal , message, PageHeader, Button} from 'antd';
import { Link, Redirect } from "react-router-dom";
import { ExclamationCircleOutlined,FormOutlined,DeleteOutlined,PlusOutlined} from '@ant-design/icons';
import axios from 'axios'

//modal
import ModalTamaños from './ModalTamaños'

const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;




class Tamaños extends Component {
  constructor(props) {
	super(props);
	this.state = {
	  nombreVista: '',
	  user: 'Administrador',
	  isAdmin: true,
	  dataTamanos: [],
	  itemCount: undefined,
	  perPage: 10,
	  pageCount: 1,
	  currentPage: 1,
	  slNo: 1,
	  hasPrevPage: false,
	  hasNextPage: false,
	  prev: null,
	  next: null,
	}
  }
  /**
   * @memberof Tamaños
   *
   * @method componentDidMount
   * @description comentar metodo por favor
   *
   **/

 	componentDidMount() {
 		this.props.setTitle('Tamaños')
 		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.receivedData();
 	}


	/**
	* @memberof Categorias
	*
	* @method   receivedData
	* @description  Metodo que realiza el paginado de las categorias
	* @param values String  o Number con la info a buscar, por defecto cadena vacia para busquedas
	*
	**/
	receivedData = () => {
		axios.get('/sizes',{
			params: {
				page: this.state.currentPage,
				limit: 10,
				paginate: true,
				search: this.state.search,
			}
	  	})
		.then(res => {
			if (res.data != undefined) {
				var data = res.data.data.itemsList;
				var data_tamanos = [];
				for (let index = 0; index < data.length; index++) {
					const element = data[index];
					data_tamanos.push({
				  		_id:         element._id,
				  		size:      element.size,
				  		createdAt:   element.createdAt,
				  		updatedAt:   element.updatedAt,
				  		index: (index + 1)
					});
				}

			  	this.setState({ 
					dataTamanos:  data_tamanos,
					currentPage:  res.data.data.paginator.currentPage,
					itemCount:    res.data.data.paginator.itemCount,
			  	});
			}
	  	}).catch(error=>{
	  		message.error('Error al traer la informacion')
	  	})
  }




 	/**
  	* @memberof Tamaños
   	*
   	* @method   handlePageClick
   	* @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
   	*
   	**/
  	handlePageClick = page => {
		this.setState({currentPage: page},()=>{
	  		this.receivedData();
		})
  	};

  	/**
  	* @memberof Tamaños
   	*
   	* @method   handlePageClick
   	* @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
   	*
   	**/
  	handleSearch = search => {
		this.setState({search: search},()=>{
	  		this.receivedData();
		})
  	};

  	/**
   	* @memberof Tamaños
   	*
   	* @method   showDeleteConfirm
   	* @description  describir funcion por favor
   	*
   	**/
   	showDeleteConfirm = (item) => {
  		const tm_nombre = item.size;
  		const tm_id = item._id;

		confirm({
			title: 'Eliminar tamaño',
			icon: <ExclamationCircleOutlined />,
			content: '¿Estas seguro de eliminar el tamaño ' + tm_nombre + ' ?',
			okText: 'Continuar',
			okType: 'danger',
			cancelText: 'Cancelar',
			onOk: () => {
				axios({
					method: 'delete',
					url: '/sizes/delete',
					data: { id: tm_id }
		  		})
		  		.then((response) => {
					message.success('Tamaño eliminado');
			 		this.receivedData()
				}).catch((error) => {
					message.error('Error al eliminar');
				})
			}
  		})
	}

	render() {

		const columns = [
			{
				title: '#',
				dataIndex: 'index',
				key: "index"
			},{
				title: 'Tamaños',
				dataIndex: 'size',
				key: 'nombre',
		  	},{
				title: 'Acciones',
				key: 'actions',
				dataIndex: '_id',
				render: (text, record) => (
			  		<>
						<Button
							className="mr-1"
							title="Editar" 
							type="secondary" 
							icon={<FormOutlined />}
							onClick={()=>{
								console.log(record)
								this.setState({modalTamaños: true, size_id: record._id})
							}}
						>
						</Button>
						<Button 
							title="Eliminar"
							type="danger"
							onClick={()=>this.showDeleteConfirm(record)}
							icon={<DeleteOutlined /> }>
						</Button>
			  		</>
				),
		 	},
		];




		return (
			<Layout>
				<Content className="admin-content content-bg pd-1">
					<Row className="mb-1">
						<Col xs={24} md={12}>
							<Search
								className="search-bar"
								placeholder="Buscar..."
								enterButton="Buscar"
								onSearch={this.handleSearch}
							/>
						</Col>
						<Col xs={24} md={12} className="flex-right">
							<Button type="primary" icon={<PlusOutlined/>} onClick={()=>this.setState({modalTamaños: true})}></Button>
						</Col>
					</Row>

					<Table 
						columns={columns} 
						dataSource={this.state.dataTamanos} 
						pagination={{
							onChange: this.handlePageClick,
                            total: this.state.itemCount,
                            pageSize:this.state.perPage,
                            current: this.state.currentPage
						}}
					/>
				</Content>

				<ModalTamaños
					visible={this.state.modalTamaños}
					size_id={this.state.size_id}
					onCancel={()=>{
						this.setState({modalTamaños: false, size_id: undefined})
						this.receivedData()
					}}
				/>
			</Layout>
		)
	}
}



export default Tamaños;