import React from 'react'
import { Route, Switch } from "react-router-dom";
import Productos from '../components/Admin/Productos/Productos';
import CrearProducto from '../components/Admin/Productos/CrearProducto';
import EditarProducto from '../components/Admin/Productos/EditarProducto';


/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterProductos( props) {
    return (
        <Switch>
            <Route exact path="/admin/productos" render={() => <Productos {...props} />} />
        </Switch>
    )
}

export default RouterProductos;
