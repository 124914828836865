import React, { Component } from "react";
import { Form, Layout, Input, Button, Col,Row, message } from 'antd';
import { Redirect } from "react-router-dom";
import axios from 'axios'

const { Content,Header } = Layout;
const { TextArea } = Input;
class CrearTamaño extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nombreVista: '',
            tamano_id:'',
            data_tamaño: {
                id: '',
                size: "",
            }
        }
    }
    
    formRef = React.createRef();

    componentDidMount() {
        this.setState({
            nombreVista: 'Editar tamaño',
            tamano_id:'',
            data_tamaño:
            {
                id: '',
                size: "",
            }
        });
        const  tamano_id  = this.props.match.params.id;
        axios.get(
            '/sizes/get',
            {params:{id:  tamano_id} },
            {headers:{ Authorization : sessionStorage.getItem('token') }}
        )

        .then(response => {
                if(response.data.data != undefined )
                {
                    console.log(response.data.data);
                    //this.state.dataTamanos.push( response.data.data) ;
                    if(response.data.data != undefined )
                    {
                        this.setState({ tamano_id: tamano_id });
                        this.setState({ data_tamaño:response.data.data.size });
                        {this.onFill()}
                    }
                }
        });
    }

   /**
   * @memberof EditarTamaño
   *
   * @method handleSubmit
   * @description  Envia los datos del formulario al Servidor
   *
   * @param values (array)
   * Contiene los campos del formulario
   *
   * @returns response (array)
   **/
    handleSubmit = values => {
      console.log('editar');

        axios.put('/sizes/update', {
            id: this.state.tamano_id,
            size: values.size,
        },
        {headers:{ Authorization : sessionStorage.getItem('token') }  }
        )
        .then((response) => {
                console.log(response);
                if (response.status == 200) {
                    message.success("Tamaño Actualizado");
                    this.setState({ redirect: true });
                }
        })
        .catch((error) => {
            console.log(error.response);
        })
    }

      /**
     * @memberof Edit
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
     **/
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to="/admin/tamanos" />;
        }
    };

    onFill = () => {
      this.formRef.current.setFieldsValue({
        size: this.state.data_tamaño.size,
      });
    };
  
    render() {
        return (
            <Layout>
                {this.renderRedirect()}
                <Header className="admin-header" style={{ height: 'auto' }}  >
                    <Row>
                        <Col span={10}>
                            <h3 id="nombreVista" className="nombreVista encabezado" >{this.state.nombreVista}</h3>
                        </Col>
                    </Row>
                </Header>
                <Content className="box-shadow">
                   
                    <div>
                        <div className="div-contenedor" style={{ alignContent: 'center', alignItems: 'center' }} >
                            <Col span={12} xs={24} lg={{ span: 12, offset: 6 }} >
                            <Form  ref={this.formRef} name="control-ref" initialValues={{ remember: true }} onFinish={this.handleSubmit} layout={"vertical"} className="form-EditarTamaño center" >
                                    <Form.Item
                                        name="size"
                                        label="Tamaño"
                                        rules={[{ required: true, message: 'Por favor ingresa tamaño' }]}>
                                        <Input
                                            placeholder="Tamaño"
                                            
                                            defaultValue = {this.state.data_tamaño.size}
                                        />

                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            
                                        >
                                            Guardar
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </div>
                    </div>
                </Content>
            </Layout>)
    }
}
 
export default CrearTamaño;
