import React, { Component } from "react";
import { Table, Layout, Input, Row, Col,Switch, PageHeader, Button,Typography, message, Modal } from 'antd';
import { Link } from 'react-router-dom';
import {FormOutlined, PlusOutlined, DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import axios from 'axios'

const { Header, Content } = Layout;
const { Text } = Typography
const { Search } = Input;
 
const moment = require('moment')


class Variantes extends Component {

 	constructor(props) {
		super(props);
		this.state = {
	  		suscriptores: [],
	  		total: 0,
	  		page: 1,
		}
  	}

 	/**
 	* @memberof Variantes
	*
  	* @method componentDidMount
 	* @description  Al montar el componente trae los datos de los usuarios y los asigna al state.users
  	*
  	**/
  	componentDidMount() {
  		this.props.setTitle('Suscriptores')
		axios.defaults.headers.common["Authorization"] = sessionStorage.getItem('token');
		this.receivedData();	
 	}


	/**
	* @memberof Variantes
	*
	* @method   receivedData
	* @description  Metodo que realiza el paginado de los variantes
	* @param values Number con la pagina a buscar
	*
	**/
	receivedData(page = 1){
		axios.get('/suscriptores',{
			params:{
				page: page,
				search: this.state.search,
				limit: 10,
			}
		}).then(success => {
			console.log(success.data.data)
			this.setState({
				suscriptores: success.data.data.itemsList,
				page: success.data.data.paginator.currentPage,
				total: success.data.data.paginator.itemCount,
			})
		}).catch(error => {
			console.log(error)
		})
  	}


  	/**
   	* @memberof Tamaños
   	*
   	* @method   showDeleteConfirm
   	* @description  describir funcion por favor
   	*
   	**/
   	showDeleteConfirm = (item) => {
  		const tm_nombre = item.email;
  		const tm_id = item._id;

		Modal.confirm({
			title: 'Eliminar suscriptor',
			icon: <ExclamationCircleOutlined />,
			content: '¿Estas seguro de eliminar el suscriptor ' + tm_nombre + ' ?',
			okText: 'Continuar',
			okType: 'danger',
			cancelText: 'Cancelar',
			onOk: () => {
				axios({
					method: 'delete',
					url: '/suscriptores/delete',
					data: { id: tm_id }
		  		})
		  		.then((response) => {
					message.success('Suscriptor eliminado');
			 		this.receivedData()
				}).catch((error) => {
					console.log(error)
					message.error('Error al eliminar');
				})
			}
  		})
	}


	render() {


		const columns = [
			{
				title: 'Correo',
				dataIndex: 'email',
				key: 'email',
			},{
				title: 'Fecha de suscripción',
				dataIndex: 'createdAt',
				key: 'createdAt',
				render: (text) => {
					return <Text>{moment(text).format('DD/MM/YYYY')}</Text>
				}
			},{
				title: 'Acciones',
				key: 'actions',
				render: (text, record) => (
				 	<>
						<Button 
							title="Eliminar"
							type="danger"
							onClick={()=>this.showDeleteConfirm(record)}
							icon={<DeleteOutlined /> }>
						</Button>
					</>
				),
			},
		];



		return (
			<Layout>
				<Content className="admin-content content-bg pd-1">
					<Row className="mb-1">
                        <Col xs={24} md={12}>
                            <Search
                            	className="search-bar"
                                placeholder="Buscar..."
                                enterButton="Buscar"
                                onSearch={(search => this.setState({search},()=>this.receivedData()))}
                            />
                        </Col>
                        {/*<Col xs={24} md={12} className="flex-right">
                            <Button type="primary" icon={<PlusOutlined/>} onClick={()=>this.setState({modalVariantes: true})}></Button>
                        </Col>*/}
                    </Row>
                    <Table 
						columns={columns} 
						dataSource={this.state.suscriptores}  
						pagination={{
							onChange: (page) => this.receivedData(page) ,
                            total: this.state.total,
                            current: this.state.page
			  			}}
			 		/>
				</Content>	  
	 		</Layout>
		)
 	}
}

export default Variantes;