

import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, List, Switch } from 'antd';

import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import PropTypes from "prop-types";
import Logged from '../../../Hooks/Logged';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import { act } from "react-dom/test-utils";
import axios from 'axios'

const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;


/**
 *
 *
 * @class FormUsuario
 * @extends {React.Component}
 * @description Formulario de usuarios
 */
class FormUsuario extends Component {


    formModalUsuario = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingImage: false,
            form: {},

            id: null,
            image: null,
            usuario: null,

            admin: false,
            activo: false,

        }
    }


    /**:4
     * @methodOf FormUsuario
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        console.log(this.props)
        if (this.props.usuario_id) {
            this.getData()
        }
    }


    /**
    * @memberof FormUsuario
    *
    * @method onFinish
    * @description  Se ejecuta al dar finalizar al formulario
    *
    **/
    onFinish = values => {

        console.log(values);

        if (this.props.usuario_id !== undefined) {
            this.updateUsuario(values)
        } else {
            this.addUsuario(values);
        }

    }


    /**
   * @memberof FormUsuario
   *
   * @method getData
   * @description  trae la informacion de un usuario
   *
   **/
    getData = (values) => {


        this.setState({ loading: true })
        axios.get('/usuarios/get', {
            params: { id: this.props.usuario_id }
        }).then(response => {

            if (response.data.success) {
                var info = response.data.data;
                this.state.usuario = info;
                var admin = info.tipo === 1;

                console.log(info)

                this.formModalUsuario.current.setFieldsValue({
                    nombre: info.nombre,
                    apellido: info.apellido,
                    email: info.email,
                    telefono: info.telefono,

                    activo: info.activo,
                    admin: admin,

                });



            }
            else {
                message.error('Error al obtener la informacion')

            }
        }).catch(error => {
            message.error('Error al obtener la informacion')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @memberof FormUsuario
    *
    * @method updateUsuario
    * @description  Actualiza la informacion de un usuario
    *
    **/
    updateUsuario = (values) => {
        console.log('update')
        console.log(values)


        axios.put('/usuarios/update', {
            id: this.props.usuario_id,
            tipo: (values.admin) ? 1 : 2,
            ...values
        })
            .then((response) => {

                if (response.data.success) {
                    message.success("Usuario Actualizado");

                    setTimeout(() => {
                        this.props.onCancel()
                    }, 250);
                }
                else {
                    message.error(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error)
                Modal.warning({
                    title: 'Error',
                    content:
                        <List
                            size="small"
                            bordered
                            dataSource="El usuario no se ha podido editar"
                        />
                });
            })
    }

    /**
    * @memberof FormUsuario
    *
    * @method addUsuario
    * @description  Añade un usuario
    **/
    addUsuario = (values) => {
        console.log(values);
        axios.post('/usuarios/add', {
            tipo: (values.admin) ? 1 : 2,
            ...values
        })
            .then((response) => {
                if (response.data.success) {
                    message.success("Usuario creado");
                    this.props.onCancel()
                }
                else {
                    message.error(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
                message.error("Error al crear el usuario");
            })
    }


    render() {

        const { loading, usuario, activo, admin } = this.state;



        return (

            <Spin spinning={loading}>
                <Title level={3} className="text-center">Usuarios</Title>
                <Form
                    layout="vertical"
                    ref={this.formModalUsuario}
                    onFinish={this.onFinish}
                >

                    <Row>
                        <Col xs={24}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{ required: true, message: "Por favor, ingrese nombre" }]}
                            >
                                <Input placeholder="Nombre(s)" />
                            </Form.Item>
                        </Col>


                        <Col xs={24}>
                            <Form.Item
                                label="Apellido"
                                name="apellido"
                                rules={[{ required: true, message: "Por favor, ingrese apellido" }]}
                            >
                                <Input placeholder="Apellido(s)" />
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{ required: true, message: "Por favor, ingrese email" }]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                        </Col>

                        {
                            (this.props.usuario_id === undefined) ?
                                <Col xs={24}>
                                    <Form.Item
                                        label="Contraseña"
                                        name="password"
                                        rules={[{ required: true, message: "Por favor, ingrese contraseña" }]}
                                    >
                                        <Input.Password type="password" placeholder="Contraseña" />
                                    </Form.Item>
                                </Col> : null
                        }

                        <Col xs={24}>
                            <Form.Item
                                label="Teléfono"
                                name="telefono"
                                rules={[{ required: true, message: "Por favor, ingrese un número de teléfono" }]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                        </Col>


                        <Col xs={12} >
                            <Form.Item
                                label="Activo"
                                name="activo"
                                valuePropName="checked"
                            >
                                <Switch key="activo" />
                            </Form.Item>
                        </Col>

                        <Col xs={12} >
                            <Form.Item
                                label="Administrador"
                                name="admin"
                                valuePropName="checked"
                            >
                                <Switch key="admin" />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row justify="center" align="middle" >
                        <Col span={24} className="flex-column">
                            <Form.Item>
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {

    let { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            destroyOnClose={true}
            zIndex={1000}
            
            maskClosable={false}

        >
            <FormUsuario {...props} />
        </Modal>
    )
}