import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, PageHeader, Button, Avatar, Switch } from 'antd';
import { Link, Redirect } from "react-router-dom";
import { ExclamationCircleOutlined, EyeOutlined, FormOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import axios from 'axios'

//modal
import ModalUsuarios from './ModalUsuario'

import './../../../Styles/Modules/Usuarios/usuarios.css'

const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;

class Usuarios extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nombreVista: '',
			user: 'Administrador',
			isAdmin: true,
			dataUsuarios: [],
			itemCount: undefined,
			perPage: 10,
			pageCount: 1,
			currentPage: 1,
			slNo: 1,
			hasPrevPage: false,
			hasNextPage: false,
			prev: null,
			next: null,
			usuario_id: undefined
		}
	}
	/**
	 * @memberof Usuarios
	 *
	 * @method componentDidMount
	 * @description Se define header de axios, se llama metódo de consulta inicial receivedData
	 *
	 **/

	componentDidMount() {
		this.props.setTitle('Usuarios')
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.receivedData();
	}


	/**
	* @memberof Categorias
	*
	* @method   receivedData
	* @description  Metodo que realiza el paginado de los usuaros
	* @param values String  o Number con la info a buscar, por defecto cadena vacia para busquedas
	*
	**/
	receivedData = (page) => {
		this.state.dataUsuarios = [];


		axios.get('/usuarios', {
			params: {
				page: this.state.currentPage,
				limit: 10,
				paginate: true,
				search: this.state.search,
			}
		})
			.then(res => {
				if (res.data != undefined) {
					var data = res.data.data.itemsList;
					var data_usuarios = [];
					for (let index = 0; index < data.length; index++) {
						const element = data[index];

						data_usuarios.push({
							_id: element._id,
							nombre: element.nombre + " " + ((element.apellido !== undefined) ? element.apellido : ""),
							email: element.email,
							telefono: element.telefono,
							tipo: ((element.tipo === 1) ? "Administrador" : "Cliente"),
							activo: element.activo,

							createdAt: element.createdAt,
							updatedAt: element.updatedAt,
							index: (index + 1)
						});
					}

					this.setState({
						dataUsuarios: data_usuarios,
						currentPage: res.data.data.paginator.currentPage,
						itemCount: res.data.data.paginator.itemCount,
					});
				}
			}).catch(error => {
				message.error('Error al traer la informacion')
			})
	}




	/**
	  * @memberof Usuarios
		*
		* @method   handlePageClick
		* @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
		*
		**/
	handlePageClick = page => {
		this.setState({ currentPage: page }, () => {
			this.receivedData();
		})
	};

	/**
	* @memberof Usuarios
	  *
	  * @method   handlePageClick
	  * @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
	  *
	  **/
	handleSearch = search => {
		this.setState({ search: search }, () => {
			this.receivedData();
		})
	};

	/**
	  * @memberof Usuarios
	  *
	  * @method   showDeleteConfirm
	  * @description  Se muestra modal de confirmación para eliminar un usuario 
	  *
	  **/
	showDeleteConfirm = (item) => {
		const tm_nombre = item.nombre;
		const tm_id = item._id;

		confirm({
			title: 'Eliminar usuario',
			icon: <ExclamationCircleOutlined />,
			content: '¿Estas seguro de eliminar el usuario ' + tm_nombre + ' ?',
			okText: 'Continuar',
			okType: 'danger',
			cancelText: 'Cancelar',
			onOk: () => {
				axios({
					method: 'delete',
					url: '/usuarios/delete',
					data: { id: tm_id }
				})
					.then((response) => {
						message.success('Usuario eliminado');
						this.receivedData()
					}).catch((error) => {
						message.error('Error al eliminar');
					})
			}
		})
	}

	render() {

		const columns = [
			{
				title: '#',
				dataIndex: 'index',
				key: "index"
			},
			{
				title: 'Nombre',
				dataIndex: 'nombre',

				key: 'nombre',

				render: (text, record) => {
					return <>
 					 <CustomAvatar name={record.nombre + ' ' + record?.apellido} image={record.avatar}  size="large" className="avatar-user"/>
						{record.nombre}
					</>
				}
			}

			, {
				title: 'Email',
				dataIndex: 'email',
				key: 'email',
			}

			, {
				title: 'Teléfono',
				dataIndex: 'telefono',
				key: 'telefono',
			},

			, {
				title: 'Tipo',
				dataIndex: 'tipo',
				key: 'tipo',
			}


			, {
				title: 'Activo',
				dataIndex: 'activo',
				render: (text, record) => (
					<>
						<Switch checked={record.activo} disabled />
					</>
				)
			}


			, {
				title: 'Acciones',
				key: 'actions',
				dataIndex: '_id',
				render: (text, record) => (
					<>
						<Link to={`/admin/usuarios/detalle/${record._id}`}>
							<Button
								className="mr-1"
								title="Detalle"
								icon={<EyeOutlined />}
							/>
						</Link>

						<Button
							className="mr-1"
							title="Editar"
							type="secondary"
							icon={<FormOutlined />}
							onClick={() => {
								this.setState({ ModalUsuarios: true, usuario_id: record._id })
							}}
						>
						</Button>
						<Button
							title="Eliminar"
							type="danger"
							onClick={() => this.showDeleteConfirm(record)}
							icon={<DeleteOutlined />}>
						</Button>
					</>
				),
			},
		];




		return (
			<Layout>
				<Content className="admin-content content-bg pd-1">
					<Row className="mb-1">
						<Col xs={24} md={12}>
							<Search
								className="search-bar"
								placeholder="Buscar..."
								enterButton="Buscar"
								onSearch={this.handleSearch}
							/>
						</Col>
						<Col xs={24} md={12} className="flex-right">
							<Button type="primary" title="Agregar" icon={<PlusOutlined />} onClick={() => this.setState({ ModalUsuarios: true })}></Button>
						</Col>
					</Row>

					<Table
						columns={columns}
						dataSource={this.state.dataUsuarios}
						pagination={{
							onChange: this.handlePageClick,
							total: this.state.itemCount,
							current: this.state.currentPage
						}}
						className="im-table"
					/>
				</Content>

				<ModalUsuarios
					visible={this.state.ModalUsuarios}
					usuario_id={this.state.usuario_id}
					onCancel={() => {
						this.setState({ ModalUsuarios: false, usuario_id: undefined })
						this.receivedData()
					}}
				/>
			</Layout>
		)
	}
}



export default Usuarios;