

import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, List, Switch } from 'antd';

import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios'

import PropTypes from "prop-types";
import Logged from '../../../Hooks/Logged';
import CustomAvatar from "../../Widgets/Avatar/Avatar";


const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;


/**
 *
 *
 * @class FormVariantes
 * @extends {React.Component}
 * @description Formulario de categorias
 */
class FormVariantes extends Component {


	formModalVariante = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			loadingImage: false,
			form: {},

			id: null,
			image: null,
			negocios: []

		}
	}


	/**
	 * @methodOf FormVariantes
	 *
	 * @function componentDidUpdate
	 * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
	 *
	 * */
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getTamanos();
		if(this.props.variante_id){
			this.getData()
		}
	}

	/**
    * @memberof FormVariantes
    *
    * @method getTamanos
    * @description metodo que contiene la peticion de obtener todos los tamaños 
    **/
    getTamanos = () => {
        axios.get('/sizes', {
            headers: {Authorization: sessionStorage.getItem("token")}
        })
        .then(res => {
            this.setState({
                sizes: res.data.data
            })
        })
        .catch(res => {
            console.log("error", res);
            message.error('Error al obtener los Tamaños')
        });
    }

	/**
   * @memberof FormVariantes
   *
   * @method onFinish
   * @description  Se ejecuta al dar finalizar al formulario
   *
   **/
	onFinish = values => {
		this.setState({loading: true})
		if(this.props.variante_id !== undefined){
			this.updateCategoria(values)
		}else{
			this.addVariante(values);
		}
		
	}


	/**
	* @memberof FormVariantes
	*
	* @method getData
	* @description  trae la informacion de una categoria
	*
	**/
	getData = (values) => {
		this.setState({loading: true})
		axios.get(`/variantes/get`,{ 
			params:{
				id: this.props.variante_id
			}
		}).then(async res => {
			let variante = res.data.data.variante;
            this.formModalVariante.current.setFieldsValue({
                nombre: variante.nombre,
                sku_variante: variante.sku_variante,
                size: (variante.size_id != undefined) ? variante.size_id._id : '',
                precio: variante.precio,
                activo: variante.activo,
                });
        }).catch(res => {
            console.log("error", res);
            message.error('Error al traer la informacion')
        }).finally(()=>this.setState({loading: false}))
	}


	/**
	* @memberof FormVariantes
	*
	* @method updateCategoria
	* @description  Actualiza la informacion de una categoria
	*
	**/
	updateCategoria = (values) => {
		this.setState({loading: true})
		axios.put('/variantes/update', {
            id: this.props.variante_id,
            nombre: values.nombre,
            sku_variante: values.sku_variante,
            size_id: values.size,
            precio: values.precio,
            activo: values.activo,
                //existencias: values.existencias
        })
        .then((res) => {
        	message.success('Variante Actualziado')
        	this.props.onCancel()
       	}).catch((error) => {
        	message.error('Error al actualizar')
        	console.log(error)        
        }).finally(()=>this.setState({loading: false}))
	}

	/**
	* @memberof FormVariantes
	*
	* @method addVariante
	* @description  Añade una categoria
	**/
	addVariante = (values) => {
		axios.post('/variantes/add', {
            nombre: 	values.nombre,
            sku_variante:    values.sku_variante,
            size_id:    values.size,
            precio: 	values.precio,
            activo:     values.activo,  
            producto_id: this.props.producto_id,
        })
		.then((res) => {
            message.success('Varinate creada');
            this.props.onCancel()
        })
        .catch((error) => {
            console.log(error)
            message.error('Error al crear la Varinate')
        });
	}






	render() {

		const { loading } = this.state

		return (
			<Spin spinning={loading}>
				<Title level={3} className="text-center">Variantes</Title>
				<Form 
					layout="vertical" 
					ref={this.formModalVariante} 
					onFinish={this.onFinish} 
				>

					<Row>
						<Col xs={24} lg={11}>
							<Form.Item 
								name="nombre" 
								label="Nombre" 
								rules={[{
									required: true,
									message: 'Por favor ingresa nombre de producto'
								}]}
							>
								<Input placeholder="Nombre del producto" />
							</Form.Item>
						</Col>
						<Col xs={24} lg={{span: 11, push: 2}}>
							<Form.Item 
								name="sku_variante" 
								label="Sku"
							>
								<Input placeholder="ABCDS0123" />
							</Form.Item>
						</Col>
					</Row>
								
					<Row>
						<Col xs={24} lg={11}>
							<Form.Item 
								name="size" 
								label="Tamaño" 
								rules={[{
									required: true,
									message: 'Por favor selecciona tamaño'
								}]}>
									<Select 
										placeholder="Seleccionar opción" 
									>
										{
											this.state.sizes?.map(function (tam, index) {
												return <Option value={tam._id}>{tam.size}</Option>
											})
										}
									</Select>
							</Form.Item>
						</Col>
						
						<Col xs={24} lg={{span: 11, push: 2}}>
							<Form.Item 
								name="precio" 
								label="Precio" 
								rules={[{
									required: true,
									message: 'Por favor ingresa costo de producto'
								}]}>
									<Input placeholder="$0.00"  type="number"/>
							</Form.Item>
						</Col>
									
						<Col xs={24} lg={11}>
							<Form.Item 
								name="activo" 
								label="Producto activo"
								valuePropName="checked"
							>
								<Switch defaultChecked style={{float: 'left'}}/>
							</Form.Item>
						</Col>
					</Row>

					<Row justify="center" align="middle" >
						<Col span={24} className="flex-column">
							<Form.Item>
								<Button htmlType="submit" type="primary">
									Guardar
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Spin>

		)
	}
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {

	let { visible, onCancel } = props

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			title={null}
			footer={null}
			maskClosable={true}
			destroyOnClose={true}
			zIndex={1000}
		>
			<FormVariantes {...props} />
		</Modal>
	)
}
