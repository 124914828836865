import React, {useEffect, useState, Component} from 'react';

import {Form, Layout, Input, Button, Row, Col, Select, Upload, Switch, Modal, List, message, Divider, Spin} from 'antd';
// import {FileImageOutlined} from '@ant-design/icons';
import {Redirect, Link} from "react-router-dom";
import 'react-dropzone-uploader/dist/styles.css'
import Previews from '../../Widgets/Preview';
import axios from 'axios'

const { Header,Content} = Layout;



const {Option} = Select;
const {TextArea} = Input;






/**
 *
 * @memberof CrearProducto
 *
 * @method addFile
 * @description Sube un archivo al WebService.
 *
 */
class EditarProducto extends Component {

    formRef = React.createRef();

    preview = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            nombreVista: 'Editar producto',
            return: false,
            prod_activo: undefined,
            categorias: [],
            producto: [],
            imagenes: [],
            fileList:[]
        }
    }

    /**
     * @memberof EditarProducto
     *
     * @method componentDidMount
     * @description  Al montar el componente trae los datos del producto y los asigna al state.producto
     *
     **/
    async componentDidMount() {
        axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
       
        /*obtener las categorias y tamaños primero*/
        this.getCategorias();
        const pid = this.props.match.params.id;
        await axios.get(`/productos/get`,
            {params:{id: pid}},
            {
                headers: {Authorization: sessionStorage.getItem("token")}
            })
            .then(async res => {    
                let imagenes = res.data.data.producto.imagenes;
                let fileArray =  (imagenes.length)? imagenes.map((imagen,number) => new Object({
                    lastModified: new Date().getMilliseconds(),
                    lastModifiedDate: new Date(),
                    name: imagen,
                    path: imagen,
                    preview:  axios.defaults.baseURL + 'upload/' + imagen
                })): [];

                this.setState({
                    prod_activo: res.data.data.activo,
                    producto: res.data.data.producto,
                    fileList: fileArray
                });

                console.log(res.data.data.producto)
                this.setInfo()
               
            })
            .catch(res => {
                console.log("error", res);
            });

        console.log(this)
    }

    setInfo = () =>{

        let producto = this.state.producto;
        let fileArray = this.state.fileList

        this.formRef.current.setFieldsValue({
            nombre: producto.nombre,
            descripcion: producto.descripcion,
            categoria: (producto.categoria_id != undefined) ? producto.categoria_id._id : '',
            precio: producto.precio,
            sku: producto.sku,
            activo: producto.activo,
            file: fileArray
        });
    }
    /**
     * @memberof EditarProducto
     *
     * @method getCategorias
     * @description  metodo que contiene la peticion de obtener todas las categorias para asignarlos al state.categorias
     *
     **/
    getCategorias = () => {
        axios.get('/categorias', {
            headers: {Authorization: sessionStorage.getItem("token")}
        })
            .then(res => {

                this.setState({
                    categorias: res.data.data.itemsList
                })


            })
            .catch(res => {
                console.log("error", res);
            });
    };


    /**
     *
     *
     * @memberof EditarProducto
     * @description Genera un arreglo para que pueda ser leido por el Webservice
     *
     * @param fileList
     * Recibe la lista de archivos generada por el componente Upload.Dragger
     *
     * @returns array
     * Retorna el arreglo convertido para poder enviarlo al WS
     *
     */
    getImagesArray = (fileList) => {
        let array = [];
        for (let index = 0; index < fileList.length; index++)
            array.push(fileList[index].name);
        return array;
    }


    /**
     * @memberof EditarProducto
     *
     * @method onChangeActive
     * @description  Actualiza el valor del switch del input activo al state.activo
     *
     **/
    onChangeActive = (checked) => {
        console.log("estatus del producto", checked);
        this.setState({prod_activo: checked});
    };


    /**
     *
     * @memberof EditarProducto
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };

    /**
     * @memberof EditarProducto
     *
     * @method handleSubmit
     * @description  Envia los datos del formulario al Servidor
     *
     * @param values (array)
     * Contiene los campos del formulario para editar al producto
     *
     * @returns response (array)
     **/
    handleSubmit = values => {


        let element = this.getImagesArray(this.preview.current.state.files);
        console.log(element)
        // return ;
        // console.log();
        // return ;
        axios.put('/productos/update', {
                id: this.state.producto._id,
                nombre: values.nombre,
                descripcion: values.descripcion,
                categoria_id: values.categoria,
                sku: values.sku,
                imagenes: element,
                activo: this.state.prod_activo,
            },
            {headers: {Authorization: sessionStorage.getItem("token")}})
            .then((res) => {
                console.log("ok", res);
                message.success(res.data.message);
                this.setState({return: true});

            })
            .catch((error) => {
                console.log(error)
                Modal.warning({
                    title: 'Error',
                    content: (Array.isArray(error.errors))? <List
                        size="small"
                        bordered
                        dataSource={error.errors}
                    />: 'Error al procesar el producto. Verifique la información.'
                });
            });

    };

    /**
     * @memberof EditarProducto
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
     **/
    renderRedirect = () => {
        if (this.state.return) {
            return <Redirect to="/admin/productos"/>;
        }
    };




    render() {
        return (
            <Layout>
                {this.renderRedirect()}
                <Header className="admin-header" style={{height: 'auto'}}>
                    <Row>
                        <Col span={10}>
                            <h3 id="nombreVista" className="nombreVista encabezado">{this.state.nombreVista}</h3>
                        </Col>
                    </Row>
                </Header>
                <Content className="box-shadow">
                    <div>
                        <div className="div-contenedor">
                            <Col span={16} offset={4}>
                                <Form onFinish={this.handleSubmit} layout={"vertical"} ref={this.formRef}>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12} xs={24} lg={12}>
                                            <Form.Item name="nombre" label="Nombre" rules={[{
                                                required: true,
                                                message: 'Por favor ingresa nombre de producto'
                                            }]}>
                                                <Input placeholder="Nombre del producto" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} xs={24} lg={{span: 11, push: 1}}>
                                            <Form.Item name="categoria" label="Categoría" rules={[{
                                                required: true,
                                                message: 'Por favor selecciona categoría'
                                            }]}>
                                                <Select placeholder="Seleccionar opción" >
                                                        {this.state.categorias.map(function (categoria, index) {
                                                            return <Option value={categoria._id}>{categoria.nombre}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} md={24} lg={24}>
                                            <Form.Item name="descripcion" type='textarea' label="Descripción" rules={[{
                                                required: true,
                                                message: 'Por favor ingresa descripción'
                                            }]}>
                                                <TextArea
                                                    placeholder="Descripción del producto"
                                                    
                                                    style={{minHeight: '100px', width: '100%'}}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12} xs={24} md={8} lg={8}>
                                            <Form.Item name="sku" label="Sku" rules={[{
                                                required: true,
                                                message: 'Por favor ingresa el sku de producto'
                                            }]}>
                                                <Input placeholder="ABCDS0123" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} xs={24} md={8} lg={8}>
                                                <Form.Item name="activo" label="Producto activo" valuePropName="checked">
                                                        <Switch   onChange={this.onChangeActive}  style={{float: 'left'}}/>
                                                    </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item label="Imagenes">
                                        <Form.Item
                                            name="file"
                                            defaultFileList={this.state.fileList}
                                            initialValue={this.state.fileList}
                                        >
                                            <Previews
                                                ref={this.preview}
                                            />
                                        </Form.Item>
                                    </Form.Item>
                                    <Row>
                                        <Col span={12} xs={24} lg={12}>
                                            
                                        </Col>
                                        <Col span={12} xs={24} lg={{span: 12}}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit"
                                                        >
                                                    Guardar
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </div>
                    </div>
                </Content>

            </Layout>
        )
    }
}

export default EditarProducto;
