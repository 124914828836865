import React, { Component, useContext } from 'react'
import { Modal, Row, Col, Form, Input, Button, Spin, Image, Checkbox, List, message, Typography, Alert } from 'antd';
import { UserOutlined, LockOutlined, PhoneOutlined } from '@ant-design/icons';
import { Link, Redirect } from "react-router-dom";



import { User, SetUser } from '../../Hooks/Logged';
import '../../Styles/Global/auth.css';
import { IconBus, IconBuyBag, IconUserCircle, IconHotDeals, IconCloseModal } from "../Widgets/Iconos";

import axios from 'axios'

const { Title, Text } = Typography;

class Register extends Component {
    
    static contextType = SetUser

    redirectLink = "/admin/dashboard"

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            confirmDirty: false,
            log: false,
            isAdmin: false,
            loading: false,
            userName: '',
        }
    }


    componentDidMount() {
        this.setState({ loading: false });
        console.log(this.props)
    }

    /**
   * @memberof Register
   *
   * @method handleSubmit
   * @description  Envia los datos del formulario al Servidor
   *
   * @param values (array)
   * Contiene los campos del formulario para registrar al usuario
   *
   * @returns response (array)
   **/
    handleSubmit = values => {
        console.log('values', values)
        axios.post('/register', {
            nombre: values.nombre,
            email: values.email,
            password: values.password,

        })

            .then(({ data, headers }) => {
                if (data.success) {
                    message.success("Your account has been created! Login to continue");
                    console.log('props', this.props)
                    console.log(data)
                    sessionStorage.setItem('token', headers.authorization);

                    axios.defaults.headers.post["Authorization"] = headers.authorization;
                    axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

                    this.context(data.user)
                    if (typeof this.props.onSuccess === "function")
                        return this.props.onSuccess()
                    else 
                        this.props.onCancel()
                }
            })
            .catch((error) => {
                console.log(error.response)

                Modal.warning({
                    title: 'Register error',
                    content:
                        <List
                            size="small"
                            bordered
                        >
                            <List.Item> Verifique su información </List.Item>

                        </List>
                });

            })
    }


    /**
   * @memberof Login
   * @param correo y contraseña de registro exisoto
   **/

    loginAuto(correo, contra) {
        axios.post('/login', {
            email: correo,
            password: contra
        })
            .then(response => {
                //console.log("ok",response);
                if (response.status == 200) {
                    axios.defaults.headers.post["Authorization"] = response.headers.authorization;
                    sessionStorage.setItem('token', axios.defaults.headers.post["Authorization"]);
                    { this.consultarInfoUser() }
                }
            })
            .catch(error => {
                // console.log(error.response.data.message);
                Modal.warning({
                    title: 'Error',
                    content: error.response.data.message
                });
            });
    }


    /**
    * @memberof Login
    * @method consultarInfoUser
    * @description  Busca la informacion del usuario logeado para guardar variables de sesion utilizadas en el sitio
    **/
    consultarInfoUser() {
        if ((sessionStorage.getItem('token') != null)) {
            this.setState({ isLogged: true });
            axios.get('/usuario/logged', { headers: { Authorization: sessionStorage.getItem('token') } })
                .then((response) => {
                    if (response.status == 200) {
                        var dataUser = response.data.data[0];
                        sessionStorage.setItem('userName', dataUser.nombre);
                        if (dataUser.activo) {
                            var admin = false;
                            if (dataUser.roles_id.length > 0) {
                                for (let i = 0; i < dataUser.roles_id.length; i++) {
                                    if (dataUser.roles_id[i].nombre == "Administrador") {
                                        this.setState({ isAdmin: true });
                                        break;
                                    }
                                }
                            }
                            sessionStorage.setItem('isAdmin', (this.state.isAdmin));
                            this.setState({ log: true, redirect: true });
                        }
                        else {
                            Modal.warning({
                                title: 'Error',
                                content: 'The user ' + dataUser.nombre + ' doesn\'t have access to the system.'

                            });
                            sessionStorage.clear();
                        }
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                    message.error('Error.');
                    sessionStorage.clear();
                })
        }
        else {
            this.setState({ redirect: true });
            { this.renderRedirect() }
        }

    }


    /**
    * @memberof Register
    *
    * @method redirectTo
    * @description  Redirecciona a cierto link.
    *
    **/
    redirectTo = (to) => {
        this.redirectLink = to;
        this.setState({ log: true });
    };
    /**
     * @memberof Register
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
     **/
    renderRedirect = () => {

        if (this.state.log)
            return <Redirect to={this.redirectLink} />

    };



    render() {
        return (
            <>
                {this.renderRedirect()}

                <section className="cnt-login">
                    <Row className="w-100" >
                        <Col className="col-form"
                            xs={{ span: 24, order: 1 }}
                            sm={{ span: 24, order: 1 }}
                            md={{ span: 24, order: 1 }}
                            lg={{ span: 12, order: 1 }}
                            xl={{ span: 12, order: 1 }}
                            xxl={{ span: 12, order: 1 }}
                        >
                            <Spin spinning={this.state.loading}>

                                <Row className="w-100" justify="center" >
                                    <Col xs={{ span: 24, offset: 2, pull: 1 }}
                                        sm={{ span: 24, offset: 0, pull: 0 }}
                                        md={{ span: 20, offset: 0 }}
                                        lg={{ span: 20, offset: 1, pull: 1 }}
                                        xl={{ span: 20, offset: 0, pull: 0 }}
                                        xxl={{ span: 20, offset: 0, pull: 0 }} >

                                        <Row justify="center" className="mb-3">
                                            <Image src={'/images/logo.png'} wrapperClassName="center" preview={false} width={185} />
                                        </Row>

                                        <h3 className="h3-title">Register</h3>
                                        <Form onFinish={this.handleSubmit} layout={"vertical"} requiredMark={false} >

                                            <Form.Item name="nombre" rules={[{ required: true, message: 'Enter your full name, please.' }]}>
                                                <Input placeholder="Full Name" size="large" className="input-login" />
                                            </Form.Item>



                                            <Form.Item name="email" rules={[{ required: true, message: 'Enter your email, please.' }]}>
                                                <Input placeholder="E-mail" size="large" className="input-login" />
                                            </Form.Item>


                                            <Form.Item name="password" rules={[{ required: true, message: 'Enter your password, please.' }]}>
                                                <Input.Password type="password" placeholder="Password" size="large" className="input-login" />
                                            </Form.Item>

                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item name="rememberme" >
                                                        <Checkbox className="checkbox-bdf " > Remember me </Checkbox>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>

                                                    <Link to={'#'} onClick={() => this.setState({ isRecovery: true })} className="mb-1"> I forgot my password </Link>
                                                </Col>
                                            </Row>

                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" block size="large" className="button-login">Sign Up</Button>
                                            </Form.Item>

                                            <Form.Item>
                                                <p> Do you already have an account? <Link to={"#"} onClick={this.props.onClickLoginButton}> Inicia sesión  </Link></p>
                                            </Form.Item>

                                        </Form>

                                        {this.state.error?.success == false ?
                                            <Alert
                                                message="An error has ocurred!"
                                                description={this.state.error.message}
                                                type="error"
                                                closable
                                                className="pd-1"

                                                onClose={() => this.setState({ error: { success: null, description: null } })}
                                            />
                                            : null}
                                    </Col>
                                </Row>
                                <Row className="w-100">
                                    <Col className="txt-pie-modal alg-l"> Copyright © 2021 <Text> BedifyMe </Text> All rights reserved. </Col>
                                    <Col className="txt-pie-modal alg-r"> <Text>Terms & Conditions</Text> </Col>
                                </Row>
                            </Spin>
                        </Col>
                        <Col
                            xs={{ span: 24, order: 1 }}
                            sm={{ span: 24, order: 1 }}
                            md={{ span: 24, order: 1 }}
                            lg={{ span: 12, order: 2 }}
                            xl={{ span: 12, order: 2 }}
                            xxl={{ span: 12, order: 2 }}
                            style={{ background: "url('/images/bg-modal.png')" }} className="col-img" >
                        </Col>
                    </Row >

                    <Modal
                        title="I forgot my password"
                        visible={this.state.isRecovery}
                        onOk={() => this.recoveryPassword.current.submit()}
                        onCancel={() => this.setState({ isRecovery: false })}
                        maskClosable={!this.state.isRecoveryLoading}
                        okText="Enviar"
                        cancelText="Cancelar"
                    >
                        <Form onFinish={this.handleRecoveryPassword} layout={"vertical"} className="pd-2" ref={this.recoveryPassword}>
                            <Form.Item
                                name="email"
                                label="Enter your email."
                                rules={[{
                                    required: true,
                                    message: 'Enter yout email, please.'
                                }]}>
                                <Input type="email" size="large" placeholder="Enter your email" />
                            </Form.Item>
                        </Form>
                    </Modal>
                </section>
            </>
        )
    }
}






export default function (props) {


    const { visible, onCancel } = props

    let user = useContext(User)
    const setUser = useContext(SetUser)

    return <Modal
        visible={visible}
        onCancel={onCancel}
        width={1000}
        maskClosable={false}

        className="bdf-modal modal-radius"
        footer={null}

        closable={true}
        closeIcon={<div title="Cerrar"> <IconCloseModal /> </div>}

        destroyOnClose={true}
        mask={true}

        maskStyle={{
            backdropFilter: 'blur(0.5rem)'
        }}
    >
        <Register {...props} user={user} setUser={setUser} />
    </Modal>

}