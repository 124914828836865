import React from 'react'
import { Route, Switch } from "react-router-dom";
import { Result } from 'antd';
import Ventas from '../components/Admin/Ventas/Ventas';

// componentes

/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterVentas( props) {
    return (
        <Switch>
            <Route exact path="/admin/ventas" render={() => <Ventas {...props} />} />
        </Switch>
    )
}

export default RouterVentas;
