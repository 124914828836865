import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


String.prototype.toMoney = function (toFixed = true, fractionDigits = 2) {
    if (!toFixed)
        return parseFloat(this).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    else
        return parseFloat(this).toFixed(fractionDigits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

Number.prototype.toMoney= function (fractionDigits = 2) {
    return this.toFixed(fractionDigits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

Number.prototype.toMoney2= function (fractionDigits = 2) {
    try{
        if(this){
            var r = this.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
            return parseFloat(r)
        }
        else
            return 0.00
    }catch(error){
        return 0.00
    }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
